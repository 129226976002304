import { IconButton, styled } from '@mui/material';
import { DialogContent } from '@mui/material';

export const StyledModalContainer = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100vh;
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: ${({ theme }) => theme.spacing(3)};
  overflow: auto;
`;

export const StyledModalIconButton = styled(IconButton)`
  position: absolute;
  top: ${({ theme }) => theme.spacing(2.25)};
  right: ${({ theme }) => theme.spacing(1.75)};
  font-size: ${({ theme }) => theme.typography.customFontSize.xLarge};

  &:hover {
    background-color: inherit;
  }
`;

export const StyledModalContent = styled(DialogContent)`
  padding-left: 0;
  padding-right: 0;
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;
