import { IconButton, styled } from '@mui/material';

export const IconButtonLogout = styled(IconButton)`
  color: ${({ theme }) => theme.palette.custom.white};
  text-decoration: none;
  font-size: ${({ theme }) => theme.typography.customFontSize.small};
  font-weight: 400;
  line-height: ${({ theme }) => theme.spacing(2.75)};
  padding: ${({ theme }) => theme.spacing(0, 1.5)};

  svg {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`;
