import { FormControlLabel, Stack, styled } from '@mui/material';
import { typographyClasses } from '@mui/material/Typography';

export const StyledCheckboxListFormControlLabel = styled(FormControlLabel)`
  display: ${({ hidden }) => (hidden ? 'none' : 'inline-flex')};
  margin-right: 0;

  &:not(:last-of-type) .${typographyClasses.root} {
    border-bottom: ${({ theme }) => theme.spacing(0.125)} solid
      ${({ theme }) => theme.palette.border.borderGrey};
  }
`;

export const StyledCheckboxListScrollableWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: calc(100% + ${({ theme }) => theme.spacing(3)});
  padding-right: ${({ theme }) => theme.spacing(2.25)};
  max-height: ${({ theme }) => theme.spacing(45)};
  overflow: auto;

  &:last-child {
    padding-bottom: 0;
  }
`;

export const StyledCheckboxListStack = styled(Stack)`
  width: 100%;
`;
