import { Table } from '@mui/material';
import { usePagination, useReports } from 'hooks';
import { REPORTS_DATA_TYPE, TABLE } from 'utils/const';

import { Pagination } from 'components/pagination';
import { Placeholder } from 'components/placeholder';
import { TableHead } from 'components/table';

import { ReportingPeriodsTableBody } from './reports-table-body/reporting-periods-table-body';
import {
  StyledReportingPeriodsTableContainer,
  StyledTabsButton,
} from './reports-table.styled';

const { REPORTING_PERIODS } = TABLE;

export const ReportingPeriodsTable = ({
  openModalEdit,
}: {
  openModalEdit: (data: any) => void;
}) => {
  const { reportingPeriods, handleGetReportingPeriods } = useReports(
    REPORTS_DATA_TYPE.reportingPeriods,
  );

  const {
    rowsPerPage,
    page,
    paginationPage,
    paginationLimit,
    handleChangePage,
    handleChangePageSelect,
    handleChangeRowsPerPage,
    handleOnKeyDown,
  } = usePagination(reportingPeriods?.length, handleGetReportingPeriods);

  return (
    <>
      <StyledTabsButton variant="contained" onClick={openModalEdit}>
        + Add new
      </StyledTabsButton>

      {reportingPeriods ? (
        <StyledReportingPeriodsTableContainer>
          <Table>
            <TableHead rowTitles={REPORTING_PERIODS} />

            <ReportingPeriodsTableBody
              data={reportingPeriods.data}
              openModalEdit={openModalEdit}
            />
          </Table>

          {reportingPeriods.length > paginationLimit ? (
            <Pagination
              results={reportingPeriods.length}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              paginationPage={paginationPage}
              page={page}
              handleChangePageSelect={handleChangePageSelect}
              handleOnKeyDown={handleOnKeyDown}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          ) : null}
        </StyledReportingPeriodsTableContainer>
      ) : (
        <Placeholder>
          No reporting periods configured. <br />
          Use "Add new" option to start generating automatic reports.
        </Placeholder>
      )}
    </>
  );
};
