import { FaUserAlt } from 'react-icons/fa';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import { MdVpnKey } from 'react-icons/md';
import { Button, IconButton, InputAdornment } from '@mui/material';
import { useLogin } from 'hooks/login';
import { ILocalStorage } from 'utils/interface';

import {
  StyledLoginForm,
  StyledLoginInputsContainer,
  StyledLoginRequestAccess,
  StyledLoginResetPasswordButton,
  StyledLoginTextField,
} from '../login.styled';

export const LoginForm = ({ setToken }: ILocalStorage) => {
  const {
    errors,
    showPassword,
    register,
    handleSubmit,
    onSubmit,
    handleSetShowPassword,
  } = useLogin({
    setToken,
  });
  return (
    <StyledLoginInputsContainer>
      <StyledLoginForm onSubmit={handleSubmit(onSubmit)}>
        <h2>Log In</h2>
        <StyledLoginTextField
          variant="outlined"
          label="Username"
          error={!!errors?.name}
          helperText={errors?.name ? errors?.name?.message : null}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FaUserAlt />
              </InputAdornment>
            ),
          }}
          {...register('name')}
        />
        <StyledLoginTextField
          variant="outlined"
          type={showPassword ? 'text' : 'password'}
          label="Password"
          error={!!errors?.pass}
          helperText={errors?.pass ? errors?.pass?.message : null}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MdVpnKey />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleSetShowPassword}
                  edge="end"
                >
                  {showPassword ? <IoMdEyeOff /> : <IoMdEye />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...register('pass')}
        />
        <StyledLoginResetPasswordButton>
          Reset password
        </StyledLoginResetPasswordButton>
        <Button type="submit" variant="contained">
          Log in
        </Button>
        <StyledLoginRequestAccess>
          Don’t have an account?
          <StyledLoginResetPasswordButton>
            Request access
          </StyledLoginResetPasswordButton>
        </StyledLoginRequestAccess>
      </StyledLoginForm>
    </StyledLoginInputsContainer>
  );
};
