import { fabric } from 'fabric';
import { colors } from 'styles/colors';
import { MAP } from 'utils/const';

const {
  ACTIVE_SENSOR: {
    GROUP: { LEFT_CENTER_POSITION, TOP_CENTER_POSITION },
    TEXT,
    SUBTEXT,
    BOX,
  },
  FONT_FAMILY,
} = MAP;

export const setActiveSensorOnMap = (
  image: fabric.Image,
  left: number,
  top: number,
  color: string,
  name: string,
  average_noise: string,
  id: string,
) =>
  new fabric.Group(
    [
      activeSensorBox(left, top, color),
      image,
      activeSensorText(top, left, average_noise),
      activeSensorSubText(top, left, name),
    ],
    {
      left: left - LEFT_CENTER_POSITION,
      top: top - TOP_CENTER_POSITION,
      name: id,
    },
  );

const activeSensorText = (top: number, left: number, text: string) =>
  new fabric.Text(text, {
    width: TEXT.WIDTH,
    fontSize: TEXT.FONT_SIZE,
    fontWeight: TEXT.FONT_WEIGHT,
    left: left + TEXT.LEFT,
    top: top + TEXT.TOP,
    fill: colors.map['text'],
    fontFamily: FONT_FAMILY,
  });

const activeSensorSubText = (top: number, left: number, text: string) =>
  new fabric.Text(text, {
    width: SUBTEXT.WIDTH,
    fontSize: SUBTEXT.FONT_SIZE,
    fontWeight: SUBTEXT.FONT_WEIGHT,
    left: left + SUBTEXT.LEFT,
    top: top + SUBTEXT.TOP,
    fill: colors.map['text'],
    fontFamily: FONT_FAMILY,
  });

const activeSensorBox = (left: number, top: number, fill: string) =>
  new fabric.Rect({
    top,
    left,
    width: BOX.WIDTH,
    height: BOX.HEIGHT,
    fill,
    rx: BOX.RX,
    ry: BOX.RY,
  });
