import { FaFilter } from 'react-icons/fa';
import { MdAssignment } from 'react-icons/md';
import { Button } from '@mui/material';
import { useHookFormContext } from 'hooks';
import { MODAL_TYPE } from 'utils/const';
import { isSelectedCheckboxOnList } from 'utils/helpers';

import {
  StyledLimitbarButtonContainer,
  StyledLimitbarButtonRestoreButton,
} from '../limitbar.styled';

export const LimitbarButtons = () => {
  const {
    data: { selectedClassList, selectedSensorList },
    handleOpenModal,
    resetToDefault,
    sendReport,
  } = useHookFormContext();

  const isSensorSelected = isSelectedCheckboxOnList(selectedSensorList);
  const isClassSelected = isSelectedCheckboxOnList(selectedClassList);

  return (
    <StyledLimitbarButtonContainer>
      <Button
        variant="outlined"
        disabled={!isSensorSelected || !isClassSelected}
        onClick={sendReport}
      >
        <MdAssignment />
        Generate report
      </Button>
      <Button
        variant="outlined"
        onClick={() => handleOpenModal(MODAL_TYPE.filter)}
      >
        <FaFilter />
        Filters
      </Button>
      <StyledLimitbarButtonRestoreButton
        onClick={resetToDefault}
        variant="text"
      >
        Restore to default
      </StyledLimitbarButtonRestoreButton>
    </StyledLimitbarButtonContainer>
  );
};
