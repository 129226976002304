import { getTruthyItems } from './get-truthy-items';

export const getCheckedStatus = (
  selectedItems: { [key: string]: boolean },
  id: string,
) => {
  const { result } = getTruthyItems(selectedItems);

  return result.includes(id);
};
