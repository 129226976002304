import { Table } from '@mui/material';
import { useCheckbox, useHookFormContext, usePagination } from 'hooks';
import { TABLE } from 'utils/const';
import { IClassTable } from 'utils/interface';
import { getClassTableDataMapper } from 'utils/mapper';

import { Pagination } from 'components/pagination';
import { TableHead } from 'components/table';

import { StyledClassTableExpandedContainer } from './class-table.styled';
import { ClassTableBody } from './class-table-body';

const { CLASSES } = TABLE;

export const ClassTableExpanded = (props: IClassTable) => {
  const { listName, data } = props;
  const {
    data: { noiseLimit },
  } = useHookFormContext();
  const { selectedItems, onSelectAllChange, register } = useCheckbox(props);
  const classesTableData = getClassTableDataMapper(data);

  const {
    sliceStart,
    sliceEnd,
    rowsPerPage,
    page,
    paginationPage,
    paginationLimit,
    handleChangePage,
    handleChangePageSelect,
    handleChangeRowsPerPage,
    handleOnKeyDown,
  } = usePagination(classesTableData?.length);

  return classesTableData ? (
    <StyledClassTableExpandedContainer>
      <Table>
        <TableHead
          rowTitles={CLASSES}
          listName={listName}
          onSelectAllChange={onSelectAllChange}
          selectedItems={selectedItems}
        />

        <ClassTableBody
          data={classesTableData}
          sliceStart={sliceStart}
          sliceEnd={sliceEnd}
          selectedItems={selectedItems}
          register={register}
          listName={listName}
          noiseLimit={noiseLimit}
        />
      </Table>

      {classesTableData.length > paginationLimit ? (
        <Pagination
          results={classesTableData.length}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          paginationPage={paginationPage}
          page={page}
          handleChangePageSelect={handleChangePageSelect}
          handleOnKeyDown={handleOnKeyDown}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : null}
    </StyledClassTableExpandedContainer>
  ) : null;
};
