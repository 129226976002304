import dayjs from 'dayjs';
import { FORMAT } from 'utils/const';
import { ILineChartTooltipProps } from 'utils/interface';

import { StatusIcon } from 'components/status';

import {
  StyledLineChartTooltip,
  StyledLineChartTooltipHeader,
  StyledLineChartTooltipParagraph,
} from './line-chart-tooltip.styled';

const { DATE, TIME } = FORMAT;

export const LineChartTooltip = ({ payload: data }: ILineChartTooltipProps) => {
  const payload = data && data[0]?.payload;
  const date = dayjs(payload?.ts).format(DATE);
  const time = dayjs(payload?.ts).format(TIME);
  const value = payload?.value;

  return data ? (
    <StyledLineChartTooltip>
      <StyledLineChartTooltipHeader>
        <StatusIcon noiseLevel={value} /> {value} dBA
      </StyledLineChartTooltipHeader>

      <StyledLineChartTooltipParagraph>
        <span>{time}</span>
        <span>{date}</span>
      </StyledLineChartTooltipParagraph>
    </StyledLineChartTooltip>
  ) : null;
};
