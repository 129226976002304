import ReactDOM from 'react-dom';
import { IPDFChartGeneratorProps } from 'utils/interface';
import { getPDFLineChartMapper } from 'utils/mapper/get-pdf-chart.mapper';

import { LineChart } from 'components/chart';

import { StyledPDFChartGeneratorWrapper } from './pdf-chart-generator.styled';

export const PDFLineChartGenerator = ({ data }: IPDFChartGeneratorProps) => {
  const { sensors } = getPDFLineChartMapper(data);
  const { global_measurements } = data;

  return (
    <>
      {ReactDOM.createPortal(
        <StyledPDFChartGeneratorWrapper id="pdf-line-chart-generator">
          {sensors &&
            sensors.map(
              ({ noise_level, id }) =>
                noise_level && (
                  <LineChart
                    key={id}
                    data={noise_level}
                    height={440}
                    noiseLimit={global_measurements.noise_limit}
                    pdf={true}
                    yMax={global_measurements.y_axis_max}
                    yMin={global_measurements.y_axis_min}
                  />
                ),
            )}
        </StyledPDFChartGeneratorWrapper>,
        document.querySelector('#root') as HTMLElement,
      )}
    </>
  );
};
