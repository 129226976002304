import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { VARIOUS_TIMES } from 'utils/const';

dayjs.extend(duration);

const { ONE_DAY_IN_SEC } = VARIOUS_TIMES;

export const convertTime = (time: number) => {
  const regex = /\b0+[a-z]+\s*/gi;

  return time < ONE_DAY_IN_SEC
    ? dayjs
        .duration(time, 'seconds')
        .format('H[hr] m[min] s[sec]')
        .replace(regex, '')
        .trim()
    : `${Math.floor(time / ONE_DAY_IN_SEC)}d ${dayjs
        .duration(time % ONE_DAY_IN_SEC, 'seconds')
        .format('H[hr] m[min] s[sec]')
        .replace(regex, '')
        .trim()}`;
};
