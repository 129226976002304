import { TableBody, TableCell, TableRow } from '@mui/material';
import { IReportingPeriodsTableBody } from 'utils/interface';
import { getReportRecurrencyMapper } from 'utils/mapper';

import { ReportTableActions } from '../reports-table-actions/reports-table-actions';

export const ReportingPeriodsTableBody = ({
  data,
  openModalEdit,
}: IReportingPeriodsTableBody) => (
  <TableBody>
    {data.map(item => {
      const {
        id,
        start_date,
        end_date,
        week_days,
        report_interval,
        sensors,
        classes,
      } = item;
      return (
        <TableRow key={id}>
          <TableCell>{start_date}</TableCell>
          <TableCell>{end_date}</TableCell>
          <TableCell>{`${getReportRecurrencyMapper(
            week_days,
            report_interval,
          )}`}</TableCell>
          <TableCell align="right">{sensors.length}</TableCell>
          <TableCell align="right">{classes.length}</TableCell>
          <TableCell>
            <ReportTableActions
              id={id}
              data={item}
              edit={openModalEdit}
              remove
            />
          </TableCell>
        </TableRow>
      );
    })}
  </TableBody>
);
