import { Controller } from 'react-hook-form';

import {
  StyeldCalendarInputsBox,
  StyledCalendarInput,
  StyledCalendarInputsContainer,
} from 'components/calendar/calendar.styled';
import { SubTitle } from 'components/subtitle';

export const ReportPeriodsTimeFrameInputs = ({ methods, title, name }: any) => (
  <>
    <StyledCalendarInputsContainer>
      <SubTitle text={title} />
      <StyeldCalendarInputsBox>
        <Controller
          control={methods.control}
          name={name}
          render={({ field: { onChange, value } }) => (
            <StyledCalendarInput
              value={value}
              onChange={onChange}
              disabled
              required
              type="text"
              label="Date"
              error={!!methods.formState.errors[name]}
              helperText={
                !!methods.formState.errors[name] ? 'Please insert date' : null
              }
            />
          )}
        />
      </StyeldCalendarInputsBox>
    </StyledCalendarInputsContainer>
  </>
);
