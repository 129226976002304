import { Navigate } from 'react-router-dom';
import { Login } from 'pages/login';
import { ROUTES } from 'utils/const';
import { IProtectedRoute } from 'utils/interface';

export const ProtectedRoute = ({
  children,
  token,
  redirect = ROUTES.LOGIN,
}: IProtectedRoute) => {
  if (!token) {
    return <Navigate to={redirect} replace />;
  }
  return children ? children : <Login />;
};
