import { IGetPDFDataClass, IGetPDFDataSensor } from 'utils/interface/pdf-data';

import { isInArray } from './is-in-array';

export const getUniqueClasses = (sensors: IGetPDFDataSensor[]) => {
  const uniqueClasses: IGetPDFDataClass[] = [];

  sensors.map(sensor => {
    sensor.average_noise = Math.round(sensor.average_noise);

    return sensor.classes.map(singleClass => {
      if (
        uniqueClasses.length === 0 ||
        !isInArray(singleClass.id, uniqueClasses)
      ) {
        return uniqueClasses.push(singleClass);
      }

      return false;
    });
  });

  return uniqueClasses;
};
