import { useEffect, useState } from 'react';
import { DEFAULT_REPORT_PERIODS_VALUE } from 'utils/const';
import { socketClient } from 'utils/data-access';
import { IUseFilterProps } from 'utils/interface';

export const useDefaultReportsPeriodsValues = () => {
  const [defaultValue, setDefaultValue] = useState<any>(
    DEFAULT_REPORT_PERIODS_VALUE,
  );

  useEffect(() => {
    let updated = false;
    socketClient.on('update', data => {
      setDefaultValue((prevState: IUseFilterProps) => {
        const defaultData = DEFAULT_REPORT_PERIODS_VALUE(
          data.classes,
          data.sensors,
        );
        if (
          JSON.stringify(prevState) !== JSON.stringify(defaultData) &&
          !updated
        ) {
          updated = true;
          return defaultData;
        }
        return prevState;
      });
    });
  }, []);

  return defaultValue;
};
