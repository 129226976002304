import { Image, Page } from '@react-pdf/renderer';
import { IPDFTemplatePageSensorProps } from 'utils/interface';

import {
  StyledPDFTemplateImageWrapper,
  StyledPDFTemplateLineChartImageWrapper,
} from 'components/pdf/pdf-chart-generator/pdf-chart-generator.styled';

import { StyledPDFTemplatePageContent } from '../../pdf-template.styled';
import { PDFTemplateFooter } from '../../pdf-template-footer';
import { PDFTemplateHeader } from '../../pdf-template-header';
import {
  StyledPDFTemplateFlex,
  StyledPDFTemplateHeadingNoMargin,
  StyledPDFTemplateLabeledData,
  StyledPDFTemplateStrong,
} from '../pdf-template-page.styled';

export const PDFTemplatePageSensor = ({
  averageNoise,
  name,
  lineChart,
  ganttChart,
}: IPDFTemplatePageSensorProps) => (
  <Page size="A4">
    <PDFTemplateHeader />

    <StyledPDFTemplatePageContent>
      <StyledPDFTemplateHeadingNoMargin>
        Time course of noise level [dBA]
      </StyledPDFTemplateHeadingNoMargin>

      <StyledPDFTemplateFlex>
        <StyledPDFTemplateLabeledData>
          Sensor: <StyledPDFTemplateStrong>{name}</StyledPDFTemplateStrong>
        </StyledPDFTemplateLabeledData>

        <StyledPDFTemplateLabeledData>
          Average noise:{' '}
          <StyledPDFTemplateStrong>{averageNoise} dBA</StyledPDFTemplateStrong>
        </StyledPDFTemplateLabeledData>
      </StyledPDFTemplateFlex>

      <StyledPDFTemplateLineChartImageWrapper>
        <Image src={lineChart} />
      </StyledPDFTemplateLineChartImageWrapper>

      <StyledPDFTemplateImageWrapper>
        <Image src={ganttChart} />
      </StyledPDFTemplateImageWrapper>
    </StyledPDFTemplatePageContent>

    <PDFTemplateFooter>Sensor report - {name}</PDFTemplateFooter>
  </Page>
);
