import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDefaultReportsPeriodsValues } from 'hooks';
import { useToastContext } from 'hooks/context';
import { saveReportingPeriod, updateReportingPeriod } from 'utils/data-access';
import {
  setDataFromAPIToModal,
  setDataFromReportPeriodsToSend,
} from 'utils/mapper';
import { reportsPeriodsSchema } from 'utils/schemas';

export const useReportPeriods = (data: any, close: () => void) => {
  const { successToast, errorToast } = useToastContext();
  const defaultValues = useDefaultReportsPeriodsValues();
  const methodsReportPeriods = useForm<any>({
    defaultValues: data.id ? setDataFromAPIToModal(data) : defaultValues,
    resolver: yupResolver(reportsPeriodsSchema),
  });

  const handleForwardPage = () => methodsReportPeriods.setValue('step', 2);
  const handleBackPage = () => methodsReportPeriods.setValue('step', 1);

  const selectedSensors = Object.keys(
    methodsReportPeriods.watch('selectedSensorList'),
  ).filter(
    elem => methodsReportPeriods.watch('selectedSensorList')[elem] === true,
  ).length;

  const selectedClasses = Object.keys(
    methodsReportPeriods.watch('selectedClassList'),
  ).filter(
    elem => methodsReportPeriods.watch('selectedClassList')[elem] === true,
  ).length;

  const submitRecipe: SubmitHandler<any> = (submitData: any) => {
    const mappedSubmitData = setDataFromReportPeriodsToSend(submitData);

    if (!data?.id) {
      saveReportingPeriod(mappedSubmitData)
        .then(() => successToast('Your reporting period has been added'))
        .catch(() => errorToast('Fail to add report period'));
    } else {
      updateReportingPeriod(mappedSubmitData)
        .then(() => successToast('Your reporting period has been updated'))
        .catch(() => errorToast('Updating reporting period failed.'));
    }

    close();
  };

  return {
    methodsReportPeriods,
    selectedSensors,
    selectedClasses,
    handleForwardPage,
    handleBackPage,
    submitRecipe,
  };
};
