import { Card, CardContent, IconButton, styled } from '@mui/material';
import { ICardExpandableStyledProps } from 'utils/interface';

export const StyledCardExpandable = styled(Card)<ICardExpandableStyledProps>`
  position: ${({ expanded }) => (expanded ? 'absolute' : 'relative')};
  z-index: 2;
  width: 100%;
  min-height: 'auto';
  display: flex;
  flex-direction: column;
`;

export const StyledCardExpandedContent = styled(CardContent)`
  display: flex;
  flex-grow: 1;
`;

export const StyledCardExpandableButton = styled(IconButton)`
  position: absolute;
  top: ${({ theme }) => theme.spacing(2)};
  right: ${({ theme }) => theme.spacing(2)};
`;
