import { Table } from '@mui/material';
import { useCheckbox, usePagination, useSocketSensors } from 'hooks';
import { TABLE } from 'utils/const';
import { ISensorsTable } from 'utils/interface';
import { getSensorsTableDataMapper } from 'utils/mapper';

import { Pagination } from 'components/pagination';
import { TableHead } from 'components/table';

import { StyledSensorsTableContainer } from './sensors-table.styled';
import { SensorsTableBody } from './sensors-table-body';

const { SENSORS } = TABLE;

export const SensorsTable = (props: ISensorsTable) => {
  const { listName, data } = props;
  const { sensorsData: liveData } = useSocketSensors();
  const { selectedItems, onSelectAllChange, register } = useCheckbox(props);
  const sensorsTableData = getSensorsTableDataMapper(data, liveData);

  const {
    sliceStart,
    sliceEnd,
    rowsPerPage,
    page,
    paginationPage,
    paginationLimit,
    handleChangePage,
    handleChangePageSelect,
    handleChangeRowsPerPage,
    handleOnKeyDown,
  } = usePagination(sensorsTableData?.length);

  return sensorsTableData ? (
    <StyledSensorsTableContainer>
      <Table>
        <TableHead
          rowTitles={SENSORS}
          listName={listName}
          onSelectAllChange={onSelectAllChange}
          selectedItems={selectedItems}
        />

        <SensorsTableBody
          data={sensorsTableData}
          sliceStart={sliceStart}
          sliceEnd={sliceEnd}
          selectedItems={selectedItems}
          register={register}
          listName={listName}
        />
      </Table>

      {sensorsTableData.length > paginationLimit ? (
        <Pagination
          results={sensorsTableData.length}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          paginationPage={paginationPage}
          page={page}
          handleChangePageSelect={handleChangePageSelect}
          handleOnKeyDown={handleOnKeyDown}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : null}
    </StyledSensorsTableContainer>
  ) : null;
};
