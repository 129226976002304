import { fabric } from 'fabric';
import { colors } from 'styles/colors';
import { MAP } from 'utils/const';

const {
  INACTIVE_SENSOR: {
    GROUP: { LEFT_CENTER_POSITION, TOP_CENTER_POSITION },
    BOX,
    TEXT,
  },
  FONT_FAMILY,
} = MAP;

export const setInactiveSensorOnMap = (
  left: number,
  top: number,
  name: string,
) =>
  new fabric.Group(
    [inactiveSensorBox(left, top), inactiveSensorText(left, top, name)],
    {
      left: left - LEFT_CENTER_POSITION,
      top: top - TOP_CENTER_POSITION,
    },
  );

export const inactiveSensorBox = (left: number, top: number) =>
  new fabric.Rect({
    top,
    left,
    width: BOX.WIDTH,
    height: BOX.HEIGHT,
    fill: colors.map['offline-sensor-box-background'],
    rx: BOX.RX,
    ry: BOX.RY,
  });

export const inactiveSensorText = (left: number, top: number, text: string) =>
  new fabric.Text(text, {
    width: TEXT.WIDTH,
    fontSize: TEXT.FONT_SIZE,
    fontWeight: TEXT.FONT_WEIGHT,
    left: left + TEXT.LEFT,
    top: top + TEXT.TOP,
    fill: colors.map['text'],
    fontFamily: FONT_FAMILY,
  });
