import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { useLoaderContext } from 'hooks/context';
import html2canvas from 'html2canvas';
import { IGetPDFDataProps } from 'utils/interface/pdf-data';
import { getPDFData } from 'utils/mapper';

import { PDFTemplate } from 'components/pdf';

const generatePDFChart = async (type: string, index: number) => {
  const chart = document.querySelector(
    `#pdf-${type}-generator > div:nth-of-type(${index})`,
  ) as HTMLElement;
  let url = '';

  if (chart) {
    await html2canvas(chart).then((canvas: HTMLCanvasElement) => {
      url = canvas.toDataURL('image/png');
    });

    return url;
  }
};

export const useGenerateReport = () => {
  const { toggleLoader } = useLoaderContext();

  const generateReport = async (data: IGetPDFDataProps, id: number) => {
    const PDFData = getPDFData(data);

    const lineChart = generatePDFChart('line-chart', 1);
    const ganttChart = generatePDFChart('gantt-chart', 1);
    const map = generatePDFChart('map', 1);

    PDFData.sensors.forEach((sensor, index) => {
      sensor.lineChart = generatePDFChart('line-chart', index + 2);
      sensor.ganttChart = generatePDFChart('gantt-chart', index + 2);
    });

    const blob = await pdf(
      <PDFTemplate
        data={PDFData}
        lineChart={lineChart}
        ganttChart={ganttChart}
        sensors={PDFData.sensors}
        map={map}
      />,
    ).toBlob();

    const fileName = `Report_${id}`;

    saveAs(blob, fileName);
    toggleLoader(false);
  };

  return { generateReport };
};
