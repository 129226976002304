import { useHookFormContext, useNoiseViolationLimits } from 'hooks';
import { PERCENTAGES } from 'utils/const';
import { IGanttChartRow } from 'utils/interface';

import {
  StyledGanttChartItem,
  StyledGanttChartRow,
} from './gantt-chart-row.styled';

const { HUNDRED } = PERCENTAGES;

export const GanttChartRow = ({
  item,
  dateRange,
  noiseLimit,
}: IGanttChartRow) => {
  const {
    data: { noiseLimit: noiseLimitContext },
  } = useHookFormContext();
  const { status } = useNoiseViolationLimits(
    noiseLimit ? noiseLimit : undefined,
  );
  const { name, measurements } = item;
  return (
    <StyledGanttChartRow>
      <div>{name}</div>

      {measurements?.occurrence_spans.map((occurrence: number[], index) => {
        const occurrenceStart = (occurrence[0] / dateRange) * HUNDRED;
        const occurrenceEnd = HUNDRED - (occurrence[1] / dateRange) * HUNDRED;
        const partDba = Math.round(measurements.part_dba);
        const statusType = noiseLimit
          ? status(partDba)
          : !noiseLimitContext
          ? null
          : status(partDba);

        return (
          <StyledGanttChartItem
            key={`${occurrenceStart}-${index}`}
            leftPos={occurrenceStart}
            rightPos={occurrenceEnd}
            status={statusType}
          />
        );
      })}
    </StyledGanttChartRow>
  );
};
