import { Image, Page } from '@react-pdf/renderer';
import { IPDFTemplatePageSensorsProps } from 'utils/interface';

import {
  StyledPDFTemplateImageWrapper,
  StyledPDFTemplateLineChartImageWrapper,
} from 'components/pdf/pdf-chart-generator/pdf-chart-generator.styled';

import { StyledPDFTemplatePageContent } from '../../pdf-template.styled';
import { PDFTemplateFooter } from '../../pdf-template-footer';
import { PDFTemplateHeader } from '../../pdf-template-header';
import {
  StyledPDFTemplateFlex,
  StyledPDFTemplateHeading,
  StyledPDFTemplateLabeledData,
  StyledPDFTemplateStrong,
} from '../pdf-template-page.styled';

export const PDFTemplatePageSensors = ({
  lineChart,
  ganttChart,
  data: { average_noise },
}: IPDFTemplatePageSensorsProps) => (
  <Page size="A4">
    <PDFTemplateHeader />

    <StyledPDFTemplatePageContent>
      <StyledPDFTemplateHeading>
        Time course of noise level [dBA] - Sensors
      </StyledPDFTemplateHeading>

      <StyledPDFTemplateFlex>
        <StyledPDFTemplateLabeledData>
          Sensor: <StyledPDFTemplateStrong>All sensors</StyledPDFTemplateStrong>
        </StyledPDFTemplateLabeledData>

        <StyledPDFTemplateLabeledData>
          Average noise:{' '}
          <StyledPDFTemplateStrong>{average_noise} dBA</StyledPDFTemplateStrong>
        </StyledPDFTemplateLabeledData>
      </StyledPDFTemplateFlex>

      <StyledPDFTemplateLineChartImageWrapper>
        <Image src={lineChart} />
      </StyledPDFTemplateLineChartImageWrapper>

      <StyledPDFTemplateImageWrapper>
        <Image src={ganttChart} />
      </StyledPDFTemplateImageWrapper>
    </StyledPDFTemplatePageContent>

    <PDFTemplateFooter>
      Summary - <StyledPDFTemplateStrong>All sensors</StyledPDFTemplateStrong>
    </PDFTemplateFooter>
  </Page>
);
