import { useGanttChart, useHookFormContext, useWebsocketContext } from 'hooks';
import { getLineChartDataMapper } from 'utils/mapper/get-line-chart-data.mapper';

import { GanttChart, LineChart } from 'components/chart';

import { Placeholder } from '../placeholder';

export const ChartWrapper = () => {
  const { data } = useHookFormContext();
  const { noiseLimit, liveMode } = data;
  const { cartesianGridRef, ganttWidth } = useGanttChart();
  const { selectedClassesData, globalData } = useWebsocketContext();
  const lineChartData = getLineChartDataMapper(globalData?.noise_level);

  return (
    <>
      {lineChartData && lineChartData.length > 0 ? (
        <LineChart
          data={lineChartData}
          cartesianGridRef={cartesianGridRef}
          height={440}
          noiseLimit={noiseLimit}
          yMax={globalData?.y_axis_max}
          yMin={globalData?.y_axis_min}
          liveMode={liveMode}
        />
      ) : (
        <Placeholder>
          At least one sensor must be selected to show sensors diagram
        </Placeholder>
      )}

      {selectedClassesData &&
      selectedClassesData.length > 0 &&
      lineChartData &&
      lineChartData.length > 0 ? (
        <GanttChart
          data={selectedClassesData}
          width={ganttWidth}
          dates={data}
        />
      ) : (
        <Placeholder>
          At least one sensor and one class must be selected to show classes
          chart
        </Placeholder>
      )}
    </>
  );
};
