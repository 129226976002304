import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DEFAULT_LOGIN_VALUE } from 'utils/const';
import { ILocalStorage, IUser } from 'utils/interface';
import { loginSchema } from 'utils/schemas';

export const useLogin = ({ setToken }: ILocalStorage) => {
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: DEFAULT_LOGIN_VALUE,
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = ({ name, pass }: IUser) => {
    if (
      name === process.env.REACT_APP_USERNAME &&
      pass === process.env.REACT_APP_PASSWORD
    ) {
      setToken?.(true);
      return;
    }
  };

  const handleSetShowPassword = () => setShowPassword(value => !value);

  return {
    errors,
    showPassword,
    register,
    handleSubmit,
    onSubmit,
    handleSetShowPassword,
  };
};
