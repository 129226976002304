import { REGEX } from 'utils/const';
import * as yup from 'yup';

const { CHECK_FOR_HOUR } = REGEX;

export const filterSchema = yup.object({
  timeFrame: yup.string().matches(CHECK_FOR_HOUR).required(),
  noiseLimit: yup.mixed().test('noiseLimit', 'error', value => {
    if (value === '') return true;
    if (
      Number.isNaN(Number(value)) ||
      Number(value) > +process.env.REACT_APP_NOISE_LIMIT_MAX ||
      value === '0' ||
      Number(value) < 0 ||
      value.toString().includes('.')
    ) {
      return false;
    }
    return true;
  }),
});
