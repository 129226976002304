import { styled as muiStyled } from '@mui/material';
import { View } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import { theme as ctxTheme } from 'context/theme/theme';

export const StyledPDFChartGeneratorWrapper = muiStyled('div')`
  position: fixed;
  z-index: -1;
  top: 0;
  left: ${({ theme }) => theme.spacing(-1250)};
  width: ${({ theme }) => theme.spacing(117)};
`;

export const StyledPDFTemplateImageWrapper = styled(View)`
  align-items: 'center';
`;

export const StyledPDFTemplateLineChartImageWrapper = styled(
  StyledPDFTemplateImageWrapper,
)`
  margin-left: ${() => ctxTheme.spacing(-1.25)};
  margin-right: ${() => ctxTheme.spacing(1.25)};
`;
