import { styled, Tabs } from '@mui/material';

export const StyledTabs = styled(Tabs)`
  position: relative;
  z-index: 1;
  margin-bottom: ${({ theme }) => theme.spacing(2)};

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    bottom: ${({ theme }) => theme.spacing(0)};
    left: 0;
    right: 0;
    height: ${({ theme }) => theme.spacing(0.25)};
    background-color: ${({ theme }) => theme.palette.border.borderGrey};
  }
`;
