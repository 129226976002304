import React from 'react';
import { IReportPeriodsCheckboxList } from 'utils/interface';

export const useReportsPeriodsCheckbox = ({
  data,
  listName,
  listTitle = '',
  methods,
}: IReportPeriodsCheckboxList) => {
  const { register, setValue, watch } = methods;
  const selectedItems = watch(listName);
  const title = listTitle.toLowerCase();

  const onSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    data.map(item => {
      const setChecked = () =>
        setValue(`${listName}.${item.id}`, event.target.checked);

      if ('available' in item) {
        return item.available === true ? setChecked() : null;
      }

      setChecked();
      return null;
    });
  };

  return {
    selectedItems,
    title,
    onSelectAllChange,
    register,
  };
};
