import { useCallback, useState } from 'react';
import { FabricJSEditor } from 'fabricjs-react';

export const useZoomLevel = (editor?: FabricJSEditor) => {
  const [zoomLevel, setZoomLevel] = useState(1);

  const handleAddZoomLevel = useCallback(() => {
    if (zoomLevel < 2) {
      setZoomLevel(prevState => ++prevState);
      editor?.zoomIn();
    }
  }, [editor, zoomLevel]);

  const handleOddZoomLevel = useCallback(() => {
    if (zoomLevel > 0) {
      setZoomLevel(prevState => --prevState);
      editor?.zoomOut();
    }
  }, [editor, zoomLevel]);

  return {
    handleAddZoomLevel,
    handleOddZoomLevel,
  };
};
