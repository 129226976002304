import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { MdOutlineDateRange } from 'react-icons/md';
import {
  Button,
  Checkbox,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
} from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import {
  FORMAT,
  TIME_PLACEHOLDER,
  VARIOUS_TIMES,
  WEEK_DAYS,
} from 'utils/const';

import { Modal } from 'components/modal';
import { SubTitle } from 'components/subtitle';
import { Title } from 'components/title';

import {
  StyledReportPeriodsFormControl,
  StyledReportPeriodsHoursField,
  StyledReportPeriodsInputContainer,
  StyledReportPeriodsSelect,
  StyledReportPeriodsTextField,
  StyledTitleStep,
} from '../report-periods.styled';
import { ReportPeriodsLabel } from '../report-periods-label';
import { ReportPeriodsTimeFrame } from '../report-periods-time-frame';

const { DEFAULT_TIME, CORRECT_TIME_LENGTH } = VARIOUS_TIMES;
const { DATE, TIME } = FORMAT;

export const RaportPeriodsSecondPage = ({
  data,
  selectedSensors,
  selectedClasses,
  methods,
  handleBackPage,
  submitRecipe,
}: any) => {
  const [openReportPeriodsTimeFrameModal, setOpenReportPeriodsTimeFrameModal] =
    useState(false);
  const [hoursFrom, setHoursFrom] = useState<Dayjs | null>(
    methods.getValues('hoursFrom') || DEFAULT_TIME,
  );
  const [hoursTo, setHoursTo] = useState<Dayjs | null>(
    methods.getValues('hoursTo') || DEFAULT_TIME,
  );

  const handleTimePickerHoursFrom = (value: dayjs.Dayjs | null) => {
    if (!value) return;
    if (value.format(TIME).length === CORRECT_TIME_LENGTH) {
      setHoursFrom(value);
      methods.setValue('hoursFrom', dayjs(value).format(TIME));
    }
  };

  const handleTimePickerHoursTo = (value: dayjs.Dayjs | null) => {
    if (!value) return;
    if (value.format(TIME).length === CORRECT_TIME_LENGTH) {
      setHoursTo(value);
      methods.setValue('hoursTo', dayjs(value).format(TIME));
    }
  };

  const handleConfirmReportPeriodsTimeFrameModal = () => {
    setOpenReportPeriodsTimeFrameModal(false);
    methods.setValue('dateFrom', dayjs().format(`${DATE} ${TIME}`));
    methods.setValue(
      'dateTo',
      dayjs().subtract(5, 'm').format(`${DATE} ${TIME}`),
    );
  };

  const handleSelect = (event: any) => {
    const {
      target: { value },
    } = event;
    methods.setValue(
      'repeatForDays',
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleOpenReportPeriodsTimeFrameModal = () =>
    setOpenReportPeriodsTimeFrameModal(true);

  const handleCloseReportPeriodsTimeFrameModal = () => {
    setOpenReportPeriodsTimeFrameModal(false);

    if (!data.id) {
      methods.setValue('dateRange', []);
      methods.setValue('timeFrame', 'Please insert date');
      methods.setValue('dateFrom', DATE);
      methods.setValue('dateTo', DATE);
    }
  };

  return (
    <>
      <Title
        title={'Data and recurrence'}
        subTitle={'Fill the details regarding data and report'}
      />
      <StyledTitleStep>Step 2/2</StyledTitleStep>
      <div style={{ display: 'flex', gap: '8px', padding: '12px 0' }}>
        <ReportPeriodsLabel
          text={'Selected sensors:'}
          data={selectedSensors.toString()}
        />
        <ReportPeriodsLabel
          text={'Selected classes:'}
          data={selectedClasses.toString()}
        />
      </div>

      <div>
        <SubTitle text="Date:" />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label="Hours From"
              value={hoursFrom}
              ampm={false}
              onChange={handleTimePickerHoursFrom}
              renderInput={params => {
                const inputPropsValue =
                  typeof hoursFrom !== 'string'
                    ? dayjs(hoursFrom).format(TIME)
                    : hoursFrom;

                return (
                  <StyledReportPeriodsHoursField
                    {...params}
                    {...methods.register('hoursFrom')}
                    inputProps={{ value: inputPropsValue }}
                    placeholder={TIME_PLACEHOLDER}
                    error={!!methods.formState.errors['hoursFrom']}
                    helperText={
                      !!methods.formState.errors['hoursFrom']
                        ? 'Please insert time'
                        : null
                    }
                  />
                );
              }}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label="Hours To"
              value={hoursTo}
              ampm={false}
              onChange={handleTimePickerHoursTo}
              renderInput={params => {
                const inputPropsValue =
                  typeof hoursTo !== 'string'
                    ? dayjs(hoursTo).format(TIME)
                    : hoursTo;

                return (
                  <StyledReportPeriodsHoursField
                    {...params}
                    {...methods.register('hoursTo')}
                    inputProps={{ value: inputPropsValue }}
                    placeholder={TIME_PLACEHOLDER}
                    error={!!methods.formState.errors['hoursTo']}
                    helperText={
                      !!methods.formState.errors['hoursTo']
                        ? 'Please insert time'
                        : null
                    }
                  />
                );
              }}
            />
          </LocalizationProvider>
        </div>
        <StyledReportPeriodsInputContainer>
          <Controller
            control={methods.control}
            name="noiseLimit"
            render={({ field: { onChange, value } }) => (
              <StyledReportPeriodsTextField
                value={value === 0 ? '' : value}
                label="Noise limit (dBA)"
                onChange={onChange}
                error={!!methods.formState.errors['noiseLimit']}
                helperText={
                  !!methods.formState.errors['noiseLimit']
                    ? 'Please insert a number between 1 and 119 '
                    : null
                }
              />
            )}
          />
        </StyledReportPeriodsInputContainer>
        <SubTitle text="Recurrence:" />
        <Controller
          control={methods.control}
          name="timeFrame"
          render={({ field: { onChange, value } }) => (
            <StyledReportPeriodsTextField
              value={value}
              onClick={handleOpenReportPeriodsTimeFrameModal}
              onChange={onChange}
              disabled
              label="Time Frame"
              error={!!methods.formState.errors['timeFrame']}
              InputProps={{
                startAdornment: <MdOutlineDateRange />,
              }}
              helperText={
                !!methods.formState.errors['timeFrame']
                  ? 'Please choose date'
                  : null
              }
            />
          )}
        />
        <div style={{ maxWidth: '230px' }}>
          <Controller
            control={methods.control}
            name="repeatForDays"
            render={({ field: { value } }) => (
              <StyledReportPeriodsFormControl>
                <InputLabel id="repeat-for-days-label">
                  Repeat for Days
                </InputLabel>

                <StyledReportPeriodsSelect
                  labelId="repeat-for-days-label"
                  id="repeat-for-days"
                  multiple
                  input={<OutlinedInput label="Repeat for Days" />}
                  value={value}
                  onChange={handleSelect}
                  renderValue={(selected: any) => selected.sort().join(', ')}
                  error={!!methods.formState.errors['repeatForDays']}
                >
                  {WEEK_DAYS.map(name => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={value.includes(name)} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </StyledReportPeriodsSelect>
                {!!methods.formState.errors['repeatForDays'] && (
                  <FormHelperText
                    style={{ color: '#D41B1B', marginLeft: '12px' }}
                  >
                    Please choose day
                  </FormHelperText>
                )}
              </StyledReportPeriodsFormControl>
            )}
          />
        </div>
        <div>
          <Controller
            control={methods.control}
            name="repeatForWeeks"
            render={({ field: { onChange, value } }) => (
              <StyledReportPeriodsTextField
                value={value}
                onChange={onChange}
                type={'number'}
                InputProps={{ inputProps: { min: 1, max: 5 } }}
                label="Repeat For Week"
                error={!!methods.formState.errors['repeatForWeeks']}
              />
            )}
          />
        </div>
      </div>

      <div style={{ display: 'flex', marginTop: '8px' }}>
        <Button
          variant="text"
          onClick={handleBackPage}
          style={{ textDecoration: 'underline' }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          onClick={() => methods.handleSubmit(submitRecipe)()}
          style={{ marginLeft: 'auto' }}
        >
          Add
        </Button>
      </div>

      <Modal
        open={openReportPeriodsTimeFrameModal}
        onCloseModal={handleCloseReportPeriodsTimeFrameModal}
        onClose={handleCloseReportPeriodsTimeFrameModal}
        aria-labelledby="handleOpenReportPeriodsTimeFrameModal"
        aria-describedby="handleOpenReportPeriodsTimeFrameModal"
      >
        <ReportPeriodsTimeFrame
          methods={methods}
          handleConfirmReportPeriodsTimeFrameModal={
            handleConfirmReportPeriodsTimeFrameModal
          }
          handleCloseReportPeriodsTimeFrameModal={
            handleCloseReportPeriodsTimeFrameModal
          }
        />
      </Modal>
    </>
  );
};
