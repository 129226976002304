import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material';

export const NavigationContainer = styled('div')`
  display: flex;
  .active {
    color: ${({ theme }) => theme.palette.custom.orangeDark};
  }
`;

export const NavigationLink = styled(NavLink)`
  color: ${({ theme }) => theme.palette.custom.whiteLink};
  text-decoration: none;
  font-size: ${({ theme }) => theme.typography.customFontSize.medium};
  font-weight: 400;
  line-height: ${({ theme }) => theme.spacing(2.75)};
`;
