import { useCallback, useState } from 'react';
import { AlertColor } from '@mui/material';
import { TOAST } from 'utils/const';

const { SUCCESS, ERROR } = TOAST;

export const useToast = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [text, setText] = useState('This is default text');
  const [severity, setSeverity] = useState<AlertColor>(SUCCESS);

  const successToast = useCallback((text: string, visible = true) => {
    setIsOpen(visible);
    setText(text);
    setSeverity(SUCCESS);
  }, []);

  const errorToast = useCallback((text: string, visible = true) => {
    setIsOpen(visible);
    setText(text);
    setSeverity(ERROR);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return {
    isOpen,
    text,
    severity,
    handleClose,
    successToast,
    errorToast,
  };
};
