import { Checkbox } from '@mui/material';
import { useReportsPeriodsCheckbox } from 'hooks';
import { getCheckedAllStatus } from 'utils/helpers';
import { IReportPeriodsCheckboxList } from 'utils/interface';
import { getCheckboxesDataMapperReportPeriods } from 'utils/mapper';

import {
  StyledCheckboxListFormControlLabel,
  StyledCheckboxListScrollableWrapper,
  StyledCheckboxListStack,
} from 'components/list/checkbox-list/checkbox-list.styled';
import { CheckboxListItemLabel } from 'components/list/checkbox-list/checkbox-list-item-label';

export const ReportPeriodsCheckboxList = (
  props: IReportPeriodsCheckboxList,
) => {
  const { data, listName, methods } = props;
  const { register } = methods;
  const { selectedItems, title, onSelectAllChange } =
    useReportsPeriodsCheckbox(props);
  const incomingData = Object.entries(methods.getValues(listName)).reduce(
    (ini: any, [k, v]) => ((ini[k] = v ? v : undefined), ini),
    [],
  );
  const checkboxData = getCheckboxesDataMapperReportPeriods(data, incomingData);

  return (
    <StyledCheckboxListStack role="group">
      <StyledCheckboxListFormControlLabel
        label={<CheckboxListItemLabel name={`Select all ${title}`} />}
        control={
          <Checkbox
            id={listName}
            color="success"
            onChange={onSelectAllChange}
            checked={
              JSON.stringify(selectedItems) === '{}'
                ? false
                : getCheckedAllStatus(selectedItems)
            }
          />
        }
      />

      <StyledCheckboxListScrollableWrapper>
        {checkboxData.map(({ disabled, id, name, isChecked }) => {
          const idString = id.toString();

          return (
            <StyledCheckboxListFormControlLabel
              hidden={disabled}
              key={idString}
              label={
                <CheckboxListItemLabel
                  name={name}
                  noiseLevel={undefined}
                  noiseLevelLabel={false}
                />
              }
              control={
                <Checkbox
                  id={idString}
                  color="success"
                  {...register(`${listName}.${id}`, {
                    value: isChecked,
                    disabled: disabled,
                  })}
                  checked={isChecked}
                />
              }
            />
          );
        })}
      </StyledCheckboxListScrollableWrapper>
    </StyledCheckboxListStack>
  );
};
