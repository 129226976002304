import { REGEX } from 'utils/const';
import * as yup from 'yup';

const { CHECK_FOR_TIMEFRAME_REPORT_PERIODS } = REGEX;

export const reportsPeriodsSchema = yup.object({
  timeFrame: yup
    .string()
    .matches(CHECK_FOR_TIMEFRAME_REPORT_PERIODS)
    .required(),
  hoursFrom: yup
    .string()
    .matches(/\d\d?:\d\d/g)
    .min(4)
    .required()
    .test('hoursFrom', 'error', value => {
      if (!value) return false;
      const arrVal = value.split('');
      if (arrVal.length === 5) {
        if (+arrVal[0] > 2) return false;
        if (+arrVal[3] > 5) return false;
      }
      if (arrVal.length === 4 && +arrVal[2] > 5) return false;
      return true;
    }),
  hoursTo: yup
    .string()
    .matches(/\d\d:\d\d/g)
    .required()
    .test('hoursTo', 'error', value => {
      if (!value) return false;
      const arrVal = value.split('');
      if (+arrVal[0] > 2) return false;
      if (+arrVal[3] > 5) return false;
      return true;
    }),
  noiseLimit: yup.mixed().test('noiseLimit', 'error', value => {
    if (value === '') return true;
    if (
      Number.isNaN(Number(value)) ||
      Number(value) > +process.env.REACT_APP_NOISE_LIMIT_MAX ||
      value === '0' ||
      Number(value) < 0 ||
      value.toString().includes('.')
    ) {
      return false;
    }
    return true;
  }),
  repeatForDays: yup.array().min(1).required(),
});
