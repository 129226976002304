import { createContext } from 'react';
import { FormProvider } from 'react-hook-form';
import { useFilter } from 'hooks';
import { IChildrenProps, IHookFormContext } from 'utils/interface';

export const HookFormContext = createContext<IHookFormContext | null>(null);

export function CustomFormProvider({ children }: IChildrenProps) {
  const filterData = useFilter();

  return (
    <HookFormContext.Provider value={filterData}>
      <FormProvider {...filterData.methods}>{children}</FormProvider>
    </HookFormContext.Provider>
  );
}
