import { createContext, useMemo } from 'react';
import { useSocket } from 'hooks';
import { useSocketGlobal } from 'hooks/websocket/use-socket-global.hook';
import { getSelectedClasses } from 'utils/helpers';
import { IChildrenProps, IWebsocketContext } from 'utils/interface';

export const WebsocketContext = createContext<IWebsocketContext | null>(null);

export const WebsocketProvider = ({ children }: IChildrenProps) => {
  useSocket();
  const { classesData, globalData, sensorsData } = useSocketGlobal();
  const selectedClassesData = useMemo(
    () => getSelectedClasses(classesData),
    [classesData],
  );
  const value = { classesData, globalData, selectedClassesData, sensorsData };

  return (
    <WebsocketContext.Provider value={value}>
      {children}
    </WebsocketContext.Provider>
  );
};
