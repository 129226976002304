import { Dayjs } from 'dayjs';
import { HTTP } from 'utils/const';

import { AxiosInstance } from './axios-instance';

const {
  ENDPOINTS: { INTERVAL },
} = HTTP;

export const getInterval = async (
  dateFrom: Dayjs,
  dateTo: Dayjs,
): Promise<number> =>
  await AxiosInstance.post(INTERVAL, {
    time_from: dateFrom,
    time_to: dateTo,
  })
    .then(res => res.data)
    .then(data => data);
