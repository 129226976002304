import { useHookFormContext } from 'hooks';

import { Modal } from 'components/modal';

import { Calendar } from '../calendar';

export const CalendarModal = () => {
  const { openCalendarModal, handleCloseCalendarModal } = useHookFormContext();
  return (
    <Modal
      open={openCalendarModal}
      onCloseModal={handleCloseCalendarModal}
      onClose={handleCloseCalendarModal}
      aria-labelledby="modal-filters"
      aria-describedby="modal-filters"
    >
      <Calendar />
    </Modal>
  );
};
