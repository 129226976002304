import { RiCloseFill } from 'react-icons/ri';
import { Modal as MuiModal } from '@mui/material';
import { IModal } from 'utils/interface';

import { StyledModalContainer, StyledModalIconButton } from './modal.styled';

export const Modal = ({ children, onCloseModal, ...props }: IModal) => (
  <MuiModal {...props}>
    <StyledModalContainer>
      <div>
        <StyledModalIconButton onClick={onCloseModal}>
          <RiCloseFill />
        </StyledModalIconButton>
        {children}
      </div>
    </StyledModalContainer>
  </MuiModal>
);
