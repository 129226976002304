import { MdError, MdWarning } from 'react-icons/md';
import { styled } from '@mui/material';

export const StyledMdError = styled(MdError)`
  fill: ${({ theme }) => theme.palette.error.main};
`;

export const StyledMdWarning = styled(MdWarning)`
  fill: ${({ theme }) => theme.palette.warning.main};
`;

export const StyledScreenReaderOnly = styled('h1')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: ${({ theme }) => theme.spacing(0.125)};
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: ${({ theme }) => theme.spacing(0.125)};
`;
