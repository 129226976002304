import { styled } from '@mui/material';

export const BaseLabel = styled('span')`
  border-radius: ${({ theme }) => theme.spacing(0.625)};
  padding: ${({ theme }) => theme.spacing(0.375, 1)};
  display: inline-block;
  text-transform: capitalize;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.customFontSize.xSmall};
`;

export const SuccessLabel = styled(BaseLabel)`
  background: ${({ theme }) => theme.palette.success.light};
  color: ${({ theme }) => theme.palette.custom.greenTextLabel};
`;

export const ErrorLabel = styled(BaseLabel)`
  color: ${({ theme }) => theme.palette.custom.redTextLabel};
  background: ${({ theme }) => theme.palette.error.light};
`;

export const WarningLabel = styled(BaseLabel)`
  color: ${({ theme }) => theme.palette.custom.orangeTextLabel};
  background: ${({ theme }) => theme.palette.warning.light};
`;
