import { useContext } from 'react';
import { WebsocketContext } from 'context/websocket';

export const useWebsocketContext = () => {
  const context = useContext(WebsocketContext);

  if (!context) {
    throw new Error(
      'useWebsocketContext must be used within a WebsocketProvider',
    );
  }

  return context;
};
