import { useState } from 'react';
import { BsThreeDots } from 'react-icons/bs';
import { IconButton, Menu } from '@mui/material';
import { IDropdownActionsProps } from 'utils/interface';

export const DropdownActions = ({
  children,
  id,
  getData,
}: IDropdownActionsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    getData(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        id={id.toString()}
        aria-controls={open ? `${id}-actions` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <BsThreeDots />
      </IconButton>

      <Menu
        id={`${id}-actions`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{
          'aria-labelledby': id.toString(),
        }}
      >
        {children}
      </Menu>
    </>
  );
};
