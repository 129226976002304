import { StyledScreenReaderOnly } from './icons.styled';

export const Logo = () => (
  <div>
    <StyledScreenReaderOnly>KFB - Monitoring System</StyledScreenReaderOnly>
    <svg
      width="96"
      height="32"
      viewBox="0 0 96 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>KFB</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.4386 0.220093H75.4983C75.6054 0.220093 75.6922 0.308838 75.6922 0.41838V4.07887C75.6922 4.18834 75.6054 4.27716 75.4983 4.27716H66.1664V8.82253H73.4398C73.5468 8.82253 73.6338 8.91136 73.6338 9.02082V12.9467C73.6338 13.0563 73.5468 13.145 73.4398 13.145H66.1664V20.8609C66.1664 20.9704 66.0795 21.0592 65.9724 21.0592H61.4386C61.3315 21.0592 61.2446 20.9704 61.2446 20.8609V0.41838C61.2446 0.308838 61.3315 0.220093 61.4386 0.220093Z"
        fill="currentColor"
      />
      <mask
        id="mask0_202_3395"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="78"
        y="0"
        width="18"
        height="22"
      >
        <path
          d="M78.8906 0.220093H95.9732V21.0592H78.8906V0.220093Z"
          fill="currentColor"
        />
      </mask>
      <g mask="url(#mask0_202_3395)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M87.7159 17.2062C90.2562 17.2062 91.082 16.2504 91.082 14.7094C91.082 13.2297 90.2562 12.2431 87.7159 12.2431H83.8124V17.2062H87.7159ZM87.1763 8.3892C89.3032 8.3892 90.0017 7.58807 90.0017 6.2319C90.0017 4.9677 89.3032 4.07355 87.1448 4.07355H83.8124V8.3892H87.1763ZM78.8906 0.41838C78.8906 0.308838 78.9774 0.220093 79.0846 0.220093H87.5266C92.9248 0.220093 94.8614 2.50144 94.8614 5.79994C94.8614 7.98884 93.7822 9.31438 91.9403 10.0231C94.0683 10.547 95.9733 12.2431 95.9733 15.0791C95.9733 19.5488 92.6387 21.0592 87.5581 21.0592H79.0846C78.9774 21.0592 78.8906 20.9704 78.8906 20.8609V0.41838Z"
          fill="currentColor"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.5052 10.6397L57.3555 0.541785C57.4565 0.41177 57.366 0.220093 57.2037 0.220093H52.1577C52.0985 0.220093 52.0426 0.24766 52.0058 0.295055L44.5849 9.84049V0.41838C44.5849 0.308838 44.498 0.220093 44.3909 0.220093H39.8585C39.7514 0.220093 39.6646 0.308838 39.6646 0.41838V20.8609C39.6646 20.9705 39.7514 21.0592 39.8585 21.0592H44.3909C44.498 21.0592 44.5849 20.9705 44.5849 20.8609V11.4388L52.0058 20.9843C52.0426 21.0316 52.0985 21.0592 52.1577 21.0592H57.2037C57.366 21.0592 57.4565 20.8675 57.3555 20.7376L49.5052 10.6397Z"
        fill="currentColor"
      />
      <mask
        id="mask1_202_3395"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="32"
      >
        <path
          d="M0 0.0805664H31.1656V31.935H0V0.0805664Z"
          fill="currentColor"
        />
      </mask>
      <g mask="url(#mask1_202_3395)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.1657 31.935H0V15.8198H3.97982C4.32505 15.8198 4.66729 15.7027 4.94329 15.49L6.1785 14.5361C6.65526 14.1698 7.27942 14.0578 7.84887 14.2392C8.41871 14.4206 8.87092 14.8746 9.05858 15.4527L11.0814 21.7074L14.981 6.57479C15.2098 5.84484 15.7839 5.40047 16.4633 5.37354C17.141 5.35879 17.7486 5.74416 18.008 6.3865L18.0381 6.46985L21.0384 15.6654L22.3407 14.9684C23.1827 14.4362 24.3133 14.3677 25.2904 14.826L26.999 15.7409C27.0907 15.7825 27.2593 15.8198 27.4266 15.8198H28.6435V2.65846H2.52221V12.5258H0V0.0805664H31.1657V18.3978H27.4266C26.8988 18.3978 26.3659 18.2789 25.8865 18.0532L24.1771 17.1383C24.0263 17.0689 23.7826 17.0837 23.5851 17.2086L21.9662 18.0784C21.3984 18.4169 20.7225 18.4811 20.1021 18.2494C19.4665 18.0132 18.979 17.5012 18.7651 16.8458L16.628 10.296L12.5394 26.162C12.3399 26.7965 11.8291 27.1897 11.2479 27.2079C10.6427 27.2453 10.1324 26.8686 9.9184 26.3165L6.95676 17.1653L6.46295 17.5472C5.75132 18.0958 4.86979 18.3978 3.97982 18.3978H2.52221V29.3571H28.6435V21.7014H31.1657V31.935Z"
          fill="#FF5B22"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.0415 30.43L41.4967 28.8344L40.9519 30.43H42.0415ZM42.2401 31.0027H40.7585L40.4968 31.7625H39.7163L41.0779 28.0261H41.9207L43.2771 31.7625H42.4973L42.2401 31.0027Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.6867 28.3102C49.9906 28.5527 50.1914 28.8829 50.2893 29.3004H49.5139C49.4339 29.0966 49.3094 28.936 49.1396 28.8186C48.9707 28.7004 48.7668 28.6421 48.5297 28.6421C48.334 28.6421 48.1575 28.692 48.0004 28.7921C47.8432 28.8921 47.721 29.0344 47.6343 29.2201C47.5468 29.4057 47.5031 29.6231 47.5031 29.8732C47.5031 30.1194 47.5468 30.3353 47.6343 30.521C47.721 30.7065 47.8432 30.8489 48.0004 30.9489C48.1575 31.049 48.334 31.099 48.5297 31.099C48.7668 31.099 48.9707 31.0398 49.1396 30.9224C49.3094 30.8042 49.4339 30.6436 49.5139 30.4406H50.2893C50.1914 30.858 49.9906 31.1884 49.6867 31.4308C49.3835 31.6733 49.001 31.7944 48.54 31.7944C48.1872 31.7944 47.8758 31.7134 47.6054 31.5512C47.3348 31.3891 47.1251 31.161 46.9768 30.8686C46.8285 30.5762 46.7544 30.2444 46.7544 29.8732C46.7544 29.4981 46.8285 29.1649 46.9768 28.8724C47.1251 28.5792 47.3341 28.3519 47.6024 28.1898C47.8714 28.0269 48.1843 27.9458 48.54 27.9458C49.001 27.9458 49.3835 28.067 49.6867 28.3102Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.2022 28.7754C55.0376 28.8753 54.9094 29.0193 54.8167 29.2065C54.7248 29.3935 54.6781 29.6126 54.6781 29.8625C54.6781 30.1117 54.7248 30.3307 54.8167 30.5178C54.9094 30.7057 55.0376 30.8489 55.2022 30.9489C55.366 31.0489 55.5565 31.0989 55.7729 31.0989C55.9893 31.0989 56.1798 31.0489 56.3437 30.9489C56.5075 30.8489 56.6364 30.7057 56.7284 30.5178C56.821 30.3307 56.8677 30.1117 56.8677 29.8625C56.8677 29.6126 56.821 29.3935 56.7284 29.2065C56.6364 29.0193 56.5075 28.8753 56.3437 28.7754C56.1798 28.6762 55.9893 28.6261 55.7729 28.6261C55.5565 28.6261 55.366 28.6762 55.2022 28.7754ZM56.7128 28.1761C56.9936 28.3405 57.2153 28.5693 57.3783 28.8641C57.5407 29.1587 57.6215 29.4913 57.6215 29.8625C57.6215 30.2338 57.5407 30.5672 57.3783 30.8633C57.2153 31.1595 56.9936 31.3899 56.7128 31.5535C56.4318 31.7179 56.1183 31.7998 55.7729 31.7998C55.4275 31.7998 55.114 31.7179 54.8331 31.5535C54.5514 31.3899 54.3298 31.1595 54.1675 30.8633C54.0052 30.5672 53.9243 30.2338 53.9243 29.8625C53.9243 29.4913 54.0052 29.1587 54.1675 28.8641C54.3298 28.5693 54.5514 28.3405 54.8331 28.1761C55.114 28.0117 55.4275 27.9299 55.7729 27.9299C56.1183 27.9299 56.4318 28.0117 56.7128 28.1761Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.0836 27.9836V30.1996C62.0836 30.4958 62.1474 30.7178 62.2749 30.8663C62.4023 31.0141 62.5862 31.0883 62.8271 31.0883C63.065 31.0883 63.2473 31.0141 63.3748 30.8663C63.5023 30.7178 63.566 30.4958 63.566 30.1996V27.9836H64.299V30.1996C64.299 30.5496 64.2354 30.8443 64.1079 31.0852C63.9803 31.3262 63.804 31.5057 63.5786 31.6231C63.3541 31.7413 63.0976 31.7997 62.8115 31.7997C62.3682 31.7997 62.014 31.6649 61.7486 31.3928C61.4833 31.1223 61.3506 30.7239 61.3506 30.1996V27.9836H62.0836Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.5413 31.2618C70.4472 31.4247 70.3078 31.555 70.1225 31.6528C69.9373 31.7512 69.7119 31.7998 69.4473 31.7998C69.1849 31.7998 68.9514 31.7542 68.7454 31.6633C68.5393 31.5725 68.374 31.4414 68.2501 31.2702C68.1264 31.0989 68.0553 30.8989 68.0383 30.6709H68.8239C68.841 30.8239 68.901 30.952 69.004 31.0535C69.107 31.155 69.2442 31.2058 69.4154 31.2058C69.5689 31.2058 69.6867 31.1641 69.7689 31.08C69.8513 30.9967 69.8919 30.8883 69.8919 30.7565C69.8919 30.6383 69.8587 30.5414 69.7927 30.4648C69.726 30.3876 69.6421 30.3262 69.5414 30.28C69.4399 30.2338 69.3005 30.18 69.1219 30.1194C68.8921 30.0406 68.7031 29.9633 68.5563 29.8868C68.4103 29.8095 68.285 29.6982 68.182 29.5519C68.0791 29.4057 68.0278 29.2148 68.0278 28.9792C68.0278 28.6542 68.142 28.3982 68.3711 28.2109C68.5993 28.0238 68.9025 27.9299 69.2791 27.9299C69.6704 27.9299 69.9817 28.0261 70.2144 28.2193C70.4465 28.4118 70.5784 28.6686 70.6095 28.9898H69.8134C69.7927 28.8542 69.7363 28.7428 69.6459 28.6549C69.5548 28.5678 69.431 28.5238 69.2738 28.5238C69.1382 28.5238 69.0277 28.561 68.944 28.6338C68.8602 28.7072 68.8187 28.8133 68.8187 28.9527C68.8187 29.0633 68.8506 29.1549 68.915 29.2277C68.9796 29.3012 69.0619 29.361 69.1612 29.4072C69.2612 29.4534 69.3961 29.5058 69.5673 29.5625C69.8045 29.6481 69.998 29.7285 70.1462 29.8034C70.2945 29.8785 70.4212 29.9906 70.5257 30.1406C70.6303 30.2906 70.6828 30.4868 70.6828 30.7292C70.6828 30.9225 70.6354 31.0997 70.5413 31.2618Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.9364 27.9836V28.5829H75.915V31.7625H75.1769V28.5829H74.1606V27.9836H76.9364Z"
        fill="currentColor"
      />
      <mask
        id="mask2_202_3395"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="96"
        height="32"
      >
        <path d="M0 31.9351H95.9733V0.0805664H0V31.9351Z" fill="currentColor" />
      </mask>
      <g mask="url(#mask2_202_3395)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M80.5396 31.7625H81.2778V27.9836H80.5396V31.7625Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M87.9606 28.3102C88.2645 28.5527 88.4654 28.8829 88.5632 29.3004H87.7878C87.7078 29.0966 87.5833 28.936 87.4136 28.8186C87.2446 28.7004 87.0408 28.6421 86.8035 28.6421C86.6078 28.6421 86.4315 28.692 86.2743 28.7921C86.1172 28.8921 85.9949 29.0344 85.9082 29.2201C85.8207 29.4057 85.777 29.6231 85.777 29.8732C85.777 30.1194 85.8207 30.3353 85.9082 30.521C85.9949 30.7065 86.1172 30.8489 86.2743 30.9489C86.4315 31.049 86.6078 31.099 86.8035 31.099C87.0408 31.099 87.2446 31.0398 87.4136 30.9224C87.5833 30.8042 87.7078 30.6436 87.7878 30.4406H88.5632C88.4654 30.858 88.2645 31.1884 87.9606 31.4308C87.6574 31.6733 87.2749 31.7944 86.814 31.7944C86.4611 31.7944 86.1498 31.7134 85.8793 31.5512C85.6087 31.3891 85.399 31.161 85.2507 30.8686C85.1025 30.5762 85.0283 30.2444 85.0283 29.8732C85.0283 29.4981 85.1025 29.1649 85.2507 28.8724C85.399 28.5792 85.608 28.3519 85.8763 28.1898C86.1453 28.0269 86.4581 27.9458 86.814 27.9458C87.2749 27.9458 87.6574 28.067 87.9606 28.3102Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M94.7117 31.2618C94.6176 31.4247 94.4782 31.555 94.2929 31.6528C94.1076 31.7512 93.8823 31.7998 93.6176 31.7998C93.3552 31.7998 93.1218 31.7542 92.9157 31.6633C92.7097 31.5725 92.5443 31.4414 92.4206 31.2702C92.2968 31.0989 92.2256 30.8989 92.2086 30.6709H92.9943C93.0113 30.8239 93.0714 30.952 93.1743 31.0535C93.2775 31.155 93.4145 31.2058 93.5857 31.2058C93.7392 31.2058 93.8571 31.1641 93.9393 31.08C94.0216 30.9967 94.0623 30.8883 94.0623 30.7565C94.0623 30.6383 94.029 30.5414 93.963 30.4648C93.8963 30.3876 93.8126 30.3262 93.7117 30.28C93.6102 30.2338 93.4709 30.18 93.2923 30.1194C93.0624 30.0406 92.8735 29.9633 92.7266 29.8868C92.5807 29.8095 92.4554 29.6982 92.3523 29.5519C92.2494 29.4057 92.1982 29.2148 92.1982 28.9792C92.1982 28.6542 92.3123 28.3982 92.5414 28.2109C92.7697 28.0238 93.0728 27.9299 93.4494 27.9299C93.8407 27.9299 94.152 28.0261 94.3848 28.2193C94.6168 28.4118 94.7487 28.6686 94.7798 28.9898H93.9838C93.963 28.8542 93.9067 28.7428 93.8163 28.6549C93.7251 28.5678 93.6014 28.5238 93.4442 28.5238C93.3086 28.5238 93.1981 28.561 93.1143 28.6338C93.0306 28.7072 92.9891 28.8133 92.9891 28.9527C92.9891 29.0633 93.0209 29.1549 93.0854 29.2277C93.1499 29.3012 93.2322 29.361 93.3315 29.4072C93.4316 29.4534 93.5665 29.5058 93.7377 29.5625C93.9749 29.6481 94.1684 29.7285 94.3166 29.8034C94.4648 29.8785 94.5916 29.9906 94.6961 30.1406C94.8006 30.2906 94.8532 30.4868 94.8532 30.7292C94.8532 30.9225 94.8058 31.0997 94.7117 31.2618Z"
          fill="currentColor"
        />
      </g>
    </svg>
  </div>
);
