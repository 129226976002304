import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  useHookFormContext,
  useLoaderContext,
  useToastContext,
  useWebsocketContext,
} from 'hooks/context';
import { ROUTES } from 'utils/const';
import { deleteReportingPeriod, getReport } from 'utils/data-access';
import { IGetReportsData, IUseFilterProps } from 'utils/interface';

import { useGenerateReport } from './use-generate-report.hook';

export const useReportActions = (data: any) => {
  const [lineChartPDFData, setLineChartPDFData] = useState<any | null>(null);
  const [ganttChartPDFData, setGanttChartPDFData] = useState<any | null>(null);
  const [mapPDFData, setMapPDFData] = useState<any | null>(null);
  const { classesData, sensorsData } = useWebsocketContext();
  const { setValue } = useFormContext<IUseFilterProps>();
  const { updateData } = useHookFormContext();
  const { successToast, errorToast } = useToastContext();
  const { toggleLoader } = useLoaderContext();
  const { generateReport } = useGenerateReport();
  const navigate = useNavigate();

  const onEdit = (edit: (data: any) => void) => {
    edit(data);
  };

  const getDataForPrepareRaport = (id: number) =>
    getReport(id)
      .then(result => {
        setLineChartPDFData(result);
        setGanttChartPDFData(result);
        setMapPDFData(result);
      })
      .catch(() => errorToast('Download report failed.'));

  const onDownload = (id: number) => {
    toggleLoader(true);
    setTimeout(() => generateReport(mapPDFData, id), 0);
  };

  const onRemove = (id: number) => {
    deleteReportingPeriod(id)
      .then(() => successToast('Reporting period deleted'))
      .catch(() => errorToast('Deleting report period failed.'));
  };

  const onView = (data: IGetReportsData) => {
    return new Promise((resolve, reject) => {
      sensorsData?.map(sensorData =>
        data.sensors.includes(sensorData.id)
          ? setValue(`selectedSensorList.${sensorData.id}`, true)
          : setValue(`selectedSensorList.${sensorData.id}`, false),
      );

      classesData?.map(classData =>
        data.classes.includes(classData.id)
          ? setValue(`selectedClassList.${classData.id}`, true)
          : setValue(`selectedClassList.${classData.id}`, false),
      );

      setValue(`liveMode`, false);
      setValue(`dateFrom`, data.start_date);
      setValue(`dateTo`, data.end_date);
      setValue(`interval`, data.interval);
      setValue(`noiseLimit`, data.noise_limit);
      setValue('alertsReported', false);
      setValue('typeOfReport', '');
      setValue('timeFrame', `${data.start_date} - ${data.end_date}`);
      setValue('calendarDate', null);

      resolve(true);
    })
      .then(() => updateData())
      .then(() => navigate(`${ROUTES.SENSORS}`));
  };

  return {
    onDownload,
    onEdit,
    onRemove,
    onView,
    lineChartPDFData,
    ganttChartPDFData,
    mapPDFData,
    getDataForPrepareRaport,
  };
};
