import { Document, Font, View } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import { theme } from 'context/theme/theme';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: '/src/assets/fonts/Roboto-Regular.ttf',
    },
    {
      src: '/src/assets/fonts/Roboto-Medium.ttf',
      fontWeight: 500,
    },
  ],
});

export const StyledPDFTemplateDocument = styled(Document)`
  font-family: 'Roboto';
`;

export const StyledPDFTemplatePageContent = styled(View)`
  flex-grow: 1;
  margin: ${() => theme.spacing(0, 1.25, 1.25)};
  padding: ${() => theme.spacing(1.25)};
  font-size: ${() => theme.typography.customFontSize.xSmall};
`;
