import { useEffect, useState } from 'react';
import { useToastContext } from 'hooks/context';
import { REPORTS_DATA_TYPE } from 'utils/const';
import { getReportingPeriods, getReports } from 'utils/data-access';
import { IGetReportingPeriods, IGetReports } from 'utils/interface';
import {
  getReportingPeriodsDataMapper,
  getReportsDataMapper,
} from 'utils/mapper';

export const useReports = (type: string) => {
  const [reports, setReports] = useState<IGetReports>();
  const { errorToast } = useToastContext();
  const [reportingPeriods, setReportingPeriods] =
    useState<IGetReportingPeriods>();

  const handleGetReports = async (offset: number, limit: number) => {
    let data;
    await getReports(offset, limit)
      .then(result => (data = result))
      .catch(() => errorToast('Loading reports failed'));
    const reportsData = data && getReportsDataMapper(data);

    setReports(reportsData);
  };

  const handleGetReportingPeriods = async (offset: number, limit: number) => {
    let data;
    await getReportingPeriods(offset, limit)
      .then(result => (data = result))
      .catch(() => errorToast('Loading reporting periods failed'));
    const reportingPeriodsData = data && getReportingPeriodsDataMapper(data);

    setReportingPeriods(reportingPeriodsData);
  };

  useEffect(() => {
    if (type === REPORTS_DATA_TYPE.reports) {
      handleGetReports(0, 10);
    }

    if (type === REPORTS_DATA_TYPE.reportingPeriods) {
      handleGetReportingPeriods(0, 10);
    }
  }, []);

  return {
    reports,
    reportingPeriods,
    handleGetReports,
    handleGetReportingPeriods,
  };
};
