import { Text } from '@react-pdf/renderer';
import { IChildrenProps } from 'utils/interface';

import {
  StyledPDFTemplateFooter,
  StyledPDFTemplateFooterContent,
} from './pdf-template-footer.styled';

export const PDFTemplateFooter = ({ children }: IChildrenProps) => (
  <StyledPDFTemplateFooter fixed>
    <StyledPDFTemplateFooterContent>
      <Text>{children}</Text>
      <Text render={({ pageNumber }) => `${pageNumber}`} />
    </StyledPDFTemplateFooterContent>
  </StyledPDFTemplateFooter>
);
