import { styled } from '@mui/material';
import { ISGanttChartRulerProps } from 'utils/interface';

export const StyledGanttChartRulers = styled('div')`
  position: absolute;
  z-index: -1;
  top: ${({ theme }) => theme.spacing(-7.5)};
  left: 0;
  right: 0;
  bottom: 0;
`;

export const StyledGanttChartRuler = styled('div')<ISGanttChartRulerProps>`
  position: absolute;
  top: 0;
  left: ${({ offset }) => offset}%;
  bottom: 0;
  width: ${({ theme }) => theme.spacing(0.125)};
  background-image: ${({ pdf }) =>
    pdf
      ? `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%235b5b5b' stroke-width='1' stroke-dasharray='3 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`
      : `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23bcbcbc' stroke-width='1' stroke-dasharray='3 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`};
`;
