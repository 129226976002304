import dangerIcon from 'assets/icons/danger.svg';
import normalIcon from 'assets/icons/normal.svg';
import warningIcon from 'assets/icons/warning.svg';
import { STATUS } from 'utils/const';

const { OVER_LIMIT, ACCEPTABLE } = STATUS;

export const setCurrentIconForSensorOnMap = (noiseStatus: string) => {
  if (noiseStatus === ACCEPTABLE) return warningIcon;
  if (noiseStatus === OVER_LIMIT) return dangerIcon;
  return normalIcon;
};
