import { styled } from '@mui/material';
import { ISLineChartWrapper } from 'utils/interface';

export const StyledLineChartWrapper = styled('div')<ISLineChartWrapper>`
  position: relative;
  z-index: 1;
  transform: translateX(${({ theme }) => theme.spacing(-2.25)});
  user-select: none;

  .recharts-wrapper {
    &::before,
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      display: ${({ pdf }) => (pdf ? 'none' : 'inline-block')};
      background: ${({ theme }) => theme.palette.custom.athensGrey};
    }

    &::before {
      width: ${({ theme }) => theme.spacing(3.5)};
      height: 90%;
    }

    &::after {
      width: ${({ theme }) => theme.spacing(7)};
      height: ${({ theme }) => theme.spacing(5)};
    }

    .recharts-cartesian-grid-bg {
      cursor: ${({ zoomed }) =>
        zoomed.some(val => val !== 0) ? 'grab' : 'default'};
    }
  }
`;
