import { Pagination as MuiPagination } from '@mui/material';
import { IPagination } from 'utils/interface';

import {
  StyledPaginationBase,
  StyledPaginationContainer,
} from './pagination.styled';
import { PaginationInput } from './pagination-input';
import { PaginationSelect } from './pagination-select';

export const Pagination = ({
  results,
  rowsPerPage,
  handleChangePage,
  paginationPage,
  page,
  handleChangePageSelect,
  handleOnKeyDown,
  handleChangeRowsPerPage,
}: IPagination) => (
  <StyledPaginationContainer>
    <p>{results} results</p>
    <MuiPagination
      count={Math.ceil(results / rowsPerPage)}
      showFirstButton
      onChange={handleChangePage}
      showLastButton
      shape="rounded"
      color="primary"
      page={paginationPage}
    />
    <StyledPaginationBase>
      <PaginationInput
        page={page}
        handleChangePageSelect={handleChangePageSelect}
        handleOnKeyDown={handleOnKeyDown}
        results={results}
        rowsPerPage={rowsPerPage}
      />
      <PaginationSelect
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
      />
    </StyledPaginationBase>
  </StyledPaginationContainer>
);
