import {
  Checkbox,
  TableBody as MuiTableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { useHookFormContext } from 'hooks';
import { getCheckedStatus } from 'utils/helpers';
import { ISensorsTableBody } from 'utils/interface';

import { StatusSensors } from 'components/status';

export const SensorsTableBody = ({
  data,
  sliceStart,
  sliceEnd,
  selectedItems,
  register,
  listName,
}: ISensorsTableBody) => {
  const { updateData } = useHookFormContext();

  return (
    <MuiTableBody>
      {data
        .slice(sliceStart, sliceEnd)
        .map(
          ({
            available,
            current_noise_level,
            disabled,
            id,
            measurements: { average_noise },
            name,
            pos_x,
            pos_y,
          }) => {
            const idString = id.toString();
            const isChecked = getCheckedStatus(selectedItems, idString);

            return (
              <TableRow key={id} selected={isChecked}>
                <TableCell>
                  <Checkbox
                    id={idString}
                    color="success"
                    {...register(`${listName}.${id}`, {
                      value: available,
                      disabled: disabled,
                      onChange: updateData,
                    })}
                    checked={isChecked}
                  />
                </TableCell>
                <TableCell>{name}</TableCell>
                <TableCell align="right">
                  {average_noise && `${average_noise} dBA`}
                </TableCell>
                <TableCell>{id}</TableCell>
                <TableCell>{`${pos_x}, ${pos_y}`}</TableCell>
                <TableCell align="right">{current_noise_level}</TableCell>
                <TableCell>
                  <StatusSensors status={available} />
                </TableCell>
              </TableRow>
            );
          },
        )}
    </MuiTableBody>
  );
};
