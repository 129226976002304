import { Text, View } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import { theme } from 'context/theme/theme';

export const StyledPDFTemplateHeading = styled(Text)`
  margin-bottom: ${() => theme.spacing(1.5)};
  font-size: ${() => theme.typography.customFontSize.small};
  font-weight: 500;
  color: ${() => theme.palette.primary.dark};
`;

export const StyledPDFTemplateHeadingNoMargin = styled(
  StyledPDFTemplateHeading,
)`
  margin-bottom: ${() => theme.spacing(1)};
`;

export const StyledPDFTemplateRow = styled(View)`
  flex-direction: row;
`;

export const StyledPDFTemplateColumn = styled(View)`
  flex-grow: 1;
`;

export const StyledPDFTemplateView = styled(View)`
  margin-top: ${() => theme.spacing(0.5)};
  margin-bottom: ${() => theme.spacing(2)};
`;

export const StyledPDFTemplateSection = styled(View)`
  margin-bottom: ${() => theme.spacing(2)};
  padding-bottom: ${() => theme.spacing(2)};
  font-size: ${() => theme.spacing(1.5)};
  border-bottom: 1px solid ${() => theme.palette.border.borderGrey};
`;

export const StyledPDFTemplateSectionLast = styled(StyledPDFTemplateSection)`
  border-bottom-width: 0;
`;

export const StyledPDFTemplateFlex = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;

  > * {
    margin-right: ${() => theme.spacing(2)};
  }
`;

export const StyledPDFTemplateLabeledData = styled(Text)`
  margin-top: ${() => theme.spacing(0.5)};
  margin-bottom: ${() => theme.spacing(0.5)};
  margin-right: ${() => theme.spacing(2)};
  font-size: ${() => theme.spacing(1.5)};
  color: ${() => theme.palette.custom.greyMain};
`;

export const StyledPDFTemplateBullet = styled(View)`
  width: ${() => theme.spacing(0.5)};
  height: ${() => theme.spacing(0.5)};
  background-color: ${() => theme.palette.text.primary};
`;

export const StyledPDFTemplateStrong = styled(Text)`
  font-weight: bold;
  color: ${() => theme.palette.text.primary};
`;
