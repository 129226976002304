import { styled } from '@mui/material';

export const StyledLineChartTooltip = styled('div')`
  padding: ${({ theme }) => theme.spacing(2)};
  border-radius: ${({ theme }) => theme.spacing(0.625)};
  background-color: ${({ theme }) => theme.palette.custom.white};
  box-shadow: ${({ theme }) => theme.palette.shadows.shadowTooltip};
  font-size: ${({ theme }) => theme.typography.customFontSize.small};

  > p {
    margin: 0;
  }
`;

export const StyledLineChartTooltipHeader = styled('div')`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  font-weight: 500;

  svg {
    margin-right: ${({ theme }) => theme.spacing(0.5)};
  }
`;

export const StyledLineChartTooltipParagraph = styled('p')`
  > span:first-of-type {
    display: block;
    font-weight: 500;
  }
`;
