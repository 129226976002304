import { IPDFTemplatePageSummaryDetailsProps } from 'utils/interface';

import { PDFTemplateBullet } from '../../pdf-template-bullet';
import {
  StyledPDFTemplateBulletList,
  StyledPDFTemplateBulletListItem,
} from '../../pdf-template-bullet/pdf-template-bullet.styled';
import {
  StyledPDFTemplateColumn,
  StyledPDFTemplateHeading,
  StyledPDFTemplateLabeledData,
  StyledPDFTemplateRow,
  StyledPDFTemplateSectionLast,
  StyledPDFTemplateStrong,
} from '../pdf-template-page.styled';

export const PDFTemplatePageSummaryDetails = ({
  sensors,
  classes,
}: IPDFTemplatePageSummaryDetailsProps) => (
  <StyledPDFTemplateSectionLast>
    <StyledPDFTemplateHeading>Details</StyledPDFTemplateHeading>

    <StyledPDFTemplateRow>
      <StyledPDFTemplateColumn>
        <StyledPDFTemplateLabeledData>
          Sensors:{' '}
          <StyledPDFTemplateStrong>{sensors.length}</StyledPDFTemplateStrong>
        </StyledPDFTemplateLabeledData>

        <StyledPDFTemplateBulletList>
          {sensors.map(({ id, name }) => (
            <StyledPDFTemplateBulletListItem key={id}>
              <PDFTemplateBullet />
              <StyledPDFTemplateStrong>{name}</StyledPDFTemplateStrong>{' '}
              {`(ID: ${id})`}
            </StyledPDFTemplateBulletListItem>
          ))}
        </StyledPDFTemplateBulletList>
      </StyledPDFTemplateColumn>

      <StyledPDFTemplateColumn>
        <StyledPDFTemplateLabeledData>
          Classes:{' '}
          <StyledPDFTemplateStrong>{classes.length}</StyledPDFTemplateStrong>
        </StyledPDFTemplateLabeledData>

        <StyledPDFTemplateBulletList>
          {classes.map(({ id, name }) => (
            <StyledPDFTemplateBulletListItem key={id}>
              <PDFTemplateBullet />
              {name}
            </StyledPDFTemplateBulletListItem>
          ))}
        </StyledPDFTemplateBulletList>
      </StyledPDFTemplateColumn>
    </StyledPDFTemplateRow>
  </StyledPDFTemplateSectionLast>
);
