import { Button } from '@mui/material';
import { useHookFormContext } from 'hooks';

import { StyledFiltersAndReportButtonsContainer } from '../filters-and-report.styled';

export const FiltersAndReportButtons = () => {
  const { isFilter, submitRecipe, handleCloseModal, methods } =
    useHookFormContext();
  const name = isFilter ? 'Apply' : 'Generate report';
  return (
    <StyledFiltersAndReportButtonsContainer>
      <Button
        variant="contained"
        fullWidth
        onClick={() => methods.handleSubmit(submitRecipe)()}
      >
        {name}
      </Button>
      <Button fullWidth onClick={handleCloseModal}>
        Cancel
      </Button>
    </StyledFiltersAndReportButtonsContainer>
  );
};
