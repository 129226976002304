import { IClass } from 'utils/interface';

export const getSelectedClasses = (classes: IClass[]) => {
  const selectedClasses = classes
    .filter((data: IClass) => data.measurements !== undefined)
    .sort((current: IClass, next: IClass) =>
      current.measurements.part_percent < next.measurements.part_percent
        ? 1
        : -1,
    );

  return selectedClasses;
};
