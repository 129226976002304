import { fabric } from 'fabric';
import { MAP } from 'utils/const';
import { ISensor } from 'utils/interface';

import { setActiveSensorOnMap } from './set-active-sensor-on-map';
import { setCircleOnMap } from './set-circle-on-map';
import { setCurrentColorForActiveSensorOnMap } from './set-current-color-for-active-sensor-on-map';
import { setCurrentIconForSensorOnMap } from './set-current-icon-for-sensor-on-map';
import { setInactiveSensorOnMap } from './set-inactive-sensor-on-map';
import { setLeftDirectionOnMap } from './set-left-direction-on-map';
import { setTopDirectionOnMap } from './set-top-direction-on-map';

const { SENSOR_LABEL_LEFT, SENSOR_LABEL_TOP } = MAP;

export const setItemsOnMap = (
  sensor: ISensor,
  status: (value: number) => string,
  editorMemo?: fabric.Canvas,
) => {
  const { pos_x, pos_y, measurements, name, available, id } = sensor;

  if (!available || !editorMemo) return false;

  const left = setLeftDirectionOnMap(editorMemo.getWidth(), pos_x);
  const top = setTopDirectionOnMap(editorMemo.getHeight(), pos_y);

  const average_noise = `${Math.round(
    Number(measurements?.average_noise ?? 0),
  )} dBA`;
  fabric.Image.fromURL(
    setCurrentIconForSensorOnMap(
      status(Math.round(Number(measurements?.average_noise ?? 0))),
    ),
    function (oImg) {
      oImg.set({
        left: left + SENSOR_LABEL_LEFT,
        top: top + SENSOR_LABEL_TOP,
        selectable: false,
      });
      if (available && measurements?.average_noise) {
        editorMemo?.add(
          setActiveSensorOnMap(
            oImg,
            left,
            top,
            setCurrentColorForActiveSensorOnMap(
              status(Math.round(Number(measurements?.average_noise ?? 0))),
            ),
            name,
            average_noise,
            id.toString(),
          ),
        );
      } else {
        editorMemo?.add(setInactiveSensorOnMap(left, top, name));
      }
    },
  );

  editorMemo?.add(setCircleOnMap(left, top, id.toString()));

  editorMemo?.renderAll();

  return true;
};
