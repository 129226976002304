import { styled } from '@mui/material';

export const StyledTitleContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
`;

export const StyledTitle = styled('h2')`
  font-size: ${({ theme }) => theme.typography.customFontSize.large};
  font-weight: 500;
  line-height: ${({ theme }) => theme.spacing(3.25)};
  margin: 0;
`;

export const StyledTitleSubTitle = styled('span')`
  color: ${({ theme }) => theme.palette.text.secondary};
`;
