import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StaticDateRangePicker from '@mui/lab/StaticDateRangePicker';
import { TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { ICalendarDateRange } from 'utils/interface';

import { StyledCalendarTimePicker } from '../calendar.styled';

export const CalendarDateRange = ({
  dateRange,
  handleDateRangePicker,
}: ICalendarDateRange) => (
  <StyledCalendarTimePicker>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StaticDateRangePicker
        displayStaticWrapperAs="desktop"
        defaultCalendarMonth={dayjs().subtract(1, 'month')}
        value={dateRange}
        disableFuture
        onChange={handleDateRangePicker}
        renderInput={(startProps, endProps) => (
          <>
            <TextField {...endProps} />
            <TextField {...startProps} />
          </>
        )}
      />
    </LocalizationProvider>
  </StyledCalendarTimePicker>
);
