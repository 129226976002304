import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { fabric } from 'fabric';
import { FabricJSEditor } from 'fabricjs-react';
import { useNoiseViolationLimits } from 'hooks/common';
import { MAP } from 'utils/const';
import {
  setAllArrowsOnMap,
  setItemsOnMap,
  setMap,
  turnOffSensor,
} from 'utils/helpers';
import { ISensor, ISensorMap } from 'utils/interface';

fabric.Object.prototype.selectable = false;

interface IDataMap {
  noiseLimit: number;
  sensorsData: ISensor[] | null;
  isDataUpdated: boolean;
  handleSetIsDataUpdated: () => void;
}

const { SCALE } = MAP;

export const useMap = (
  data: IDataMap,
  pdf: boolean,
  editor?: FabricJSEditor,
  size?: number[],
) => {
  const { noiseLimit, isDataUpdated, handleSetIsDataUpdated, sensorsData } =
    data;
  const { status } = useNoiseViolationLimits(pdf ? noiseLimit : undefined);
  const editorMemo = useMemo(() => editor?.canvas, [editor?.canvas]);
  const [mouseLastXPosition, setMouseLastXPosition] = useState<number>(0);
  const [mouseLastYPosition, setMouseLastYPosition] = useState<number>(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isDrawn, setIsDrawn] = useState(false);
  const [isMapReady, setIsMapReady] = useState(false);
  const [newNoiseLimit, setNewNoiseLimit] = useState(noiseLimit);
  const [sensor, setSensor] = useState<ISensorMap | null>(null);
  const [arrows, setArrows] = useState<ISensor[] | null>([]);
  const [sensors, setSensors] = useState<ISensor[] | null>([]);
  const [filterArrFirstLast, setFilterArrFirstLast] = useState<
    ISensor[] | null
  >([]);
  const [filterArrNullOld, setFilterArrNullOld] = useState<ISensor[] | null>(
    [],
  );
  const [isCheckboxClicked, setIsCheckboxClicked] = useState(false);

  const handleSetSensor = (value: ISensorMap | null) => setSensor(value);
  const handleSetMapIsReady = () => setIsMapReady(true);

  const listener = useCallback(
    (e?: MouseEvent) => {
      if (e?.target !== document.querySelector('canvas.upper-canvas')) {
        turnOffSensor(handleSetSensor, editorMemo);
      }
    },
    [editorMemo],
  );

  const handleWheel = useCallback(() => {
    if (sensor !== null) {
      turnOffSensor(handleSetSensor, editorMemo);
      setSensor(null);
    }
  }, [editorMemo, sensor]);

  const onClickDocument = useCallback((e: MouseEvent) => {
    setSensor(prevState => {
      return { ...prevState, x: e.clientX, y: e.clientY };
    });
  }, []);

  const setOpacityForElementsOnMap = (targetElement: string) => {
    const objects = editorMemo?.getObjects();
    if (targetElement.includes('arrow')) return;

    if (objects) {
      objects.map(elem => elem.set({ opacity: 0.2, evented: false }));

      const getClickedElemOnMap = objects.filter(elem =>
        elem.name?.includes(targetElement),
      );

      getClickedElemOnMap?.map(elem =>
        elem.set({ opacity: 1, evented: true, hoverCursor: 'default' }),
      );
    }
  };

  editorMemo?.off();

  editorMemo?.on('mouse:down', opt => {
    setMouseLastXPosition(opt.e.clientX);
    setMouseLastYPosition(opt.e.clientY);
    setIsDragging(true);
  });

  editorMemo?.on('mouse:move', opt => {
    const e = opt.e;
    if (isDragging) {
      const vpt = editorMemo?.viewportTransform;
      if (vpt && mouseLastXPosition && mouseLastYPosition) {
        vpt[4] += e.clientX - mouseLastXPosition;
        vpt[5] += e.clientY - mouseLastYPosition;
        editorMemo?.renderAll();
        setMouseLastXPosition(e.clientX);
        setMouseLastYPosition(e.clientY);
      }
    }
  });

  editorMemo?.on('mouse:up', () => {
    if (editorMemo?.viewportTransform) {
      editorMemo?.setViewportTransform(editorMemo?.viewportTransform);
    }
    setIsDragging(false);
  });

  editorMemo?.on('mouse:up', opt => {
    if (opt.target?.name) {
      setOpacityForElementsOnMap(opt.target?.name);

      const newSensor = sensorsData?.find(
        elem => elem.id === Number(opt.target?.name),
      );

      setSensor(prevState => {
        return { ...prevState, ...newSensor };
      });
    } else {
      editorMemo?.getObjects().map(elem => elem.set({ evented: true }));
    }
  });

  editorMemo?.on('mouse:down', opt => {
    if (opt.target?.opacity !== 1) {
      turnOffSensor(handleSetSensor, editorMemo);
    }
  });

  useLayoutEffect(() => {
    if (pdf && editorMemo) {
      editorMemo.zoomToPoint(
        new fabric.Point(
          editorMemo.getCenter().left,
          editorMemo.getCenter().top,
        ),
        SCALE,
      );
    }
  });

  useEffect(() => {
    if (
      (sensorsData?.length !== 0 &&
        isMapReady &&
        sensors &&
        size &&
        editorMemo &&
        sensorsData &&
        !sensor?.name) ||
      (isDataUpdated &&
        isMapReady &&
        editorMemo &&
        sensors &&
        sensorsData &&
        !sensor?.name)
    ) {
      const filterArr = sensorsData.filter(sensor => sensor.available);
      const filterArrFirst = sensorsData.filter(
        sensor => sensor.available && sensor.measurements,
      );
      const filterArrNull = filterArr.filter(
        elem => elem.measurements?.average_noise === null,
      );

      if (filterArrNull.length !== filterArrNullOld?.length) {
        for (const elem of editorMemo.getObjects()) {
          if (!elem.name?.includes('_arrow')) {
            editorMemo.remove(elem);
          }
        }
      }

      if (filterArrFirst.length !== filterArrFirstLast?.length) {
        for (const elem of editorMemo.getObjects()) {
          if (!elem.name?.includes('_arrow')) {
            editorMemo.remove(elem);
          }
        }
      }

      if (
        (filterArrFirst.length === 0 && sensors.length === 0) ||
        noiseLimit !== newNoiseLimit
      ) {
        for (const elem of editorMemo.getObjects()) {
          editorMemo.remove(elem);
        }

        for (const sensor of filterArr) {
          setItemsOnMap(sensor, status, editorMemo);
        }
        sensors.length > 0 && setSensors([]);
        return;
      }

      filterArr.forEach((elem, index) => {
        if (elem.measurements && elem.measurements.average_noise !== null) {
          const noise_direction = elem.measurements.noise_direction;

          filterArr[index] = {
            ...elem,
            measurements: {
              noise_direction,
              average_noise: +elem.measurements.average_noise.toFixed(0),
            },
          };
        }
      });

      if (sensors.length === 0) {
        filterArr.map(sensor => {
          setItemsOnMap(sensor, status, editorMemo);
          pdf && setAllArrowsOnMap(sensor, editorMemo);
        });
        setSensors(filterArr);
        return;
      }

      for (const elem of editorMemo.getObjects()) {
        filterArr.map(sensor => {
          if (!sensor.measurements && elem.name?.includes(`${sensor.id}`)) {
            editorMemo.remove(elem);
          }
        });
      }

      for (const sensor of filterArr) {
        if (isDrawn) {
          setItemsOnMap(sensor, status, editorMemo);
        }
      }

      const newSensorsToDraw =
        filterArr.length > sensors.length
          ? filterArr
          : filterArr.filter(elem => {
              const oldElem = sensors.find(arrow => arrow.id === elem.id);

              if (
                oldElem &&
                elem.measurements &&
                oldElem.measurements &&
                oldElem.measurements.average_noise !==
                  elem.measurements.average_noise
              ) {
                return elem;
              }
            });

      if (newSensorsToDraw.length > 0) {
        setSensors(filterArr);

        for (const elem of editorMemo.getObjects()) {
          newSensorsToDraw.map(({ id }) => {
            if (
              elem.name?.includes(`${id}`) &&
              !elem.name?.includes('_arrow')
            ) {
              editorMemo.remove(elem);
            }
          });
        }

        for (const sensor of newSensorsToDraw) {
          setItemsOnMap(sensor, status, editorMemo);
          pdf && setAllArrowsOnMap(sensor, editorMemo);
        }
      }

      if (isDataUpdated) handleSetIsDataUpdated();
      if (
        filterArrFirstLast &&
        filterArrFirst.length > filterArrFirstLast.length
      ) {
        setIsDrawn(true);
      } else {
        setIsDrawn(false);
      }
      if (
        filterArrFirstLast &&
        filterArrFirst.length !== filterArrFirstLast.length
      ) {
        setFilterArrFirstLast(filterArrFirst);
      }

      if (isCheckboxClicked) {
        setIsCheckboxClicked(false);
        for (const sensor of filterArr) {
          setItemsOnMap(sensor, status, editorMemo);
        }
      }

      if (filterArrNull?.length !== filterArrNullOld?.length) {
        setFilterArrNullOld(filterArrNull);
        setIsCheckboxClicked(true);
      }

      if (filterArrNull && filterArrNull.length !== filterArrNullOld?.length) {
        setFilterArrNullOld(filterArrNull);
      }

      if (
        filterArrFirstLast &&
        filterArrFirst.length !== filterArrFirstLast.length
      ) {
        setIsCheckboxClicked(true);
      }
    }
  }, [
    editorMemo,
    filterArrFirstLast,
    handleSetIsDataUpdated,
    isDataUpdated,
    isDrawn,
    isMapReady,
    pdf,
    sensors,
    sensorsData,
    status,
    setFilterArrFirstLast,
    size,
    noiseLimit,
    newNoiseLimit,
    sensor?.name,
    isCheckboxClicked,
    filterArrNullOld?.length,
  ]);

  useLayoutEffect(() => {
    if (
      (!pdf &&
        arrows &&
        editorMemo &&
        size &&
        sensorsData &&
        sensorsData.length > 0 &&
        isMapReady &&
        !sensor?.name) ||
      (isDataUpdated && isMapReady && editorMemo && arrows && sensorsData)
    ) {
      const filterArr = sensorsData
        .filter(sensor => sensor.measurements)
        .filter(sensor => sensor.measurements.noise_direction);
      const filterArr2 = sensorsData.filter(sensor => !sensor.measurements);

      for (const elem of editorMemo.getObjects()) {
        filterArr2.map(({ id }) => {
          if (elem.name?.includes(`${id}_arrow`)) {
            editorMemo.remove(elem);
          }
        });
      }

      if (filterArr.length === 0 || noiseLimit !== newNoiseLimit) {
        for (const elem of editorMemo.getObjects()) {
          filterArr2.map(() => {
            if (elem.name?.includes(`_arrow`)) {
              editorMemo.remove(elem);
            }
          });
        }
        arrows.length > 0 && setArrows([]);
        return;
      }

      if (filterArr[0].measurements.average_noise === null) {
        for (const elem of editorMemo.getObjects()) {
          filterArr2.map(() => {
            if (elem.name?.includes(`_arrow`)) {
              editorMemo.remove(elem);
            }
          });
        }
        arrows.length > 0 && setArrows([]);
        return;
      }

      filterArr.forEach((elem, index) => {
        const average_noise = elem.measurements.average_noise;

        filterArr[index] = {
          ...elem,
          measurements: {
            average_noise,
            noise_direction: Math.ceil(elem.measurements.noise_direction),
          },
        };
      });

      if (arrows.length === 0) {
        filterArr.map(sensor => setAllArrowsOnMap(sensor, editorMemo));
        setArrows(filterArr);
        return;
      }

      const newArrowsToDraw =
        filterArr.length > arrows.length
          ? filterArr
          : filterArr.filter(elem => {
              const oldElem = arrows.find(arrow => arrow.id === elem.id);
              if (
                oldElem &&
                oldElem.measurements.noise_direction !==
                  elem.measurements.noise_direction
              ) {
                return elem;
              }
            });

      if (filterArr.length !== arrows.length) {
        setArrows(filterArr);
        for (const elem of editorMemo.getObjects()) {
          arrows.map(({ id }) => {
            if (elem.name?.includes(`${id}_arrow`)) {
              editorMemo.remove(elem);
            }
          });
        }
        filterArr.map(sensor => setAllArrowsOnMap(sensor, editorMemo));
      }

      if (newArrowsToDraw.length > 0) {
        setArrows(filterArr);
        for (const elem of editorMemo.getObjects()) {
          newArrowsToDraw.map(({ id }) => {
            if (elem.name?.includes(`${id}_arrow`)) {
              editorMemo.remove(elem);
            }
          });
        }
        newArrowsToDraw.map(sensor => setAllArrowsOnMap(sensor, editorMemo));
      }
    }
  }, [
    arrows,
    editorMemo,
    isMapReady,
    pdf,
    sensor,
    sensorsData,
    size,
    status,
    newNoiseLimit,
    noiseLimit,
    isDataUpdated,
  ]);

  useEffect(() => {
    if (!pdf && noiseLimit !== newNoiseLimit) {
      setNewNoiseLimit(noiseLimit);
    }
  }, [newNoiseLimit, noiseLimit, pdf]);

  useEffect(() => {
    if (!pdf) {
      window.document.addEventListener('click', onClickDocument);
      window.document.addEventListener('mousewheel', handleWheel);

      if (sensor) {
        window.document.addEventListener('mousedown', listener);
      }

      return () => {
        window.document.removeEventListener('click', onClickDocument);
        window.document.removeEventListener('mousedown', listener);
        window.document.removeEventListener('mousewheel', handleWheel);
      };
    }
  }, [editorMemo, handleWheel, listener, onClickDocument, pdf, sensor]);

  useLayoutEffect(() => {
    size && editorMemo && setMap(handleSetMapIsReady, editorMemo, size, pdf);
  }, [editor, editorMemo, pdf, size]);

  return {
    sensor,
    turnOffSensor: listener,
  };
};
