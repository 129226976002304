import { styled } from '@mui/material';
import { IconButton } from '@mui/material';
import { FabricJSCanvas } from 'fabricjs-react';
import { ISensorMap } from 'utils/interface';

export const StyledFabricJSCanvas = styled(FabricJSCanvas)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0 auto;
`;

export const StyledMapDescriptionContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin: ${({ theme }) => theme.spacing(2.625, 0)};
    font-weight: bold;
  }
`;

export const StyledFlexContainer = styled('div')`
  display: flex;
  align-items: center;

  span {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

export const BaseSquare = styled('div')`
  height: ${({ theme }) => theme.spacing(2.5)};
  width: ${({ theme }) => theme.spacing(2.5)};
  border-radius: ${({ theme }) => theme.spacing(0.625)};
  margin: ${({ theme }) => theme.spacing(0, 1, 0, 2)};
`;

export const StyledMapDescriptionNormalSquare = styled(BaseSquare)`
  background: ${({ theme }) => theme.palette.success.main};
`;

export const StyledMapDescriptionWarningSquare = styled(BaseSquare)`
  background: ${({ theme }) => theme.palette.warning.main};
`;

export const StyledMapDescriptionDangerSquare = styled(BaseSquare)`
  background: ${({ theme }) => theme.palette.error.main};
`;

export const StyledMapIconButtonBase = styled(IconButton)`
  position: absolute;
  right: ${({ theme }) => theme.spacing(6)};
  z-index: 1;
  background: ${({ theme }) => theme.palette.custom.athensGrey};
  border: ${({ theme }) =>
    `${theme.spacing(0.125)} solid ${theme.palette.custom.grey86}`};
  box-shadow: ${({ theme }) => theme.palette.shadows.shadowButton};
  border-radius: ${({ theme }) => theme.spacing(0.625)};
`;

export const StyledMapIconButtonPlus = styled(StyledMapIconButtonBase)`
  bottom: ${({ theme }) => theme.spacing(10)};
`;

export const StyledMapIconButtonMinus = styled(StyledMapIconButtonBase)`
  bottom: ${({ theme }) => theme.spacing(4)};
`;

export const StyledMapCanvasContainer = styled('div')`
  position: relative;
`;

export const StyledMapCanvasBackground = styled('img')`
  max-width: 100%;
  height: auto;
  opacity: 0;
`;

export const StyledMapPopupContainer = styled('div')<ISensorMap>`
  position: fixed;
  left: ${({ x }) => `${x ? x : 0 + 30}px`};
  top: ${({ y }) => `${y ?? 0 + 30}px`};
  background: ${({ theme }) => theme.palette.custom.athensGrey};
  z-index: 10;
  box-shadow: ${({ theme }) => theme.palette.shadows.shadowMapPopup};
  border-radius: ${({ theme }) => theme.spacing(0.625)};
  padding: ${({ theme }) => theme.spacing(2)};
  display: flex;
  flex-direction: column;
`;
