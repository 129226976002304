import {
  Checkbox,
  TableBody as MuiTableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { getCheckedStatus } from 'utils/helpers';
import { IClassTableBody } from 'utils/interface';

import { StatusClass } from 'components/status';

export const ClassTableBody = ({
  data,
  sliceStart,
  sliceEnd,
  selectedItems,
  register,
  listName,
  noiseLimit,
}: IClassTableBody) => (
  <MuiTableBody>
    {data
      .slice(sliceStart, sliceEnd)
      .map(
        ({
          id,
          measurements: {
            average_noise,
            occurrence_time,
            part_dba,
            part_percent,
          },
          name,
        }) => {
          const idString = id.toString();
          const isChecked = selectedItems
            ? getCheckedStatus(selectedItems, idString)
            : false;

          return (
            <TableRow key={id}>
              {listName && (
                <TableCell>
                  <Checkbox
                    id={idString}
                    color="success"
                    {...register(`${listName}.${id}`)}
                    checked={isChecked}
                  />
                </TableCell>
              )}
              <TableCell>{name}</TableCell>
              <TableCell align="right">
                {average_noise && `${average_noise} dBA`}
              </TableCell>
              <TableCell align="right">
                {occurrence_time && occurrence_time}
              </TableCell>
              <TableCell align="right">{part_dba && part_dba}</TableCell>
              <TableCell align="right">
                {part_percent && part_percent}
              </TableCell>
              <TableCell>
                {!!noiseLimit && part_dba && (
                  <StatusClass noiseLevel={part_dba} />
                )}
              </TableCell>
            </TableRow>
          );
        },
      )}
  </MuiTableBody>
);
