import { useNoiseViolationLimits } from 'hooks';
import { STATUS } from 'utils/const';
import { IClassTableStatus } from 'utils/interface';

import { ErrorLabel, SuccessLabel, WarningLabel } from '../status-label.styled';

const { NORMAL, OVER_LIMIT, ACCEPTABLE } = STATUS;

export const StatusClass = ({ noiseLevel }: IClassTableStatus) => {
  const { status } = useNoiseViolationLimits();
  if (status(noiseLevel) === ACCEPTABLE) {
    return <WarningLabel>{ACCEPTABLE}</WarningLabel>;
  }

  if (status(noiseLevel) === OVER_LIMIT) {
    return <ErrorLabel>{OVER_LIMIT}</ErrorLabel>;
  }

  return <SuccessLabel>{NORMAL}</SuccessLabel>;
};
