import dayjs from 'dayjs';
import { INoiseLevel } from 'utils/interface';

export const getLineChartDataMapper = (data?: INoiseLevel[]) => {
  if (!data) return;

  return data.map((item: INoiseLevel) => ({
    ts: +dayjs(item.time),
    value: item.noise ? Math.round(item.noise) : item.noise,
  }));
};
