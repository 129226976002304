import { Controller, useFormContext } from 'react-hook-form';
import { useHookFormContext } from 'hooks';
import { convertTime } from 'utils/helpers';

import { SubTitle } from 'components/subtitle';

import {
  StyledFiltersAndReportDetailsInputContainer,
  StyledFiltersAndReportTextField,
} from '../filters-and-report.styled';

export const FiltersAndReportDetails = () => {
  const { control, watch } = useFormContext();
  const { methods } = useHookFormContext();

  return (
    <StyledFiltersAndReportDetailsInputContainer>
      <SubTitle text="Details:" />
      <StyledFiltersAndReportTextField
        value={`${convertTime(watch('interval'))}`}
        disabled
        label="Interval"
      />
      <Controller
        control={control}
        name="noiseLimit"
        render={({ field: { onChange, value } }) => (
          <StyledFiltersAndReportTextField
            value={value === 0 ? '' : value}
            label="Noise limit (dBA)"
            onChange={onChange}
            error={!!methods.formState.errors['noiseLimit']}
            helperText={
              !!methods.formState.errors['noiseLimit']
                ? 'Please insert a number between 1 and 119 '
                : null
            }
          />
        )}
      />
    </StyledFiltersAndReportDetailsInputContainer>
  );
};
