import * as yup from 'yup';

export const loginSchema = yup
  .object({
    name: yup
      .string()
      .required('User is required')
      .test(
        'testName',
        'Incorrect user',
        val => val === process.env.REACT_APP_USERNAME,
      ),
    pass: yup
      .string()
      .required('Password is required')
      .test(
        'testPass',
        'Incorrect password',
        val => val === process.env.REACT_APP_PASSWORD,
      ),
  })
  .required();
