import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHookFormContext } from 'hooks';

import { LabelData } from 'components/label-data';
import { Title } from 'components/title';

import { StyledFiltersAndReportTitleContainer } from '../filters-and-report.styled';

export const FiltersAndReportTitle = () => {
  const { isFilter } = useHookFormContext();
  const { watch } = useFormContext();
  const { t } = useTranslation('filtersAndReport');
  const title = isFilter ? t('filters') : t('report');
  const subTitle = !isFilter ? t('select_options') : undefined;

  return (
    <>
      <Title title={title} subTitle={subTitle} />
      {!isFilter ? (
        <>
          <StyledFiltersAndReportTitleContainer>
            <LabelData
              label={t('select_sensors')}
              data={watch('selectedSensorList').length}
            />
            <LabelData
              label={t('select_classes')}
              data={watch('selectedClassList').length}
            />
          </StyledFiltersAndReportTitleContainer>
        </>
      ) : null}
    </>
  );
};
