import { useTranslation } from 'react-i18next';
import { FiLogOut } from 'react-icons/fi';
import { AppBar, Toolbar } from '@mui/material';
import { useHookFormContext } from 'hooks';
import { Logo } from 'utils/icons';
import { ILocalStorage } from 'utils/interface';

import { IconButtonLogout } from './header.styled';
import { Navigation } from './navigation';

export const Header = ({ setToken }: ILocalStorage) => {
  const { resetToDefault } = useHookFormContext();
  const { t } = useTranslation('header');

  return (
    <header>
      <AppBar position="static">
        <Toolbar>
          <Logo />
          <Navigation />
          <IconButtonLogout
            aria-label="menu"
            onClick={() => {
              setToken?.(false);
              resetToDefault();
            }}
          >
            <FiLogOut />
            {t('logout')}
          </IconButtonLogout>
        </Toolbar>
      </AppBar>
    </header>
  );
};
