import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useLayout, useVisibilityControl } from 'hooks';
import { useLocalStorage } from 'hooks';
import { PageNotFound } from 'pages/404';
import { Login } from 'pages/login';
import { MapView } from 'pages/map';
import { ReportsView } from 'pages/reports';
import { SensorsView } from 'pages/sensors';
import { ROUTES } from 'utils/const';

import { CalendarModal } from 'components/calendar';
import { FiltersAndReportModal } from 'components/filters-and-report';
import { Header } from 'components/header';
import { Limitbar } from 'components/limitbar';
import { Main } from 'components/main';
import { MainContent } from 'components/main/main-content';
import { ProtectedRoute } from 'components/protected-route/protected-route';
import { Sidebar } from 'components/sidebar';
import { ClassTable } from 'components/table';
import { Tiles } from 'components/tiles';
import { Toast } from 'components/toast';

const { LOGIN, SENSORS, MAP, REPORTS } = ROUTES;

function App() {
  const { visibility, handleClick } = useVisibilityControl();
  const { layoutBasic, layoutGrid, layoutSingle } = useLayout();
  const [token, setToken] = useLocalStorage('token');
  const { pathname } = useLocation();

  useEffect(() => {
    if (layoutSingle && !visibility) {
      handleClick();
    }
  }, [pathname]);

  return (
    <div className="App">
      {!layoutBasic ? (
        <>
          <Header setToken={setToken} />
          {layoutGrid ? <Limitbar /> : null}
        </>
      ) : null}

      <Main view={layoutBasic ? 'basic' : 'advanced'}>
        {layoutGrid ? (
          <Sidebar visibility={visibility} handleClick={handleClick} />
        ) : null}

        <MainContent>
          {layoutGrid ? <Tiles /> : null}

          <Routes>
            <Route
              path={LOGIN}
              element={<Login setToken={setToken} token={token} />}
            />
            <Route
              path={SENSORS}
              element={
                <ProtectedRoute token={token}>
                  <SensorsView visibility={visibility} />
                </ProtectedRoute>
              }
            />
            <Route
              path={MAP}
              element={
                <ProtectedRoute token={token}>
                  <MapView visibility={visibility} />
                </ProtectedRoute>
              }
            />
            <Route
              path={REPORTS}
              element={
                <ProtectedRoute token={token}>
                  <ReportsView />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
          {layoutGrid ? <ClassTable visibility={visibility} /> : null}
        </MainContent>
      </Main>

      {!layoutBasic ? (
        <>
          <FiltersAndReportModal />
          <CalendarModal />
        </>
      ) : null}
      <Toast />
    </div>
  );
}

export default App;
