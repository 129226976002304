import { FiltersAndReportButtons } from './filters-and-report-buttons';
import { FiltersAndReportDate } from './filters-and-report-date';
import { FiltersAndReportDetails } from './filters-and-report-details';
import { FiltersAndReportTitle } from './filters-and-report-title';

export const FiltersAndReport = () => (
  <>
    <FiltersAndReportTitle />
    <FiltersAndReportDate />
    <FiltersAndReportDetails />
    <FiltersAndReportButtons />
  </>
);
