import { ITabPanelProps } from 'utils/interface';

export const TabPanel = ({
  children,
  value,
  index,
  ...other
}: ITabPanelProps) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    {...other}
  >
    {value === index && <>{children}</>}
  </div>
);
