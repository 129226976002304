import { styled } from '@mui/material';

export const StyledErrorPageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 65vh;
  color: ${({ theme }) => theme.palette.custom.greyMain};

  > p {
    margin-top: ${({ theme }) => theme.spacing(1)};
    margin-bottom: ${({ theme }) => theme.spacing(8)};
    font-weight: 500;
  }
`;

export const StyledErrorPageHeader = styled('h2')`
  margin-bottom: ${({ theme }) => theme.spacing(0)};
  font-weight: 600;
  font-size: ${({ theme }) => theme.typography.customFontSize.xxxLarge};
`;
