import { styled, TextField } from '@mui/material';

export const StyledFiltersAndReportTitleModalContainer = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: ${({ theme }) => theme.spacing(3)};
`;

export const StyledFiltersAndReportTitleContainer = styled('div')`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

export const StyledFiltersAndReportTextField = styled(TextField)`
  margin-top: ${({ theme }) => theme.spacing(2)};
  width: ${({ theme }) => theme.spacing(60.75)};

  input {
    padding: ${({ theme }) => theme.spacing(1, 2)};
  }

  label {
    color: ${({ theme }) => theme.palette.custom.disabledText};
  }

  .Mui-error {
    fieldset {
      border-color: ${({ theme }) => theme.palette.error.main} !important;
    }
  }
`;

export const StyledFiltersAndReportDateInputContainer = styled('div')`
  margin: ${({ theme }) => theme.spacing(2, 0, 3, 0)};
`;

export const StyledFiltersAndReportDetailsInputContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing(3)};

  .MuiInputLabel-shrink {
    top: 0;
  }

  label {
    top: ${({ theme }) => theme.spacing(-1)};
  }
`;

export const StyledFiltersAndReportButtonsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
`;
