import { useLocation } from 'react-router-dom';
import { ROUTES } from 'utils/const';

const { LOGIN, SENSORS, MAP, REPORTS } = ROUTES;

export const useLayout = () => {
  const { pathname } = useLocation();

  const layoutBasic = pathname === LOGIN;
  const layoutGrid = pathname === SENSORS || pathname === MAP;
  const layoutSingle = pathname === REPORTS;

  return { layoutBasic, layoutGrid, layoutSingle };
};
