import { useHookFormContext, useWebsocketContext } from 'hooks';
import { getTruthyItems } from 'utils/helpers';
import { convertTime } from 'utils/helpers';

import { Tile } from './tile/tile';
import { TileAverageNoise } from './tile-average-noise/tile-average-noise';
import { StyledTilesContainer } from './tiles.style';

export const Tiles = () => {
  const {
    data: { selectedSensorList, selectedClassList },
  } = useHookFormContext();
  const { globalData } = useWebsocketContext();

  const { result: sensors } = getTruthyItems(selectedSensorList);
  const { result: classes } = getTruthyItems(selectedClassList);
  const averageNoiseValue = `${Math.round(
    Number(globalData?.overall_average_noise ?? 0),
  )} dBA`;
  const totalTimeValue = `${
    globalData && convertTime(globalData.total_occurrence_time)
  }`;

  return (
    <StyledTilesContainer>
      <Tile value={sensors.length} text={'Selector Sensors'} />
      <Tile value={classes.length} text={'Occurring Classes'} />
      <TileAverageNoise
        noiseLevel={Math.round(Number(globalData?.overall_average_noise ?? 0))}
        value={averageNoiseValue}
        text={'Average Noise'}
      />
      <Tile value={totalTimeValue} text={'Occurrence Time'} />
    </StyledTilesContainer>
  );
};
