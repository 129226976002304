import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { ROUTES } from 'utils/const';

import { StyledErrorPageHeader, StyledErrorPageWrapper } from './404.styled';

export const PageNotFound = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`${ROUTES.LOGIN}`);
  };

  return (
    <StyledErrorPageWrapper>
      <StyledErrorPageHeader>ERROR 404</StyledErrorPageHeader>
      <p>PAGE NOT FOUND</p>
      <Button variant="outlined" onClick={handleClick}>
        Return Homepage
      </Button>
    </StyledErrorPageWrapper>
  );
};
