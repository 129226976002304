import logo from 'assets/img/kfb-logo-pdf.png';

import {
  StyledPDFTemplateHeader,
  StyledPDFTemplateLogo,
} from './pdf-template-header.styled';

export const PDFTemplateHeader = () => (
  <StyledPDFTemplateHeader>
    <StyledPDFTemplateLogo src={logo} />
  </StyledPDFTemplateHeader>
);
