import ReactDOM from 'react-dom';
import { IPDFChartGeneratorProps } from 'utils/interface';
import { getPDFGanttChartMapper } from 'utils/mapper/get-pdf-chart.mapper';

import { GanttChart } from 'components/chart';

import { StyledPDFChartGeneratorWrapper } from './pdf-chart-generator.styled';

export const PDFGanttChartGenerator = ({ data }: IPDFChartGeneratorProps) => {
  const { sensors, noise_limit, start_date, end_date } =
    getPDFGanttChartMapper(data);
  return (
    <>
      {ReactDOM.createPortal(
        <StyledPDFChartGeneratorWrapper id="pdf-gantt-chart-generator">
          {sensors &&
            sensors.map(({ classes, id }) => (
              <div key={id}>
                <GanttChart
                  data={classes}
                  width="875"
                  pdf={true}
                  noiseLimit={noise_limit}
                  dates={{ dateFrom: start_date, dateTo: end_date }}
                />
              </div>
            ))}
        </StyledPDFChartGeneratorWrapper>,
        document.querySelector('#root') as HTMLElement,
      )}
    </>
  );
};
