import { useTranslation } from 'react-i18next';
import { MenuItem } from '@mui/material';
import { navigationRoutes } from 'utils/routes';

import { NavigationContainer, NavigationLink } from './navigation.styled';

export const Navigation = () => {
  const { t } = useTranslation('header');
  return (
    <NavigationContainer>
      {navigationRoutes(t).map(item => (
        <MenuItem key={item.id}>
          <NavigationLink to={item.link}>{item.name}</NavigationLink>
        </MenuItem>
      ))}
    </NavigationContainer>
  );
};
