import { BrowserRouter } from 'react-router-dom';
import { IChildrenProps } from 'utils/interface';

import { LoaderProvider } from './loader';
import { CustomLocalizationProvider } from './localization';
import { CustomFormProvider } from './react-hook-form';
import { CustomThemeProvider } from './theme';
import { ToastProvider } from './toast';
import { WebsocketProvider } from './websocket';

export function AppProvider({ children }: IChildrenProps) {
  return (
    <CustomThemeProvider>
      <BrowserRouter>
        <ToastProvider>
          <LoaderProvider>
            <WebsocketProvider>
              <CustomLocalizationProvider>
                <CustomFormProvider>{children}</CustomFormProvider>
              </CustomLocalizationProvider>
            </WebsocketProvider>
          </LoaderProvider>
        </ToastProvider>
      </BrowserRouter>
    </CustomThemeProvider>
  );
}
