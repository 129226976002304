import { Select, styled, TextField } from '@mui/material';

export const StyledPaginationBase = styled('div')`
  display: flex;
  align-items: center;
`;

export const StyledPaginationContainer = styled(StyledPaginationBase)`
  justify-content: space-between;
  padding-top: ${({ theme }) => theme.spacing(1.5)};
  margin-top: auto;

  p {
    height: ${({ theme }) => theme.spacing(1.75)};
    font-weight: 400;
    font-size: ${({ theme }) => theme.typography.customFontSize.xSmall};
    line-height: ${({ theme }) => theme.spacing(1.75)};
    display: flex;
    align-items: center;
    letter-spacing: -0.03em;
    color: ${({ theme }) => theme.palette.custom.grey33};
  }
`;

export const StyledPaginationTextField = styled(TextField)`
  input {
    padding: ${({ theme }) => theme.spacing(1.25)};
    width: ${({ theme }) => theme.spacing(2.875)};
    text-align: center;
    font-weight: 400;
    font-size: ${({ theme }) => theme.typography.customFontSize.small};
    line-height: ${({ theme }) => theme.spacing(2)};
    letter-spacing: -0.03em;
    height: ${({ theme }) => theme.spacing(1.625)};
    color: ${({ theme }) => theme.palette.custom.grey33};
  }
`;

export const StyledPaginationSelect = styled(Select)`
  div {
    padding: ${({ theme }) => theme.spacing(1.25)};
    width: ${({ theme }) => theme.spacing(2.875)};
    text-align: center;
    font-weight: 400;
    font-size: ${({ theme }) => theme.typography.customFontSize.small};
    line-height: ${({ theme }) => theme.spacing(2)};
    letter-spacing: -0.03em;
    height: ${({ theme }) => theme.spacing(1.625)};
    color: ${({ theme }) => theme.palette.custom.grey33};
    display: flex;
  }

  .MuiSelect-select {
    min-height: 0 !important;
  }
`;

export const StyledPaginationContainerOptions = styled(StyledPaginationBase)`
  justify-content: center;
  margin-left: ${({ theme }) => theme.spacing(2.5)};

  p {
    margin-right: ${({ theme }) => theme.spacing(0.5)};
  }
`;
