import { Controller } from 'react-hook-form';
import { useHookFormContext } from 'hooks';

import { SubTitle } from 'components/subtitle';

import {
  StyledFiltersAndReportDateInputContainer,
  StyledFiltersAndReportTextField,
} from '../filters-and-report.styled';

export const FiltersAndReportDate = () => {
  const { handleOpenCalendarModal, methods } = useHookFormContext();

  return (
    <StyledFiltersAndReportDateInputContainer>
      <SubTitle text="Date and time:" />
      <Controller
        control={methods.control}
        name="timeFrame"
        render={({ field: { onChange, value } }) => (
          <StyledFiltersAndReportTextField
            value={value}
            onClick={handleOpenCalendarModal}
            onChange={onChange}
            disabled
            label="Time Frame"
            error={!!methods.formState.errors['timeFrame']}
          />
        )}
      />
    </StyledFiltersAndReportDateInputContainer>
  );
};
