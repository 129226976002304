import { FORMAT, WEEK_DAYS } from 'utils/const';
import { getTruthyItems } from 'utils/helpers';

const { DATE } = FORMAT;

const sortWeekDaysForNumbers = (weekDays: string[]) =>
  weekDays.map(day => WEEK_DAYS.indexOf(day) + 1);

export const setDataFromReportPeriodsToSend = (data: any) => {
  const sensors = getTruthyItems(data.selectedSensorList);
  const classes = getTruthyItems(data.selectedClassList);
  const hours = `${data.hoursFrom} - ${data.hoursTo}`;
  const week_days = sortWeekDaysForNumbers(data.repeatForDays);

  return {
    start_date: data.dateRange[1]?.format(DATE) ?? DATE,
    end_date: data.dateRange[0]?.format(DATE) ?? DATE,
    sensors: sensors.result,
    classes: classes.result,
    hours,
    noise_limit: data.noiseLimit,
    week_days,
    report_interval: data.repeatForWeeks,
  };
};
