import { HTTP } from 'utils/const';

import { AxiosInstance } from '../axios-instance';

const {
  ENDPOINTS: { REPORT },
} = HTTP;

export const getReport = async (id: number): Promise<any> => {
  await AxiosInstance.get(REPORT, { data: { id: id } })
    .then(res => res.data)
    .then(data => data);
};
