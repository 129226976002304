import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { CssBaseline } from '@mui/material';

import './utils/translations';

import { AppProvider } from './context';
import App from './main';

const root = ReactDOM.createRoot(
  document.querySelector('#root') as HTMLElement,
);

root.render(
  <StrictMode>
    <AppProvider>
      <CssBaseline />
      <App />
    </AppProvider>
  </StrictMode>,
);
