import { ITitle } from 'utils/interface';

import {
  StyledTitle,
  StyledTitleContainer,
  StyledTitleSubTitle,
} from './title.styled';

export const Title = ({ title, subTitle }: ITitle) => (
  <StyledTitleContainer>
    <StyledTitle>{title}</StyledTitle>
    <StyledTitleSubTitle>{subTitle}</StyledTitleSubTitle>
  </StyledTitleContainer>
);
