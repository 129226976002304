import { styled, TableContainer } from '@mui/material';

export const StyledClassTableContainer = styled(TableContainer)`
  display: flex;
  flex-direction: column;

  .MuiTableRow-head {
    th:nth-of-type(2) {
      padding-left: ${({ theme }) => theme.spacing(1)};
      text-align: right;
    }

    th:nth-of-type(3) {
      text-align: right;
    }

    th:nth-of-type(4) {
      text-align: right;
    }

    th:nth-of-type(5) {
      text-align: right;
    }
  }

  .MuiTableBody-root {
    tr {
      td:nth-of-type(2) {
        padding-left: ${({ theme }) => theme.spacing(1)};
      }
    }
  }
`;

export const StyledClassTableExpandedContainer = styled(
  StyledClassTableContainer,
)`
  .MuiTableRow-head {
    th:nth-of-type(1) {
      max-width: ${({ theme }) => theme.spacing(4)};
      padding: ${({ theme }) => theme.spacing(0, 1)};
    }

    th:nth-of-type(2) {
      text-align: left;
    }

    th:nth-of-type(6) {
      text-align: right;
    }
  }

  .MuiTableBody-root {
    tr {
      td:nth-of-type(1) {
        max-width: ${({ theme }) => theme.spacing(4)};
        padding: ${({ theme }) => theme.spacing(0, 1)};
      }
    }
  }
`;
