import { ISensorMap } from 'utils/interface';

export const turnOffSensor = (
  handleSetSensor: (v: ISensorMap | null) => void,
  editorMemo?: fabric.Canvas,
) => {
  const objects = editorMemo?.getObjects();

  if (objects) {
    handleSetSensor(null);
    objects.forEach(elem => elem.set({ opacity: 1 }));
    editorMemo?.renderAll();
  }
};
