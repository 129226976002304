import { useCallback, useRef, useState } from 'react';
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart';
import { CHARTS, ZOOM_TYPE } from 'utils/const';
import { isObject } from 'utils/helpers/is-object';
import {
  ILineChartContainerRef,
  ILineChartMouseCoordinates,
} from 'utils/interface';

const { ZOOM } = CHARTS;

export const useZoom = () => {
  const [xPadding, setXPadding] = useState<number[]>([0, 0]);
  const wrapperRef = useRef<HTMLElement | null>(null);
  const chartMouseDown = useRef<ILineChartMouseCoordinates | null>(null);
  const chartXPaddingOnMouseDown = useRef<number[] | null>(null);

  const containerRef = useCallback((e: ILineChartContainerRef) => {
    if (isObject<ILineChartContainerRef>(e, 'current')) {
      wrapperRef.current = e.current;
    }
  }, []);

  const zoom = useCallback((type: string) => {
    setXPadding(padding => {
      const [left, right] = padding;
      const zoomValue = ZOOM * 100;

      if (type === ZOOM_TYPE.zoomIn) {
        return [left - zoomValue, right - zoomValue];
      }

      if (type === ZOOM_TYPE.zoomOut) {
        return [Math.min(left + zoomValue, 0), Math.min(right + zoomValue, 0)];
      }

      return [0, 0];
    });
  }, []);

  const onMouseMove = (state: CategoricalChartState) => drag(state);

  const onMouseDown = (state: CategoricalChartState) => {
    if (state) {
      const { chartX, chartY } = state;

      if (typeof chartX === 'number' && typeof chartY === 'number') {
        chartMouseDown.current = { x: chartX, y: chartY };
        chartXPaddingOnMouseDown.current = xPadding;
      }
    }
  };

  const onMouseUp = () => {
    chartMouseDown.current = null;
    chartXPaddingOnMouseDown.current = null;
  };

  const drag = (state: CategoricalChartState) => {
    if (
      chartMouseDown.current !== null &&
      state?.chartX &&
      state?.chartY &&
      chartXPaddingOnMouseDown.current
    ) {
      const xDistance = chartMouseDown.current.x - state.chartX;
      const [paddingLeft, paddingRight] = chartXPaddingOnMouseDown.current;

      const panPaddingLeft = paddingLeft - xDistance;
      const panPaddingRight = paddingRight + xDistance;

      if (panPaddingLeft > 0) {
        setXPadding(([, pr]) => [0, pr]);
        return;
      }
      if (panPaddingRight > 0) {
        setXPadding(([pl]) => [pl, 0]);
        return;
      }
      setXPadding([
        Math.min(paddingLeft - xDistance, 0),
        Math.min(paddingRight + xDistance, 0),
      ]);
    }
  };

  return {
    containerRef,
    onMouseMove,
    onMouseDown,
    onMouseUp,
    xPadding,
    zoom,
  };
};
