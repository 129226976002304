import { Image, Page } from '@react-pdf/renderer';
import map from 'assets/img/map1.png';

import { StyledPDFTemplatePageContent } from '../../pdf-template.styled';
import { PDFTemplateFooter } from '../../pdf-template-footer';
import { PDFTemplateHeader } from '../../pdf-template-header';

export const PDFTemplatePageMap = ({
  map,
}: {
  map: Promise<string | undefined>;
}) => (
  <Page size="A4" orientation="landscape">
    <PDFTemplateHeader />
    <StyledPDFTemplatePageContent>
      <Image src={map} />
    </StyledPDFTemplatePageContent>
    <PDFTemplateFooter>Map</PDFTemplateFooter>
  </Page>
);
