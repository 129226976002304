import { Button, DialogActions, DialogContentText } from '@mui/material';
import { IModalConfirm } from 'utils/interface';

import { Title } from 'components/title';

import { Modal } from '../modal';
import { StyledModalContent } from '../modal.styled';

export const ModalConfirm = ({
  children,
  open,
  onConfirm,
  onClose,
  title,
}: IModalConfirm) => (
  <Modal
    open={open}
    onCloseModal={onClose}
    aria-labelledby="modal-confirm"
    aria-describedby="modal-confirm"
  >
    <>
      <Title title={title} />

      <StyledModalContent>
        <DialogContentText id="modal-confirm-description">
          {children}
        </DialogContentText>
      </StyledModalContent>

      <DialogActions>
        <Button variant={'text'} color={'inherit'} onClick={onClose}>
          Cancel
        </Button>

        <Button
          variant={'contained'}
          color={'error'}
          onClick={() => {
            onConfirm();
            onClose();
          }}
          autoFocus
        >
          Delete
        </Button>
      </DialogActions>
    </>
  </Modal>
);
