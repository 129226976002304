import { useEffect, useState } from 'react';
import { socketClient } from 'utils/data-access/socket-client/socket-client-instance';
import { isEqual } from 'utils/helpers';
import { ISensor } from 'utils/interface';

export const useSocketSensors = () => {
  const [sensorsData, setSensorsData] = useState<ISensor[]>([]);

  useEffect(() => {
    socketClient.on('update', data => {
      if (data.sensors) {
        setSensorsData(prevState => {
          if (data !== undefined && !isEqual(prevState, data.sensors)) {
            return data.sensors;
          }

          return prevState;
        });
      }
    });
  }, []);

  return { sensorsData };
};
