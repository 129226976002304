import { isInArray } from 'utils/helpers';
import { ISensor } from 'utils/interface';
import {
  IGetPDFDataClass,
  IGetPDFDataProps,
  IGetPDFDataSensor,
} from 'utils/interface/pdf-data';

import { getLineChartDataMapper } from './get-line-chart-data.mapper';

export const getPDFLineChartMapper = ({
  global_measurements,
  sensors,
}: IGetPDFDataProps) => {
  const lineChartOverallData = getLineChartDataMapper(
    global_measurements.noise_level,
  );

  const clonedSensors = sensors.map((sensor: IGetPDFDataSensor) => ({
    ...sensor,
    noise_level: getLineChartDataMapper(sensor.noise_level),
  }));

  const sensorsGanttChartData = [
    { id: 'all', noise_level: lineChartOverallData },
    ...clonedSensors,
  ];

  return {
    sensors: sensorsGanttChartData,
  };
};

export const getPDFGanttChartMapper = ({
  global_measurements,
  sensors,
  classes,
}: IGetPDFDataProps) => {
  const ganttChartOverallData: IGetPDFDataClass[] = [];
  sensors.map((sensor: IGetPDFDataSensor) =>
    sensor.classes.map(singleClass => {
      if (
        ganttChartOverallData.length === 0 ||
        !isInArray(singleClass.id, ganttChartOverallData)
      ) {
        ganttChartOverallData.push(singleClass);
      }
    }),
  );

  const sensorsLineChartData = [{ id: 'all', classes }];
  sensors.map(sensor => sensorsLineChartData.push(sensor));

  return {
    ...global_measurements,
    sensors: sensorsLineChartData,
  };
};

export const getPDFMapMapper = ({
  global_measurements,
  sensors,
}: IGetPDFDataProps) => {
  const { noise_limit } = global_measurements;
  const mapSensors: ISensor[] = sensors.map(
    ({
      id,
      name,
      serial,
      pos_x,
      pos_y,
      available,
      current_noise_level,
      measurements,
    }) => ({
      id: Number(id),
      name,
      serial,
      pos_x,
      pos_y,
      available,
      current_noise_level,
      measurements,
    }),
  );

  return {
    noiseLimit: noise_limit,
    sensorsData: mapSensors,
  };
};
