import { colors } from 'styles/colors';
import { STATUS } from 'utils/const';

const { OVER_LIMIT, ACCEPTABLE } = STATUS;

export const setCurrentColorForActiveSensorOnMap = (noiseStatus: string) => {
  if (noiseStatus === ACCEPTABLE) return colors.map['active-sensor-warning'];
  if (noiseStatus === OVER_LIMIT) return colors.map['active-sensor-danger'];
  return colors.map['active-sensor-success'];
};
