export const colors = {
  'blue-primary': '#5659D5',
  'blue-dark': '#13154E',
  'blue-light': '#DEDFF7',
  'background-primary': '#F2F2F7',
  'background-modal': '#FCFCFD',
  'green-light': '#EFFAF0',
  'green-main': '#0AB506',
  'green-dark': '#127510',
  'green-text-label': '#2b822f',
  'red-light': '#FCF2F2',
  'red-main': '#D41B1B',
  'orange-light': '#FEF3E1',
  'orange-main': '#F49609',
  'orange-dark': '#FF5B22',
  'white-main': '#FFFFFF',
  'white-link': '#F9F9FB',
  'dark-main': '#29292E',
  'grey-light': '#E1E1E1',
  'grey-main': '#595964',
  'gray-86': '#dbdbdb',
  'black-main': '#000000',
  'white-grey': '#E4E4E4',
  'blue-main': '#DEDFF7',
  shadow: 'rgba(162, 162, 162, 0.08)',
  map: {
    'circle-fill': '#F9F9FB',
    'circle-border': '#29292E',
    'active-sensor-warning': '#F49609',
    'active-sensor-danger': '#D41B1B',
    'active-sensor-success': '#0AB506',
    text: '#FFFFFF',
    'offline-sensor-box-background': '#848484',
  },
  'border-grey': '#D9D9D9',
  'border-grey-light': '#c9c9c9',
  'dark-grey': '#A6A6A6',
  'rare-grey': '#595964',
  'athens-grey': '#FCFCFD',
  'grey-33': '#545454',
  'red-text-label': '#c22828',
  'orange-text-label': '#714604',
  'white-ghost': '#F3F3F7',
  'line-chart': {
    line: '#959595',
    'cartesian-grid-fill': '#F9F9FB',
    'cartesian-grid-stroke': '#D9D9D9',
    axis: '#D9D9D9',
    label: '#595964',
    'reference-line': '#D41B1B',
  },
  toast: {
    'success-background': '#D9F8D8',
    'error-background': '#FCE8E8',
    'error-color': '#A31515',
  },
  disabledText: 'rgba(0, 0, 0, 0.38)',
  'loader-bg': 'rgba(255, 255, 255, 0.65)',
};
