import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Card, Table } from '@mui/material';
import { useHookFormContext, usePagination, useWebsocketContext } from 'hooks';
import { TABLE } from 'utils/const';
import { IUseFilterProps, IVisibleContent } from 'utils/interface';
import { getClassTableDataMapper } from 'utils/mapper';

import { Pagination } from 'components/pagination';
import { Placeholder } from 'components/placeholder';
import { TableHead } from 'components/table';
import { Title } from 'components/title';

import { StyledClassTableContainer } from './class-table.styled';
import { ClassTableBody } from './class-table-body';

const { CLASSES } = TABLE;

export const ClassTable = ({ visibility }: IVisibleContent) => {
  const { register } = useFormContext<IUseFilterProps>();
  const {
    data: { noiseLimit },
  } = useHookFormContext();
  const { t } = useTranslation('sidebar');
  const header = t('classes_table_header');
  const subheader = t('classes_table_subheader');
  const { selectedClassesData, globalData } = useWebsocketContext();
  const selectedClassesTableData = getClassTableDataMapper(selectedClassesData);

  const {
    sliceStart,
    sliceEnd,
    rowsPerPage,
    page,
    paginationPage,
    paginationLimit,
    handleChangePage,
    handleChangePageSelect,
    handleChangeRowsPerPage,
    handleOnKeyDown,
  } = usePagination(selectedClassesTableData?.length);

  return visibility && selectedClassesTableData && globalData ? (
    <Card>
      <Title title={header} subTitle={subheader} />

      {selectedClassesTableData.length > 0 &&
      globalData.noise_level.length > 0 ? (
        <StyledClassTableContainer>
          <Table>
            <TableHead rowTitles={CLASSES} />

            <ClassTableBody
              data={selectedClassesTableData}
              sliceStart={sliceStart}
              sliceEnd={sliceEnd}
              register={register}
              noiseLimit={noiseLimit}
            />
          </Table>

          {selectedClassesTableData.length > paginationLimit ? (
            <Pagination
              results={selectedClassesTableData.length}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              paginationPage={paginationPage}
              page={page}
              handleChangePageSelect={handleChangePageSelect}
              handleOnKeyDown={handleOnKeyDown}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          ) : null}
        </StyledClassTableContainer>
      ) : (
        <Placeholder>
          At least one sensor and one class must be selected to show classes
          details
        </Placeholder>
      )}
    </Card>
  ) : null;
};
