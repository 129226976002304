import { Button, styled, TableContainer } from '@mui/material';

const StyledReportsTableContainerBase = styled(TableContainer)`
  .MuiTableCell-head,
  .MuiTableCell-body {
    &:last-of-type,
    &:nth-of-type(-n),
    &:nth-of-type(-2n) {
      width: ${({ theme }) => theme.spacing(12)};
    }
  }

  .MuiIconButton-root {
    transform: translateX(${({ theme }) => theme.spacing(-1)});
  }
`;

export const StyledReportsTableContainer = styled(
  StyledReportsTableContainerBase,
)`
  display: flex;
  flex-direction: column;

  .MuiTableRow-head {
    th:nth-of-type(5) {
      text-align: right;
    }

    th:nth-of-type(7) {
      text-align: right;
    }

    th:nth-of-type(8) {
      text-align: right;
    }
  }
`;

export const StyledReportingPeriodsTableContainer = styled(
  StyledReportsTableContainerBase,
)`
  display: flex;
  flex-direction: column;

  .MuiTableRow-head {
    th:nth-of-type(4) {
      text-align: right;
    }

    th:nth-of-type(5) {
      text-align: right;
    }
  }
`;

export const StyledTabsButton = styled(Button)`
  margin: 5px 0;
  margin-left: auto;
  position: absolute;
  right: 20px;
  top: 22px;
  z-index: 1000;
`;
