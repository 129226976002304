import { View } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import { theme } from 'context/theme/theme';

import { StyledPDFTemplateLabeledData } from '../pdf-template-page/pdf-template-page.styled';

export const StyledPDFTemplateBulletList = styled(View)`
  margin-top: ${() => theme.spacing(0.5)};
`;

export const StyledPDFTemplateBulletListItem = styled(
  StyledPDFTemplateLabeledData,
)``;

export const StyledPDFTemplateBullet = styled(View)`
  width: ${() => theme.spacing(0.5)};
  height: ${() => theme.spacing(0.5)};
  background-color: ${() => theme.palette.text.primary};
`;
