import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TIME_PLACEHOLDER } from 'utils/const';
import { ICalendarInputs } from 'utils/interface';

import { SubTitle } from 'components/subtitle';

import {
  StyeldCalendarInputsBox,
  StyledCalendarInput,
  StyledCalendarInputsContainer,
} from '../calendar.styled';

export const CalendarInputs = ({
  timePickerValue,
  handleTimePicker,
  textFieldValue,
  title,
  dateError,
  timeError,
}: ICalendarInputs) => (
  <>
    <StyledCalendarInputsContainer>
      <SubTitle text={title} />
      <StyeldCalendarInputsBox>
        <StyledCalendarInput
          type="text"
          label="Date"
          required
          value={textFieldValue}
          disabled
          error={!!dateError}
          helperText={!!dateError ? 'Please insert date' : null}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            label="Time"
            value={timePickerValue}
            ampm={false}
            onChange={handleTimePicker}
            renderInput={params => (
              <StyledCalendarInput
                {...params}
                placeholder={TIME_PLACEHOLDER}
                error={timeError || !params.inputProps?.value || params.error}
                helperText={
                  timeError || !params.inputProps?.value || params.error
                    ? 'Please insert time'
                    : null
                }
              />
            )}
          />
        </LocalizationProvider>
      </StyeldCalendarInputsBox>
    </StyledCalendarInputsContainer>
  </>
);
