import ReactDOM from 'react-dom';
import { ILoaderProps } from 'utils/interface';

import { StyledLoaderWrapper, StyledSpinner } from './loader.styled';

export const Loader = ({ children, loading, fixed, spinner }: ILoaderProps) => {
  const loader = (
    <StyledLoaderWrapper>
      {children}
      {spinner ? <StyledSpinner /> : <span>...</span>}
    </StyledLoaderWrapper>
  );

  return loading
    ? fixed
      ? ReactDOM.createPortal(
          loader,
          document.querySelector('#root') as HTMLElement,
        )
      : loader
    : null;
};
