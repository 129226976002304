import { Navigate } from 'react-router-dom';
import { ROUTES } from 'utils/const';
import { Logo } from 'utils/icons';
import { ILocalStorage } from 'utils/interface';

import { LoginForm, LoginImageContainer } from 'components/login';

import { StyledLoginContainer, StyledLoginLeftSide } from './login.styled';

export const Login = ({ setToken, token }: ILocalStorage) => {
  if (token) {
    return <Navigate to={ROUTES.SENSORS} replace />;
  }

  return (
    <StyledLoginContainer>
      <StyledLoginLeftSide>
        <Logo />
        <LoginForm setToken={setToken} />
      </StyledLoginLeftSide>
      <LoginImageContainer />
    </StyledLoginContainer>
  );
};
