import { createContext } from 'react';
import { useLoader } from 'hooks';
import { IChildrenProps, ILoaderContext } from 'utils/interface';

export const LoaderContext = createContext<ILoaderContext | null>(null);

export function LoaderProvider({ children }: IChildrenProps) {
  const value = useLoader();

  return (
    <LoaderContext.Provider value={value}>{children}</LoaderContext.Provider>
  );
}

export default LoaderProvider;
