import { useContext } from 'react';
import { ToastContext } from 'context/toast';

export const useToastContext = () => {
  const context = useContext(ToastContext);

  if (context === null) {
    throw new Error('useToastContext must be used within a useToastProvider');
  }
  return context;
};
