import { useHookFormContext } from 'hooks/context';
import { STATUS } from 'utils/const';

const { NORMAL, OVER_LIMIT, ACCEPTABLE } = STATUS;

export const useNoiseViolationLimits = (noise_limit?: number) => {
  const {
    data: { noiseLimit },
  } = useHookFormContext();

  const limit = noise_limit ? noise_limit : noiseLimit;

  const acceptable = () => {
    return noise_limit
      ? noise_limit - +process.env.REACT_APP_NOISE_LIMIT_VIOLATIONS_WARNING
      : noiseLimit - +process.env.REACT_APP_NOISE_LIMIT_VIOLATIONS_WARNING;
  };

  const status = (value: number) => {
    if (limit === 0) return NORMAL;
    if (value >= acceptable() && value < limit) return ACCEPTABLE;
    if (value >= limit) return OVER_LIMIT;
    return NORMAL;
  };

  return {
    status,
  };
};
