import { createTheme } from '@mui/material';
import { colors } from 'styles/colors';
import { fontSize } from 'styles/font';
import { shadows } from 'styles/shadows';

declare module '@mui/material/styles' {
  interface Palette {
    custom: {
      white: string;
      whiteLink: string;
      orangeDark: string;
      orangeMain: string;
      black: string;
      greyLight: string;
      greyMain: string;
      whiteGrey: string;
      darkGrey: string;
      rareGrey: string;
      athensGrey: string;
      grey33: string;
      grey86: string;
      greenTextLabel: string;
      redTextLabel: string;
      orangeTextLabel: string;
      whiteGhost: string;
      lineChartLine: string;
      lineChartCartesianGridFill: string;
      lineChartCartesianGridStroke: string;
      lineChartLineAxis: string;
      lineChartLineLabel: string;
      lineChartLineReferenceLine: string;
      disabledText: string;
      loaderBG: string;
    };
    border: {
      borderGrey: string;
      borderGreyLight: string;
    };
    shadows: {
      shadowTooltip: string;
      shadowButton: string;
      shadowMapPopup: string;
    };
  }

  interface PaletteOptions {
    custom?: {
      white: string;
      whiteLink: string;
      orangeDark: string;
      orangeMain: string;
      black: string;
      greyLight: string;
      greyMain: string;
      whiteGrey: string;
      darkGrey: string;
      rareGrey: string;
      athensGrey: string;
      grey33: string;
      grey86: string;
      greenTextLabel: string;
      redTextLabel: string;
      orangeTextLabel: string;
      whiteGhost: string;
      lineChartLine: string;
      lineChartCartesianGridFill: string;
      lineChartCartesianGridStroke: string;
      lineChartLineAxis: string;
      lineChartLineLabel: string;
      lineChartLineReferenceLine: string;
      disabledText: string;
      loaderBG: string;
    };
    border?: {
      borderGrey: string;
      borderGreyLight: string;
    };
    shadows?: {
      shadowTooltip: string;
      shadowButton: string;
      shadowMapPopup: string;
    };
  }

  interface TypographyVariants {
    customFontSize: {
      xSmall: string;
      small: string;
      medium: string;
      large: string;
      xLarge: string;
      xxLarge: string;
      xxxLarge: string;
    };
  }

  interface TypographyVariantsOptions {
    customFontSize: {
      xSmall: string;
      small: string;
      medium: string;
      large: string;
      xLarge: string;
      xxLarge: string;
      xxxLarge: string;
    };
  }
}

export const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        svg: {
          fontSize: '20px',
        },
        'body *::-webkit-scrollbar': {
          width: '6px',
          minHeight: '60px',
        },
        'body *::-webkit-scrollbar-thumb': {
          background: colors['grey-light'],
          WebkitBorderRadius: '2px',
          WebkitBoxShadow: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          textTransform: 'capitalize',
        },
        outlined: {
          background: colors['blue-light'],
          color: colors['blue-primary'],
          border: 'none',
          textTransform: 'capitalize',
          '&:hover': {
            border: 'none',
          },
        },
        text: {
          textTransform: 'capitalize',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          padding: '24px',
          borderRadius: '5px',
          boxShadow: shadows['shadow-card'],
          marginBottom: '24px',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: '0',
          marginBottom: '12px',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '0',
          marginTop: '12px',
          '&:last-child': {
            paddingBottom: 0,
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: colors['blue-dark'],
          height: '76px',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: '76px',
          display: 'flex',
          justifyContent: 'space-between',
          '@media (min-width: 600px)': {
            padding: 0,
            margin: '0 auto',
            width: '100%',
            maxWidth: '1320px',
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          minWidth: '340px',
          maxWidth: '100%',
          '.MuiAlert-message': {
            fontWeight: 400,
            fontSize: fontSize.small,
          },
        },
        filledSuccess: {
          backgroundColor: colors['toast']['success-background'],
          '.MuiAlert-message': {
            color: colors['green-dark'],
          },
          '.MuiAlert-icon': {
            color: colors['green-dark'],
          },
        },
        filledError: {
          backgroundColor: colors['toast']['error-background'],
          '.MuiAlert-message': {
            color: colors['toast']['error-color'],
          },
          '.MuiAlert-icon': {
            color: colors['toast']['error-color'],
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          width: '100%',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: colors['dark-grey'],
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 600,
          padding: '12px 16px',
          fontSize: fontSize.xSmall,
          backgroundColor: colors['background-modal'],
        },
        root: {
          fontSize: fontSize.xSmall,
          padding: '8px 16px',
          color: colors['rare-grey'],
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: colors['white-link'],
          },
          '&.Mui-selected': {
            backgroundColor: colors['white-ghost'],
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: 0,
          paddingLeft: 0,
          paddingRight: 0,
          marginRight: '24px',
          fontWeight: 500,
          textTransform: 'initial',
        },
      },
    },
  },
  palette: {
    primary: {
      main: colors['blue-primary'],
      light: colors['blue-light'],
      dark: colors['blue-dark'],
    },
    success: {
      main: colors['green-main'],
      light: colors['green-light'],
      dark: colors['green-dark'],
    },
    error: {
      light: colors['red-light'],
      main: colors['red-main'],
    },
    warning: {
      main: colors['orange-main'],
      light: colors['orange-light'],
    },
    info: {
      main: colors['blue-main'],
    },
    background: {
      default: colors['background-primary'],
      paper: colors['background-modal'],
    },
    border: {
      borderGrey: colors['border-grey'],
      borderGreyLight: colors['border-grey-light'],
    },
    shadows: {
      shadowTooltip: shadows['shadow-tooltip'],
      shadowButton: shadows['shadow-button'],
      shadowMapPopup: shadows['shadow-map-popup'],
    },
    text: {
      primary: colors['dark-main'],
      secondary: colors['grey-main'],
    },
    custom: {
      white: colors['white-main'],
      whiteLink: colors['white-link'],
      orangeMain: colors['orange-main'],
      orangeDark: colors['orange-dark'],
      greyLight: colors['grey-light'],
      greyMain: colors['grey-main'],
      black: colors['black-main'],
      whiteGrey: colors['white-grey'],
      darkGrey: colors['dark-grey'],
      rareGrey: colors['rare-grey'],
      athensGrey: colors['athens-grey'],
      grey33: colors['grey-33'],
      grey86: colors['gray-86'],
      greenTextLabel: colors['green-text-label'],
      redTextLabel: colors['red-text-label'],
      orangeTextLabel: colors['orange-text-label'],
      whiteGhost: colors['white-ghost'],
      lineChartLine: colors['line-chart']['line'],
      lineChartCartesianGridFill: colors['line-chart']['cartesian-grid-fill'],
      lineChartCartesianGridStroke:
        colors['line-chart']['cartesian-grid-stroke'],
      lineChartLineAxis: colors['line-chart']['axis'],
      lineChartLineLabel: colors['line-chart']['label'],
      lineChartLineReferenceLine: colors['line-chart']['reference-line'],
      disabledText: colors['disabledText'],
      loaderBG: colors['loader-bg'],
    },
  },
  typography: {
    h1: {
      fontSize: fontSize.large,
      fontWeight: 500,
    },
    customFontSize: {
      xSmall: fontSize.xSmall,
      small: fontSize.small,
      medium: fontSize.medium,
      large: fontSize.large,
      xLarge: fontSize.xLarge,
      xxLarge: fontSize.xxLarge,
      xxxLarge: fontSize.xxxLarge,
    },
  },
});
