import { HTTP } from 'utils/const';

import { AxiosInstance } from '../axios-instance';

const {
  ENDPOINTS: { DELETE_REPORTING_PERIOD },
} = HTTP;

export const deleteReportingPeriod = async (id: number): Promise<any> =>
  await AxiosInstance.get(`${DELETE_REPORTING_PERIOD}/${id}`)
    .then(res => res.data)
    .then(data => data);
