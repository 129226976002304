import {
  Checkbox,
  TableCell,
  TableHead as MuiTableHead,
  TableRow,
} from '@mui/material';
import { getCheckedAllStatus } from 'utils/helpers';
import { ITableHead } from 'utils/interface';

export const TableHead = ({
  rowTitles,
  listName,
  onSelectAllChange,
  selectedItems,
}: ITableHead) => (
  <MuiTableHead>
    <TableRow>
      {selectedItems && listName ? (
        <TableCell>
          <Checkbox
            id={listName}
            color="success"
            onChange={onSelectAllChange}
            checked={getCheckedAllStatus(selectedItems)}
          />
        </TableCell>
      ) : null}

      {rowTitles.map((name: string, index: number) => {
        return <TableCell key={`${name}-${index}`}>{name}</TableCell>;
      })}
    </TableRow>
  </MuiTableHead>
);
