import { Card, styled } from '@mui/material';

export const StyledTilesContainer = styled('div')`
  display: flex;
  gap: ${({ theme }) => theme.spacing(3)};
`;

export const StyledTileCard = styled(Card)`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  span {
    font-weight: 500;
    display: flex;
    align-items: center;
    font-size: ${({ theme }) => theme.typography.customFontSize.large};
    color: ${({ theme }) => theme.palette.text.primary};
  }

  p {
    margin-top: 0;
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: ${({ theme }) => theme.typography.customFontSize.small};
  }
`;
