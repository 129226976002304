import { HTTP } from 'utils/const';
import { IGetReports } from 'utils/interface';

import { AxiosInstance } from '../axios-instance';

const {
  ENDPOINTS: { REPORTS },
} = HTTP;

export const getReports = async (
  offset: number,
  limit: number,
): Promise<IGetReports> => {
  return await AxiosInstance.get(REPORTS, {
    data: {
      offset: offset,
      limit: limit,
    },
  })
    .then(res => res.data)
    .then(data => data);
};
