import { FormControl, Select, styled, Tabs, TextField } from '@mui/material';

export const StyledReportPeriodsTabs = styled(Tabs)`
  min-width: 486px;
  background-color: #e3e3ee;
  min-height: 44px;
  border-radius: 5px;

  .MuiTabs-indicator {
    display: none;
  }

  button {
    margin: 0;
    min-height: 36px;
    font-weight: 500;
    font-size: 14px;
  }

  .Mui-selected {
    background: #5659d5;
    border-radius: 5px;
    color: #ffffff !important;
    font-weight: 500;
    font-size: 14px;
    margin: 4px;
    padding: 2px 0;
  }
`;

export const StyledTitleStep = styled('span')`
  font-weight: 500;
  font-size: 16px;
  position: absolute;
  top: 24px;
  right: 48px;
`;

export const StyledReportPeriodsLabelData = styled('span')`
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: 600;
  font-size: ${({ theme }) => theme.typography.customFontSize.medium};
  padding-left: 8px;
`;

export const StyledReportPeriodsLabelContainer = styled('div')`
  color: ${({ theme }) => theme.palette.text.secondary};
  display: flex;
`;

export const StyledReportPeriodsTextField = styled(TextField)`
  margin-top: ${({ theme }) => theme.spacing(2)};
  width: ${({ theme }) => theme.spacing(60.75)};

  input {
    padding: ${({ theme }) => theme.spacing(1, 2)};
  }

  label {
    color: ${({ theme }) => theme.palette.custom.disabledText};
  }

  .Mui-error {
    fieldset {
      border-color: ${({ theme }) => theme.palette.error.main} !important;
    }
  }
`;

export const StyledReportPeriodsFormControl = styled(FormControl)`
  margin-top: ${({ theme }) => theme.spacing(2)};

  label {
    top: ${({ theme }) => theme.spacing(-1)};
    color: rgba(0, 0, 0, 0.38);
  }

  .MuiInputLabel-shrink {
    top: 0;
  }
`;

export const StyledReportPeriodsSelect = styled(Select)`
  width: ${({ theme }) => theme.spacing(60.75)};

  .MuiSelect-outlined {
    padding: ${({ theme }) => theme.spacing(1, 2)};
  }
`;

export const StyledReportPeriodsHoursField = styled(
  StyledReportPeriodsTextField,
)`
  width: ${({ theme }) => theme.spacing(28)};

  label {
    top: ${({ theme }) => theme.spacing(-1)};
  }

  .MuiInputLabel-shrink {
    top: ${({ theme }) => theme.spacing(0)};
  }
`;

export const StyledReportPeriodsInputContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing(3)};

  .MuiInputLabel-shrink {
    top: 0;
  }

  label {
    top: ${({ theme }) => theme.spacing(-1)};
  }
`;
