import { ISensor } from 'utils/interface';

import { setArrowOnMap } from './set-arrow-on-map';
import { setLeftDirectionOnMap } from './set-left-direction-on-map';
import { setTopDirectionOnMap } from './set-top-direction-on-map';

export const setAllArrowsOnMap = async (
  sensor: ISensor,
  editorMemo?: fabric.Canvas,
) => {
  const { pos_x, pos_y, measurements, available, id } = sensor;

  if (!available || !editorMemo) return;

  const left = setLeftDirectionOnMap(editorMemo.getWidth(), pos_x);
  const top = setTopDirectionOnMap(editorMemo.getHeight(), pos_y);

  if (available && !Number.isNaN(measurements?.noise_direction)) {
    setArrowOnMap(
      left,
      top,
      id.toString() + '_arrow',
      measurements?.noise_direction,
      editorMemo,
    );
  }
};
