import axios from 'axios';
import { HTTP } from 'utils/const';

const {
  TIMEOUT,
  ENDPOINTS: { BASE_URL },
} = HTTP;

export const AxiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: TIMEOUT,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
  },
});
