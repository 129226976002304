import { styled } from '@mui/material';

export const StyledLoginLeftSide = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 42%;
`;

export const StyledLoginContainer = styled('div')`
  padding: ${({ theme }) => theme.spacing(2.5)};
  background-color: ${({ theme }) => theme.palette.custom.whiteLink};
  display: flex;
  height: 100vh;
`;
