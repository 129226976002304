import {
  StyledFlexContainer,
  StyledMapDescriptionContainer,
  StyledMapDescriptionDangerSquare,
  StyledMapDescriptionNormalSquare,
  StyledMapDescriptionWarningSquare,
} from '../map.styled';

export const MapDescription = () => (
  <StyledMapDescriptionContainer>
    <p>Noise level</p>
    <StyledFlexContainer>
      <StyledFlexContainer>
        <StyledMapDescriptionNormalSquare />
        <span>normal</span>
      </StyledFlexContainer>
      <StyledFlexContainer>
        <StyledMapDescriptionWarningSquare />
        <span>acceptable</span>
      </StyledFlexContainer>
      <StyledFlexContainer>
        <StyledMapDescriptionDangerSquare />
        <span>over limit</span>
      </StyledFlexContainer>
    </StyledFlexContainer>
  </StyledMapDescriptionContainer>
);
