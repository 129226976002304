import { useState } from 'react';
import { Button, Tab } from '@mui/material';
import { useWebsocketContext } from 'hooks';

import { TabPanel } from 'components/tabs/tab-panel';
import { Title } from 'components/title';

import {
  StyledReportPeriodsTabs,
  StyledTitleStep,
} from '../report-periods.styled';
import { ReportPeriodsCheckboxList } from '../report-periods-checkbox-list';
import { ReportPeriodsLabel } from '../report-periods-label';

export const RaportPeriodsFirstPage = ({
  selectedSensors,
  selectedClasses,
  methods,
  handleForwardPage,
}: any) => {
  const { sensorsData, classesData } = useWebsocketContext();
  const [tabValue, setTabValue] = useState(0);

  const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Title
        title={'Select Sensors and Classes'}
        subTitle={'Your report will contain data of selected items'}
      />
      <StyledTitleStep>Step 1/2</StyledTitleStep>
      <StyledReportPeriodsTabs
        value={tabValue}
        onChange={handleChangeTabs}
        aria-label="class or sensor tabs"
        variant="fullWidth"
        style={{ marginTop: '16px' }}
      >
        <Tab label={'Sensors'} />
        <Tab label={'Classes'} />
      </StyledReportPeriodsTabs>
      <div style={{ display: 'flex', gap: '8px', padding: '16px 0 12px 0' }}>
        <ReportPeriodsLabel
          text={'Selected sensors:'}
          data={selectedSensors.toString()}
        />
        <ReportPeriodsLabel
          text={'Selected classes:'}
          data={selectedClasses.toString()}
        />
      </div>
      <TabPanel value={tabValue} index={0}>
        {sensorsData && sensorsData?.length > 0 ? (
          <ReportPeriodsCheckboxList
            data={sensorsData}
            listName={'selectedSensorList'}
            listTitle={'Sensors'}
            methods={methods}
          />
        ) : (
          <>Loader</>
        )}
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        {classesData && classesData?.length > 0 ? (
          <ReportPeriodsCheckboxList
            data={classesData}
            listName={'selectedClassList'}
            listTitle={'Classes'}
            methods={methods}
          />
        ) : (
          <>Loader</>
        )}
      </TabPanel>
      <div style={{ display: 'flex', marginTop: '10px' }}>
        <Button
          variant="contained"
          onClick={handleForwardPage}
          style={{ marginLeft: 'auto' }}
          disabled={selectedSensors === 0 || selectedClasses === 0}
        >
          next
        </Button>
      </div>
    </>
  );
};
