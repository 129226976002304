import { LinkOrange } from 'utils/icons';

import {
  StyledLoginImgContainer,
  StyledLoginImgContainerParagraph,
  StyledLoginImgContainerTitle,
} from '../login.styled';

export const LoginImageContainer = () => (
  <StyledLoginImgContainer>
    <div>
      <LinkOrange />
      <StyledLoginImgContainerTitle>
        We deliver first-in-class noise reduction technologies through
        scientific advancement.
      </StyledLoginImgContainerTitle>
      <StyledLoginImgContainerParagraph>
        We have been providing complete product development services to the
        world leading organizations across all industries solving complex noise
        and vibration problems.
      </StyledLoginImgContainerParagraph>
    </div>
  </StyledLoginImgContainer>
);
