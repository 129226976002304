import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { resources } from './resource';
export const defaultNS = 'common';
export type Languages = 'en' | 'pl';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    ns: ['common', 'header', 'sidebar', 'limitbar', 'filtersAndReport'],
    fallbackLng: 'en',
    debug: false,
    defaultNS,
    interpolation: {
      escapeValue: false,
    },
  });

export { default as kfbI18n } from 'i18next';
