import { styled } from '@mui/material';

export const StyledPlaceholder = styled('div')`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: calc(100% - 60px);
  min-height: 240px;
  margin-top: 24px;
  margin-left: auto;
  margin-right: 18px;
  background-color: ${({ theme }) =>
    theme.palette.custom.lineChartCartesianGridFill};
  color: ${({ theme }) => theme.palette.custom.darkGrey};
  text-align: center;
`;
