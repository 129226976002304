/* eslint-disable unicorn/numeric-separators-style */
import { Card } from '@mui/material';
import { useHookFormContext } from 'hooks';
import { convertTime } from 'utils/helpers';
import { IVisibleContent } from 'utils/interface';

import { ChartWrapper } from 'components/chart/chart-wrapper';
import { Title } from 'components/title';

export const SensorsView = ({ visibility }: IVisibleContent) => {
  const {
    data: { interval },
  } = useHookFormContext();

  return visibility ? (
    <Card>
      <Title
        title="Time course of noise level [dBA]"
        subTitle={`Equivalent sound pressure levels for ${convertTime(
          interval,
        )}`}
      />

      <ChartWrapper />
    </Card>
  ) : null;
};
