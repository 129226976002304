import { useContext } from 'react';
import { HookFormContext } from 'context/react-hook-form';

export const useHookFormContext = () => {
  const context = useContext(HookFormContext);
  if (context === null) {
    throw new Error(
      'CustomFormProvider must be used within a useHookFormContext',
    );
  }
  return context;
};
