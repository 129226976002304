import { createContext } from 'react';
import { useToast } from 'hooks';
import { IChildrenProps, IToastContext } from 'utils/interface';

export const ToastContext = createContext<IToastContext | null>(null);

export function ToastProvider({ children }: IChildrenProps) {
  const value = useToast();

  return (
    <ToastContext.Provider value={value}>{children}</ToastContext.Provider>
  );
}

export default ToastProvider;
