import 'react-i18next';

import commonEn from './locales/en/common.json';
import filtersAndReportEn from './locales/en/filters-and-report.json';
import headerEn from './locales/en/header.json';
import limitbarEn from './locales/en/limitbar.json';
import sidebarEn from './locales/en/sidebar.json';
import commonPl from './locales/pl/common.json';
import filtersAndReportPl from './locales/pl/filters-and-report.json';
import headerPl from './locales/pl/header.json';
import limitbarPl from './locales/pl/limitbar.json';
import sidebarPl from './locales/pl/sidebar.json';
import { defaultNS } from './i18n';

export const resources = {
  en: {
    header: headerEn,
    sidebar: sidebarEn,
    common: commonEn,
    limitbar: limitbarEn,
    filtersAndReport: filtersAndReportEn,
  },
  pl: {
    header: headerPl,
    sidebar: sidebarPl,
    common: commonPl,
    limitbar: limitbarPl,
    filtersAndReport: filtersAndReportPl,
  },
};

// react-i18next versions higher than 11.11.0
declare module 'react-i18next' {
  // and extend them!
  interface CustomTypeOptions {
    // custom namespace type if you changed it
    defaultNS: typeof defaultNS;
    // custom resources type
    resources: {
      common: typeof commonEn;
      header: typeof headerEn;
      sidebar: typeof sidebarEn;
      limitbar: typeof limitbarEn;
      filtersAndReport: typeof filtersAndReportEn;
    };
  }
}
