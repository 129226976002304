import { Button, styled, TextField } from '@mui/material';
import background from 'assets/img/image001.jpg';

export const StyledLoginInputsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

export const StyledLoginImgContainer = styled('div')`
  background: url(${background}) no-repeat;
  background-size: cover;
  border-radius: ${({ theme }) => theme.spacing(0.625)};
  flex: 1;

  div {
    margin: ${({ theme }) => theme.spacing(5, 0, 0, 5)};
    max-width: ${({ theme }) => theme.spacing(87.5)};
  }
`;

export const StyledLoginImgContainerTitle = styled('p')`
  margin: ${({ theme }) => theme.spacing(2, 0, 2.5, 0)};
  color: ${({ theme }) => theme.palette.custom.white};
  font-weight: 600;
  font-size: ${({ theme }) => theme.typography.customFontSize.xxxLarge};
  line-height: ${({ theme }) => theme.spacing(4.25)};
`;

export const StyledLoginImgContainerParagraph = styled('p')`
  margin: 0;
  color: ${({ theme }) => theme.palette.custom.whiteGrey};
  font-weight: 400;
  font-size: ${({ theme }) => theme.typography.customFontSize.medium};
  line-height: ${({ theme }) => theme.spacing(3)};
`;

export const StyledLoginForm = styled('form')`
  display: flex;
  flex-direction: column;
  width: ${({ theme }) => theme.spacing(40)};

  h2 {
    font-weight: 600;
    font-size: ${({ theme }) => theme.typography.customFontSize.xxLarge};
    line-height: 28px;
    margin: ${({ theme }) => theme.spacing(2.5, 0)};
  }
`;

export const StyledLoginTextField = styled(TextField)`
  margin: ${({ theme }) => theme.spacing(2, 0)};
  min-width: ${({ theme }) => theme.spacing(33)};

  input {
    padding: ${({ theme }) => theme.spacing(1, 2)};
  }
`;

export const StyledLoginResetPasswordButton = styled(Button)`
  display: flex;
  justify-content: end;
  text-decoration: underline;
  text-transform: inherit;
  font-size: ${({ theme }) => theme.typography.customFontSize.small};
  font-weight: 600;
  padding: ${({ theme }) => theme.spacing(1.5, 0, 3, 0)};

  &:hover {
    background-color: inherit;
  }
`;

export const StyledLoginRequestAccess = styled('span')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: ${({ theme }) => theme.spacing(3.5)};

  button {
    padding: ${({ theme }) => theme.spacing(0, 0, 0, 1)};
    &:hover {
      background-color: inherit;
    }
  }
`;
