export const getDividedNumbers = (
  start: number,
  end: number,
  divider: number,
) => {
  const diff = (end - start) / divider;
  const result: number[] = [];

  for (let i = 0; i <= divider; i++) {
    const time = Math.round(start + diff * i);
    result.push(time);
  }

  return { result };
};
