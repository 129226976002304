/* eslint-disable unicorn/numeric-separators-style */
import { styled } from '@mui/material';
import { ISGanttChartContentProps } from 'utils/interface';

export const StyledGanttChartWrapper = styled('div')`
  justify-content: flex-end;
  display: flex;
  margin-top: ${({ theme }) => theme.spacing(2)};

  h3 {
    padding-left: ${({ theme }) => theme.spacing(1.25)};
  }
`;

export const StyledGanttChartContent = styled('div')<ISGanttChartContentProps>`
  position: relative;
  transform: translateX(${({ theme }) => theme.spacing(-2.4)});
  margin-top: ${({ theme }) => theme.spacing(-8.25)};
  padding-top: ${({ theme }) => theme.spacing(8.75)};
  width: calc(${({ width }) => width}px - 1px);
  background-color: ${({ theme, pdf }) =>
    pdf ? theme.palette.custom.athensGrey : theme.palette.custom.whiteLink};
`;
