import { useTranslation } from 'react-i18next';
import { useWebsocketContext } from 'hooks';
import { IuseVisibilityControl } from 'utils/interface';

import { CardExpandable } from 'components/card/card-expandable';
import { CheckboxList } from 'components/list';
import { ClassTableExpanded, SensorsTable } from 'components/table';

import { StyledSidebar } from './sidebar.styled';

export const Sidebar = ({ visibility, handleClick }: IuseVisibilityControl) => {
  const { t } = useTranslation('sidebar');
  const { classesData, sensorsData } = useWebsocketContext();

  return (
    <StyledSidebar>
      {sensorsData && sensorsData?.length > 0 ? (
        <CardExpandable
          header={t('sensors_header')}
          subheader={t('sensors_subheader')}
          visibility={visibility}
          handleClick={handleClick}
          item={
            <CheckboxList
              data={sensorsData}
              listName={'selectedSensorList'}
              listTitle={t('sensors_header')}
            />
          }
          itemExpanded={
            <SensorsTable data={sensorsData} listName={'selectedSensorList'} />
          }
        />
      ) : null}

      {classesData && classesData?.length > 0 ? (
        <CardExpandable
          header={t('classes_header')}
          subheader={t('classes_expanded_table_subheader')}
          visibility={visibility}
          handleClick={handleClick}
          item={
            <CheckboxList
              data={classesData}
              listName={'selectedClassList'}
              listTitle={t('classes_header')}
            />
          }
          itemExpanded={
            <ClassTableExpanded
              data={classesData}
              listName={'selectedClassList'}
            />
          }
        />
      ) : null}
    </StyledSidebar>
  );
};
