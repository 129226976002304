import { useCallback, useState } from 'react';
import { CartesianGrid } from 'recharts';
import { isObject } from 'utils/helpers/is-object';
import { CartesianGridRefType } from 'utils/interface';

import { useResize } from '../resize/use-resize.hook';

export const useGanttChart = () => {
  const [ganttWidth, setGanttWidth] = useState('100%');
  const { windowSize } = useResize();

  const cartesianGridRef = useCallback(
    (e: CartesianGridRefType) => {
      if (
        isObject(e, 'props') &&
        !!windowSize &&
        e instanceof CartesianGrid &&
        e.props.width
      ) {
        setGanttWidth(e.props.width.toString());
      }
    },
    [windowSize],
  );

  return {
    cartesianGridRef,
    ganttWidth,
  };
};
