import { useContext } from 'react';
import { LoaderContext } from 'context/loader';

export const useLoaderContext = () => {
  const context = useContext(LoaderContext);

  if (context === null) {
    throw new Error('useLoaderContext must be used within a useLoaderProvider');
  }
  return context;
};
