import { useState } from 'react';
import { IoContractSharp, IoExpandSharp } from 'react-icons/io5';
import { ICardExpandableProps } from 'utils/interface';

import { Title } from 'components/title';

import {
  StyledCardExpandable,
  StyledCardExpandableButton,
  StyledCardExpandedContent,
} from './card-expandable.styled';

export const CardExpandable = ({
  header,
  subheader,
  handleClick,
  visibility,
  item,
  itemExpanded,
}: ICardExpandableProps) => {
  const [expanded, setExpanded] = useState(false);

  const handleSetExpanded = () => {
    handleClick();
    setExpanded(!expanded);
  };

  return visibility || (!visibility && expanded) ? (
    <StyledCardExpandable expanded={+expanded}>
      <StyledCardExpandableButton onClick={handleSetExpanded}>
        {!expanded ? <IoExpandSharp /> : <IoContractSharp />}
      </StyledCardExpandableButton>
      <Title title={header} subTitle={expanded ? subheader : undefined} />
      <StyledCardExpandedContent>
        {!expanded ? item : itemExpanded}
      </StyledCardExpandedContent>
    </StyledCardExpandable>
  ) : null;
};
