import { Modal } from 'components/modal';
import { ReportPeriods } from 'components/report-periods';

export const ReportPeriodsModal = ({ open, onClose, data }: any) => {
  return (
    <Modal
      open={open}
      onCloseModal={onClose}
      onClose={onClose}
      aria-labelledby="modal-report-periods"
      aria-describedby="modal-report-periods"
    >
      <ReportPeriods close={onClose} data={data} />
    </Modal>
  );
};
