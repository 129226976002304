import dayjs from 'dayjs';
import { useHookFormContext } from 'hooks';
import { CHARTS, PERCENTAGES } from 'utils/const';
import { IClass, IGanttChart } from 'utils/interface';

import { SubTitle } from 'components/subtitle';

import {
  StyledGanttChartContent,
  StyledGanttChartWrapper,
} from './gantt-chart.styled';
import { GanttChartRow } from './gantt-chart-row';
import { GanttChartRulers } from './gantt-chart-rulers';

const { X_AXIS_TICK_COUNT } = CHARTS;
const { THOUSAND } = PERCENTAGES;

export const GanttChart = ({
  data,
  width,
  pdf,
  noiseLimit,
  dates,
}: IGanttChart) => {
  const { dateFrom, dateTo } = dates;
  const startDate = +dayjs(dateFrom);
  const endDate = +dayjs(dateTo);
  const dateRange = (endDate - startDate) / THOUSAND;

  return (
    <StyledGanttChartWrapper>
      <StyledGanttChartContent width={width} pdf={pdf}>
        <SubTitle text="Classes" />

        {data.map((item: IClass) => (
          <GanttChartRow
            key={item.id}
            item={item}
            dateRange={dateRange}
            noiseLimit={noiseLimit}
          />
        ))}

        <GanttChartRulers
          startDate={startDate}
          endDate={endDate}
          dateRange={dateRange}
          ticks={X_AXIS_TICK_COUNT}
          pdf={pdf}
        />
      </StyledGanttChartContent>
    </StyledGanttChartWrapper>
  );
};
