import { RadioGroup, styled, TextField } from '@mui/material';

export const StyledCalendarButtonsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const StyeldCalendarInputsBox = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const StyledCalendarInput = styled(TextField)`
  margin: ${({ theme }) => theme.spacing(2, 0)};
  min-width: ${({ theme }) => theme.spacing(33)};

  input {
    padding: ${({ theme }) => theme.spacing(1, 2)};
  }
`;

export const StyledCalendarInputsContainer = styled('div')`
  label {
    top: ${({ theme }) => theme.spacing(-1)};
  }

  .MuiInputLabel-shrink {
    top: ${({ theme }) => theme.spacing(0)};
  }

  .Mui-error {
    fieldset {
      border-color: ${({ theme }) => theme.palette.error.main}!important;
    }
  }
`;

export const StyledCalendarTimePicker = styled('div')`
  .PrivatePickersSlideTransition-root {
    min-height: ${({ theme }) => theme.spacing(27.5)};
    min-width: ${({ theme }) => theme.spacing(20)};
  }

  .MuiPickerStaticWrapper-root {
    div {
      padding-top: 0;
    }
  }

  button {
    border-radius: 20%;
  }

  button:focus {
    background-color: ${({ theme }) => theme.palette.primary.main}!important;
  }
`;

export const StyledCalendarRadioGroup = styled(RadioGroup)`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;
