import dayjs from 'dayjs';
import { FORMAT } from 'utils/const';
import { getUniqueClasses } from 'utils/helpers';
import { IGetPDFDataProps } from 'utils/interface/pdf-data';

const { DATE_TIME } = FORMAT;

export const getPDFData = (data: IGetPDFDataProps) => {
  const { global_measurements, sensors } = data;
  const { start_date, end_date, average_noise, noise_limit } =
    global_measurements;
  const classes = getUniqueClasses(sensors);

  return {
    ...data,
    global_measurements: {
      ...global_measurements,
      start_date: dayjs(start_date).format(DATE_TIME),
      end_date: dayjs(end_date).format(DATE_TIME),
      average_noise: Math.round(average_noise),
      noise_limit: Math.round(noise_limit),
    },
    classes: classes,
  };
};
