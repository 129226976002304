import { IClass } from 'utils/interface';

export const getClassTableDataMapper = (data?: IClass[]) => {
  if (!data) return;

  return data.map((item: IClass) => {
    const { measurements } = item;
    const avgNoise = Math.round(measurements?.average_noise);
    const partDba = Math.round(measurements?.part_dba);
    const partPerc = Math.round(measurements?.part_percent * 10) / 10;

    return {
      ...item,
      measurements: {
        ...measurements,
        average_noise:
          !Number.isNaN(avgNoise) && avgNoise !== 0 ? avgNoise : undefined,
        part_dba: !Number.isNaN(partDba) && partDba !== 0 ? partDba : undefined,
        part_percent: !Number.isNaN(partPerc) ? partPerc : undefined,
      },
    };
  });
};
