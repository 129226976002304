import { IClass, ISensor } from 'utils/interface';

export const getCheckboxesDataMapperReportPeriods = (
  data: IClass[] | ISensor[],
  formData: any,
) =>
  data.map((item: IClass | ISensor) => {
    const { measurements, id } = item;
    const avgNoise = Math.round(measurements?.average_noise);
    let partDba;
    let status;

    if ('measurements' in item && 'part_dba' in measurements) {
      const { part_dba } = measurements;
      partDba = Math.round(part_dba);
    }

    if ('available' in item) {
      const { available } = item;
      status = available;
    }

    return {
      ...item,
      available: status,
      isChecked: formData[id] === true ? true : false,
      disabled: status !== undefined && !status,
      measurements: {
        ...measurements,
        average_noise: !Number.isNaN(avgNoise) ? avgNoise : undefined,
        part_dba: !Number.isNaN(partDba) ? partDba : undefined,
      },
      show_label: !!avgNoise && status,
    };
  });
