import { ITruthyItemsProps } from 'utils/interface';

export const getTruthyItems = (items: ITruthyItemsProps) => {
  const result: string[] = [];

  for (const [key, value] of Object.entries(items)) {
    if (value) {
      result.push(key);
    }
  }
  return { result };
};
