import { Card } from '@mui/material';
import { useModal } from 'hooks';
import { TABS } from 'utils/const';

import { ReportPeriodsModal } from 'components/modal';
import { ReportingPeriodsTable, ReportsTable } from 'components/table';
import { Tabs } from 'components/tabs';

const { REPORTING } = TABS;

export const ReportsView = () => {
  const { openModal, data, handleOpenModal, handleCloseModal } = useModal();

  return (
    <Card>
      <Tabs name="Reports" headers={REPORTING}>
        <ReportsTable />
        <ReportingPeriodsTable openModalEdit={handleOpenModal} />
      </Tabs>
      {openModal && (
        <ReportPeriodsModal
          open={openModal}
          onClose={handleCloseModal}
          data={data}
        />
      )}
    </Card>
  );
};
