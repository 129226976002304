import { Divider } from '@mui/material';
import { useCalendar } from 'hooks/calendar/use-calendar.hook';

import { CalendarButtons } from './calendar-buttons';
import { CalendarDateRange } from './calendar-date-range';
import { CalendarInputs } from './calendar-inputs';
import { CalendarRadio } from './calendar-radio';

export const Calendar = () => {
  const {
    dateRange,
    timeFrom,
    timeTo,
    dateFrom,
    dateTo,
    checked,
    timeError,
    handleTimeRadio,
    handleTimePickerFrom,
    handleTimePickerTo,
    handleDateRangePicker,
    handleSubmitCalendarModal,
  } = useCalendar();

  return (
    <>
      <CalendarInputs
        title="From:"
        dateError={timeError?.dateFromError}
        timeError={timeError?.timeFromError}
        timePickerValue={timeTo}
        handleTimePicker={handleTimePickerTo}
        textFieldValue={dateTo}
      />
      <CalendarInputs
        title="To:"
        dateError={timeError?.dateToError}
        timeError={timeError?.timeToError}
        timePickerValue={timeFrom}
        handleTimePicker={handleTimePickerFrom}
        textFieldValue={dateFrom}
      />
      <CalendarDateRange
        dateRange={dateRange}
        handleDateRangePicker={handleDateRangePicker}
      />
      <Divider>or</Divider>
      <CalendarRadio checked={checked} handleTimeRadio={handleTimeRadio} />
      <CalendarButtons handleSubmitCalendarModal={handleSubmitCalendarModal} />
    </>
  );
};
