import { HTTP } from 'utils/const';
import {
  IGetReportingPeriods,
  IGetReportingPeriodsData,
} from 'utils/interface';

import { AxiosInstance } from '../axios-instance';

const {
  ENDPOINTS: { REPORTING_PERIODS },
} = HTTP;

export const getReportingPeriods = async (
  offset: number,
  limit: number,
): Promise<IGetReportingPeriods> => {
  // TODO: delete when backend provide data
  const arr: IGetReportingPeriodsData[] = [];
  const data = {
    length: 40,
    data: arr,
  };

  [...Array(limit)].forEach((e, i = offset) =>
    data.data.push({
      id: ++offset,
      start_date: '2023-01-05T08:00:00',
      end_date: '2023-01-05T10:00:00',
      hours: '6:00 - 12:00',
      data_interval: 30,
      noise_limit: 50,
      week_days: [1, 2, 4, 6],
      report_interval: 2,
      sensors: [1, 3, 5, 6, 7, 8, 10, 15],
      classes: [1, 2, 3, 4],
    }),
  );

  return await data;

  // TODO uncomment when backend provide data
  // return await AxiosInstance.get(REPORTING_PERIODS, {
  //   data: {
  //     offset: offset,
  //     limit: limit,
  //   },
  // })
  //   .then(res => res.data)
  //   .then(data => data);
};
