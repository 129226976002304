import { useEffect, useState } from 'react';

export const useLoader = () => {
  const [loading, setLoading] = useState(false);
  const toggleLoader = (state: boolean) => setLoading(state);

  useEffect(() => {
    loading
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = '');
  }, [loading]);

  return { loading, toggleLoader };
};
