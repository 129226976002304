import { Button } from '@mui/material';
import { useHookFormContext } from 'hooks';
import { ICalendarButtons } from 'utils/interface';

import { StyledCalendarButtonsContainer } from '../calendar.styled';

export const CalendarButtons = ({
  handleSubmitCalendarModal,
}: ICalendarButtons) => {
  const { handleCloseCalendarModal } = useHookFormContext();
  return (
    <StyledCalendarButtonsContainer>
      <Button variant="contained" fullWidth onClick={handleSubmitCalendarModal}>
        Apply
      </Button>
      <Button fullWidth onClick={handleCloseCalendarModal}>
        Cancel
      </Button>
    </StyledCalendarButtonsContainer>
  );
};
