import { memo } from 'react';
import { MenuItem } from '@mui/material';
import { PAGINATION_SELECT } from 'utils/const';
import { IPaginationSelect } from 'utils/interface';

import {
  StyledPaginationContainerOptions,
  StyledPaginationSelect,
} from '../pagination.styled';

const { TEN, ONE_HUNDRED, TWENTY, FIFTY } = PAGINATION_SELECT;

export const PaginationSelect = memo(
  ({ handleChangeRowsPerPage, rowsPerPage }: IPaginationSelect) => (
    <StyledPaginationContainerOptions>
      <p>Rows per page</p>
      <StyledPaginationSelect
        name="page-size"
        id="page-size"
        onChange={handleChangeRowsPerPage}
        value={rowsPerPage}
      >
        <MenuItem value={TEN}>{TEN}</MenuItem>
        <MenuItem value={TWENTY}>{TWENTY}</MenuItem>
        <MenuItem value={FIFTY}>{FIFTY}</MenuItem>
        <MenuItem value={ONE_HUNDRED}>{ONE_HUNDRED}</MenuItem>
      </StyledPaginationSelect>
    </StyledPaginationContainerOptions>
  ),
);
