import dayjs from 'dayjs';
import { FORMAT } from 'utils/const';
import { IGetReportingPeriods, IGetReports } from 'utils/interface';

const { DATE, TIME } = FORMAT;

const getReportDates = (date: string) => {
  const dateFormatted = `${dayjs(date).format(DATE)}`;
  const timeFormatted = `${dayjs(date).format(TIME)}`;

  return `${dateFormatted} ${timeFormatted}`;
};

export const getReportsDataMapper = (data: IGetReports) => {
  data.data.forEach(item => {
    const { report_date, start_date, end_date } = item;

    item.report_date = `${getReportDates(report_date)}`;
    item.start_date = `${getReportDates(start_date)}`;
    item.end_date = `${getReportDates(end_date)}`;
  });

  return data;
};

export const getReportingPeriodsDataMapper = (data: IGetReportingPeriods) => {
  data.data.forEach(item => {
    const { start_date, end_date } = item;

    item.start_date = `${getReportDates(start_date)}`;
    item.end_date = `${getReportDates(end_date)}`;
  });

  return data;
};
