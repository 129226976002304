import { ILabelData } from 'utils/interface';

import { LabelDataContainer, StyledLabelData } from './label-data-styled';

export const LabelData = ({ label, data }: ILabelData) => (
  <LabelDataContainer>
    {label}
    <StyledLabelData>{data}</StyledLabelData>
  </LabelDataContainer>
);
