import { IReportPeriodsLabel } from 'utils/interface';

import {
  StyledReportPeriodsLabelContainer,
  StyledReportPeriodsLabelData,
} from '../report-periods.styled';

export const ReportPeriodsLabel = ({ text, data }: IReportPeriodsLabel) => (
  <StyledReportPeriodsLabelContainer>
    {text}
    <StyledReportPeriodsLabelData>{data}</StyledReportPeriodsLabelData>
  </StyledReportPeriodsLabelContainer>
);
