import { TFunction } from 'i18next';
import { ROUTES } from 'utils/const';

const { SENSORS, MAP, REPORTS } = ROUTES;

export const navigationRoutes = (t: TFunction) => [
  {
    link: SENSORS,
    name: t('sensor'),
    id: 1,
  },
  {
    link: MAP,
    name: t('map'),
    id: 2,
  },
  {
    link: REPORTS,
    name: t('reporting'),
    id: 3,
  },
];
