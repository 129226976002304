import { ISensor } from 'utils/interface';

export const getSensorsTableDataMapper = (
  data: ISensor[] | null,
  liveData: ISensor[] | null,
) => {
  if (!data) return;

  return data.map((item: ISensor) => {
    const { available, id, measurements } = item;
    const avgNoise = Math.round(measurements?.average_noise);
    const sensor = liveData?.find(elem => elem.id === id);
    const currentNoise = sensor && Math.round(sensor.current_noise_level);

    return {
      ...item,
      current_noise_level:
        !Number.isNaN(currentNoise) && available ? currentNoise : undefined,
      disabled: !available,
      measurements: {
        ...measurements,
        average_noise: !Number.isNaN(avgNoise) ? avgNoise : undefined,
      },
    };
  });
};
