import { Box } from '@mui/material';
import { ICheckboxListItemLabelProps } from 'utils/interface';

import { StatusIcon } from 'components/status';

import {
  StyledCheckboxListLabel,
  StyledCheckboxListLabelPrimary,
  StyledCheckboxListLabelSecondary,
} from './checkbox-list-item-label.styled';

export const CheckboxListItemLabel = ({
  name,
  noiseLevel,
  noiseLevelLabel,
}: ICheckboxListItemLabelProps) => {
  return (
    <StyledCheckboxListLabel>
      <Box>
        {noiseLevelLabel ? (
          <>
            <StyledCheckboxListLabelPrimary>
              {name}
            </StyledCheckboxListLabelPrimary>

            <StyledCheckboxListLabelSecondary>
              Avr: {noiseLevel} dBA
            </StyledCheckboxListLabelSecondary>
          </>
        ) : (
          <span>{name}</span>
        )}
      </Box>

      <StatusIcon noiseLevel={noiseLevel} />
    </StyledCheckboxListLabel>
  );
};
