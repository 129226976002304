import { IPDFTemplateProps } from 'utils/interface';

import { StyledPDFTemplateDocument } from './pdf-template.styled';
import {
  PDFTemplatePageMap,
  PDFTemplatePageSensor,
  PDFTemplatePageSensors,
  PDFTemplatePageSummary,
} from './pdf-template-page';

export const PDFTemplate = ({
  data,
  lineChart,
  ganttChart,
  sensors,
  map,
}: IPDFTemplateProps) => (
  <StyledPDFTemplateDocument>
    <PDFTemplatePageSummary data={data} />
    <PDFTemplatePageSensors
      lineChart={lineChart}
      ganttChart={ganttChart}
      data={data.global_measurements}
    />
    <PDFTemplatePageMap map={map} />

    {sensors &&
      sensors.map(({ average_noise, id, name, lineChart, ganttChart }) => (
        <PDFTemplatePageSensor
          key={id}
          averageNoise={average_noise}
          name={name}
          lineChart={lineChart}
          ganttChart={ganttChart}
        />
      ))}
  </StyledPDFTemplateDocument>
);
