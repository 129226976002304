import { Page } from '@react-pdf/renderer';
import { IPDFTemplatePageSummaryProps } from 'utils/interface';

import { StyledPDFTemplatePageContent } from '../../pdf-template.styled';
import { PDFTemplateFooter } from '../../pdf-template-footer';
import { PDFTemplateHeader } from '../../pdf-template-header';

import { PDFTemplatePageSummaryData } from './pdf-template-page-summary-data';
import { PDFTemplatePageSummaryDetails } from './pdf-template-page-summary-details';

export const PDFTemplatePageSummary = ({
  data: { global_measurements, sensors, classes },
}: IPDFTemplatePageSummaryProps) => (
  <Page size="A4" orientation="portrait">
    <PDFTemplateHeader />

    <StyledPDFTemplatePageContent>
      <PDFTemplatePageSummaryData data={global_measurements} />
      <PDFTemplatePageSummaryDetails sensors={sensors} classes={classes} />
    </StyledPDFTemplatePageContent>

    <PDFTemplateFooter>Summary - Data, Details</PDFTemplateFooter>
  </Page>
);
