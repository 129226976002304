import { Checkbox } from '@mui/material';
import { useCheckbox, useHookFormContext } from 'hooks';
import { getCheckedAllStatus, getCheckedStatus } from 'utils/helpers';
import { ICheckboxListProps } from 'utils/interface';
import { getCheckboxesDataMapper } from 'utils/mapper';

import {
  StyledCheckboxListFormControlLabel,
  StyledCheckboxListScrollableWrapper,
  StyledCheckboxListStack,
} from './checkbox-list.styled';
import { CheckboxListItemLabel } from './checkbox-list-item-label';

export const CheckboxList = (props: ICheckboxListProps) => {
  const { data, listName } = props;
  const { updateData } = useHookFormContext();
  const { selectedItems, title, onSelectAllChange, register } =
    useCheckbox(props);
  const checkboxData = getCheckboxesDataMapper(data);

  return (
    <StyledCheckboxListStack role="group">
      <StyledCheckboxListFormControlLabel
        label={<CheckboxListItemLabel name={`Select all ${title}`} />}
        control={
          <Checkbox
            id={listName}
            color="success"
            onChange={onSelectAllChange}
            checked={getCheckedAllStatus(selectedItems)}
          />
        }
      />

      <StyledCheckboxListScrollableWrapper>
        {checkboxData.map(
          ({
            disabled,
            id,
            measurements: { average_noise, part_dba },
            name,
            show_label,
          }) => {
            const idString = id.toString();

            return (
              <StyledCheckboxListFormControlLabel
                hidden={disabled}
                key={idString}
                label={
                  <CheckboxListItemLabel
                    name={name}
                    noiseLevel={part_dba ? part_dba : average_noise}
                    noiseLevelLabel={show_label}
                  />
                }
                control={
                  <Checkbox
                    id={idString}
                    color="success"
                    {...register(`${listName}.${id}`, {
                      value: !disabled,
                      disabled: disabled,
                      onChange: updateData,
                    })}
                    checked={getCheckedStatus(selectedItems, idString)}
                  />
                }
              />
            );
          },
        )}
      </StyledCheckboxListScrollableWrapper>
    </StyledCheckboxListStack>
  );
};
