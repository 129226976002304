import { Image, View } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import { theme } from 'context/theme/theme';

export const StyledPDFTemplateHeader = styled(View)`
  display: flex;
  justify-content: center;
  padding-left: ${() => theme.spacing(2.5)};
  padding-right: ${() => theme.spacing(2.5)};
  margin-bottom: ${() => theme.spacing(2)};
  height: ${() => theme.spacing(7)};
  background-color: ${() => theme.palette.primary.dark};
`;

export const StyledPDFTemplateLogo = styled(Image)`
  width: ${() => theme.spacing(9)};
  height: auto;
`;
