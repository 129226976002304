import { IPDFTemplatePageSummaryDataProps } from 'utils/interface';

import {
  StyledPDFTemplateFlex,
  StyledPDFTemplateHeading,
  StyledPDFTemplateLabeledData,
  StyledPDFTemplateSection,
  StyledPDFTemplateStrong,
} from '../pdf-template-page.styled';

export const PDFTemplatePageSummaryData = ({
  data: { start_date, end_date, interval, average_noise, noise_limit },
}: IPDFTemplatePageSummaryDataProps) => (
  <StyledPDFTemplateSection>
    <StyledPDFTemplateHeading>Data</StyledPDFTemplateHeading>

    <StyledPDFTemplateLabeledData>
      Data from:{' '}
      <StyledPDFTemplateStrong>{`${start_date} - ${end_date}`}</StyledPDFTemplateStrong>
    </StyledPDFTemplateLabeledData>

    <StyledPDFTemplateFlex>
      <StyledPDFTemplateLabeledData>
        Interval:{' '}
        <StyledPDFTemplateStrong>{`${interval}`}</StyledPDFTemplateStrong>
      </StyledPDFTemplateLabeledData>

      <StyledPDFTemplateLabeledData>
        Noise limit:{' '}
        <StyledPDFTemplateStrong>
          {noise_limit > 0 ? `${noise_limit} dBA` : 'Not set'}
        </StyledPDFTemplateStrong>
      </StyledPDFTemplateLabeledData>
    </StyledPDFTemplateFlex>

    <StyledPDFTemplateLabeledData>
      Average noise for all sensors and classes:{' '}
      <StyledPDFTemplateStrong>{`${average_noise} dBA`}</StyledPDFTemplateStrong>
    </StyledPDFTemplateLabeledData>
  </StyledPDFTemplateSection>
);
