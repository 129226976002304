import { Button, styled } from '@mui/material';

export const StyledLimitbarContainer = styled('div')`
  display: flex;
  margin: ${({ theme }) => theme.spacing(3)} auto;
  width: 100%;
  max-width: ${({ theme }) => theme.spacing(165)};
  justify-content: space-between;
  padding-left: ${({ theme }) => theme.spacing(42)};
`;

export const StyledLimitbarDataContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.palette.text.secondary};
  align-items: center;
`;

export const StyledLimitbarFlexContainer = styled('div')`
  display: flex;
`;

export const StyledLimitbarButtonContainer = styled(
  StyledLimitbarFlexContainer,
)`
  gap: ${({ theme }) => theme.spacing(1)};
  padding: 4px 0;

  svg {
    margin-right: 4px;
    font-size: ${({ theme }) => theme.typography.customFontSize.medium};
  }
`;

export const StyledLimitbarSecondDataContainer = styled(
  StyledLimitbarFlexContainer,
)`
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const StyledLimitbarSpan = styled('span')`
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.customFontSize.medium};
`;

export const StyledLimitbarButtonRestoreButton = styled(Button)`
  text-decoration: underline;
`;
