import { useCallback, useState } from 'react';

export const useModal = () => {
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState();

  const handleOpenModal = useCallback((data?: any) => {
    setData(data);
    setOpenModal(true);
  }, []);

  const handleCloseModal = useCallback(() => setOpenModal(false), []);

  return {
    data,
    openModal,
    handleOpenModal,
    handleCloseModal,
  };
};
