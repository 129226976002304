import { CircularProgress, styled } from '@mui/material';

export const StyledLoaderWrapper = styled('div')`
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.custom.loaderBG};
  font-size: ${({ theme }) => theme.typography.customFontSize.large};
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const StyledSpinner = styled(CircularProgress)`
  margin-right: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.palette.custom.orangeDark};
`;
