import { styled } from '@mui/material';
import { STATUS } from 'utils/const';
import { ISGanttChartItemProps } from 'utils/interface';

export const StyledGanttChartRow = styled('div')`
  position: relative;
  flex: none;
  display: block;
  margin: ${({ theme }) => theme.spacing(1.5, 2.5, 1.5, 'auto')};
  width: 100%;
  height: ${({ theme }) => theme.spacing(1.25)};
  padding-bottom: ${({ theme }) => theme.spacing(4)};
  font-size: ${({ theme }) => theme.typography.customFontSize.small};

  > div:first-of-type {
    padding-left: ${({ theme }) => theme.spacing(1.25)};
  }
`;

export const StyledGanttChartItem = styled('div')<ISGanttChartItemProps>`
  position: absolute;
  z-index: 1;
  left: ${({ leftPos }) => leftPos}%;
  right: ${({ rightPos }) => rightPos}%;
  bottom: 0;
  width: auto;
  min-width: ${({ theme }) => theme.spacing(0.125)};
  height: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ status, theme }) =>
    status === STATUS.OVER_LIMIT
      ? theme.palette.error.main
      : status === STATUS.ACCEPTABLE
      ? theme.palette.warning.main
      : status === STATUS.NORMAL
      ? theme.palette.success.main
      : theme.palette.info.main};
`;
