import { UseFormReturn } from 'react-hook-form';
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs from 'dayjs';
import { FORMAT } from 'utils/const';

import { ReportPeriodsTimeFrameButtons } from './report-periods-time-frame-buttons';
import { ReportPeriodsTimeFrameDateRange } from './report-periods-time-frame-date-range';
import { ReportPeriodsTimeFrameInputs } from './report-periods-time-frame-inputs';

const { DATE } = FORMAT;

export const ReportPeriodsTimeFrame = ({
  methods,
  handleConfirmReportPeriodsTimeFrameModal,
  handleCloseReportPeriodsTimeFrameModal,
}: {
  methods: UseFormReturn<any, any>;
  handleConfirmReportPeriodsTimeFrameModal: () => void;
  handleCloseReportPeriodsTimeFrameModal: () => void;
}) => {
  const handleDateRangePicker = (value: DateRange<dayjs.Dayjs>) => {
    const dateForTimeFrame = `${value[0]?.format(DATE) ?? DATE} - ${
      value[1]?.format(DATE) ?? DATE
    }`;

    methods.setValue('dateRange', value);
    methods.setValue('dateFrom', value[0]?.format(DATE) ?? DATE);
    methods.setValue('dateTo', value[1]?.format(DATE) ?? DATE);
    methods.setValue('timeFrame', dateForTimeFrame);
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <ReportPeriodsTimeFrameInputs
          title="From:"
          methods={methods}
          name="dateFrom"
        />
        <ReportPeriodsTimeFrameInputs
          title="To:"
          methods={methods}
          name="dateTo"
        />
      </div>
      <ReportPeriodsTimeFrameDateRange
        methods={methods}
        handler={handleDateRangePicker}
      />
      <ReportPeriodsTimeFrameButtons
        handleConfirmReportPeriodsTimeFrameModal={
          handleConfirmReportPeriodsTimeFrameModal
        }
        handleCloseReportPeriodsTimeFrameModal={
          handleCloseReportPeriodsTimeFrameModal
        }
      />
    </>
  );
};
