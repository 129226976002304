import { MdClose, MdDone } from 'react-icons/md';
import { Alert, Snackbar } from '@mui/material';
import { useToastContext } from 'hooks';
import { TOAST, VARIOUS_TIMES } from 'utils/const';

const { TO_CLOSE_TOAST } = VARIOUS_TIMES;
const { SUCCESS } = TOAST;

export const Toast = ({ autoHideDuration = TO_CLOSE_TOAST }) => {
  const { isOpen, text, severity, handleClose } = useToastContext();
  const icon = severity === SUCCESS ? <MdDone /> : <MdClose />;

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      onClick={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert
        onClose={undefined}
        severity={severity}
        icon={icon}
        variant="filled"
        elevation={6}
      >
        {text}
      </Alert>
    </Snackbar>
  );
};
