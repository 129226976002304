import { useState } from 'react';
import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs, { Dayjs } from 'dayjs';
import { useHookFormContext, useToastContext } from 'hooks';
import { FORMAT, VARIOUS_TIMES } from 'utils/const';
import { getInterval } from 'utils/data-access/axios';
import { IuseCalendarError } from 'utils/interface';

const { ONE_HOUR, ONE_DAY, CORRECT_TIME_LENGTH, DEFAULT_TIME } = VARIOUS_TIMES;
const { TIME, DATE } = FORMAT;

export const useCalendar = () => {
  const { errorToast } = useToastContext();
  const {
    handleCloseCalendarModal,
    methods: { setValue, getValues },
  } = useHookFormContext();
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>(
    getValues('calendarDate.dateRange') || [null, null],
  );

  const [timeFrom, setTimeFrom] = useState<Dayjs | null>(
    getValues('calendarDate.timeFrom') || DEFAULT_TIME,
  );
  const [timeTo, setTimeTo] = useState<Dayjs | null>(
    getValues('calendarDate.timeTo') || DEFAULT_TIME,
  );
  const [timeError, setTimeError] = useState<IuseCalendarError | null>(null);
  const [checked, setChecked] = useState(0);

  const handleTimePickerFrom = (value: dayjs.Dayjs | null) => {
    setChecked(0);
    if (!value) return;
    setTimeError({ ...timeError, timeToError: true });
    if (value.format(TIME).length === CORRECT_TIME_LENGTH) {
      setTimeError(null);
      setTimeFrom(value);
    }
  };
  const handleTimePickerTo = (value: dayjs.Dayjs | null) => {
    setChecked(0);
    if (!value) return;
    setTimeError({ ...timeError, timeFromError: true });
    if (value.format(TIME).length === CORRECT_TIME_LENGTH) {
      setTimeError(null);
      setTimeTo(value);
    }
  };
  const handleDateRangePicker = (value: DateRange<dayjs.Dayjs>) => {
    setTimeError(null);
    setChecked(0);
    setDateRange(value);
  };

  const handleTimeRadio = (time: number) => {
    setTimeError(null);
    setChecked(time);
    if (time === ONE_HOUR * ONE_DAY) {
      setTimeFrom(dayjs());
      setTimeTo(dayjs().subtract(1, 'd'));
      setDateRange([dayjs().subtract(1, 'd'), dayjs()]);
      return;
    }
    if (dayjs().subtract(time, 'm').hour() > dayjs().hour()) {
      setTimeFrom(dayjs());
      setTimeTo(dayjs().subtract(time, 'm'));
      setDateRange([dayjs().subtract(1, 'd'), dayjs()]);
      return;
    }
    setTimeFrom(dayjs());
    setTimeTo(dayjs().subtract(time, 'm'));
    setDateRange([dayjs(), dayjs()]);
  };

  const handleSubmitCalendarModal = () => {
    const dateFromToTimeFrame = `${dateRange[0]?.format(DATE)} ${timeTo?.format(
      TIME,
    )}`;
    const dateToToTimeFrame = `${dateRange[1]?.format(DATE)} ${timeFrom?.format(
      TIME,
    )}`;

    if (!dateRange[0]) {
      if (!dateRange[1]) {
        return setTimeError({
          ...timeError,
          dateFromError: true,
          dateToError: true,
        });
      }
      return setTimeError({ ...timeError, dateFromError: true });
    }
    if (!dateRange[1]) return setTimeError({ ...timeError, dateToError: true });
    if (!timeFrom) return setTimeError({ ...timeError, timeFromError: true });

    if (!timeTo) return setTimeError({ ...timeError, timeToError: true });

    if (
      !dateRange[0]?.diff(dateRange[1]) &&
      timeFrom.hour() <= timeTo.hour() &&
      timeFrom.minute() <= timeTo.minute()
    ) {
      return setTimeError({
        ...timeError,
        timeFromError: true,
        timeToError: true,
      });
    }

    if (timeError === null) {
      setValue('timeFrame', `${dateFromToTimeFrame} - ${dateToToTimeFrame}`);
      setValue('calendarDate', { dateRange, timeFrom, timeTo });
      setValue('liveMode', false);
      getInterval(dayjs(dateFromToTimeFrame), dayjs(dateToToTimeFrame))
        .then(data => setValue('interval', data))
        .catch(() => errorToast('Try again'));
      handleCloseCalendarModal();
    }
  };

  return {
    dateRange,
    timeFrom,
    timeTo,
    dateFrom: dateRange[1]?.format(DATE) ?? DATE,
    dateTo: dateRange[0]?.format(DATE) ?? DATE,
    checked,
    timeError,
    handleTimeRadio,
    handleTimePickerFrom,
    handleTimePickerTo,
    handleDateRangePicker,
    handleSubmitCalendarModal,
  };
};
