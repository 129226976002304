import { useEffect, useState } from 'react';
import { socketClient } from 'utils/data-access/socket-client/socket-client-instance';
import { isEqual } from 'utils/helpers';
import { IClass, IGlobalMeasurements, ISensor } from 'utils/interface';

export const useSocketGlobal = () => {
  const [sensorsData, setSensorsData] = useState<ISensor[]>([]);
  const [classesData, setClassesData] = useState<IClass[]>([]);
  const [globalData, setGlobalData] = useState<IGlobalMeasurements | null>(
    null,
  );

  useEffect(() => {
    socketClient.on('update', data => {
      if (data.global_measurements) {
        setGlobalData(prevState => {
          if (!isEqual(prevState, data.global_measurements)) {
            return data.global_measurements;
          }

          return prevState;
        });

        setSensorsData(prevState => {
          if (!isEqual(prevState, data.sensors)) {
            return data.sensors;
          }

          return prevState;
        });

        setClassesData(prevState => {
          if (!isEqual(prevState, data.classes)) {
            return data.classes;
          }

          return prevState;
        });
      }
    });
  }, []);

  return { classesData, globalData, sensorsData };
};
