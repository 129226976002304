import { useHookFormContext } from 'hooks';

import { Modal } from 'components/modal';

import { FiltersAndReport } from '../filters-and-report';

export const FiltersAndReportModal = () => {
  const { openModal, handleCloseModal } = useHookFormContext();
  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      onCloseModal={handleCloseModal}
      aria-labelledby="modal-filters"
      aria-describedby="modal-filters"
    >
      <FiltersAndReport />
    </Modal>
  );
};
