import { styled } from '@mui/material';

export const StyledLabelData = styled('span')`
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: 600;
  font-size: ${({ theme }) => theme.typography.customFontSize.medium};
`;

export const LabelDataContainer = styled('div')`
  color: ${({ theme }) => theme.palette.text.secondary};
  display: flex;
  flex-direction: column;
  border-left: ${({ theme }) =>
    `${theme.spacing(0.25)} solid ${theme.palette.custom.lineChartLine}`};
  padding-left: ${({ theme }) => theme.spacing(1)};
  padding-right: ${({ theme }) => theme.spacing(4)};
`;
