import { useCallback, useEffect, useState } from 'react';
import { socketClient } from 'utils/data-access/socket-client/socket-client-instance';
import { ISetParamsMessage, IUseFilterProps } from 'utils/interface';

export const useSocket = () => {
  const [isConnected, setIsConnected] = useState(socketClient.connected);
  const [isDataUpdated, setIsDataUpdated] = useState(false);

  const sendParams = useCallback((data: IUseFilterProps) => {
    socketClient.emit('setParams', data, (e: ISetParamsMessage) => {
      setIsDataUpdated(true);
    });
  }, []);

  const handleSetIsDataUpdated = useCallback(() => setIsDataUpdated(false), []);

  useEffect(() => {
    socketClient.on('connect', () => {
      setIsConnected(true);
    });

    socketClient.on('disconnect', () => {
      setIsConnected(false);
    });

    return () => {
      socketClient.off('connect');
      socketClient.off('disconnect');
    };
  }, []);

  return {
    isDataUpdated,
    isConnected,
    handleSetIsDataUpdated,
    sendParams,
  };
};
