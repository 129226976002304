import { useState } from 'react';
import { Tab } from '@mui/material';
import { ITabsProps } from 'utils/interface';

import { TabPanel } from './tab-panel/tab-panel';
import { StyledTabs } from './tabs.styled';

export const Tabs = ({ children, name, headers }: ITabsProps) => {
  const [value, setValue] = useState(0);

  const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <StyledTabs value={value} aria-label={name} onChange={handleChangeTabs}>
        {headers.map(header => (
          <Tab key={header} label={header} />
        ))}
      </StyledTabs>

      {children.map((child, index) => (
        <TabPanel key={index} value={value} index={index}>
          {child}
        </TabPanel>
      ))}
    </>
  );
};
