import arrowIcon from 'assets/icons/arrow.svg';
import { fabric } from 'fabric';
import { MAP } from 'utils/const';

const {
  ARROW: { SCALE_X, SCALE_Y, BASE_ROTATE_SVG, RADIANS_VALUE },
} = MAP;

const sinCalculationForLeftArrow = (
  left: number,
  angle: number,
  distanceFromMiddle: number,
) =>
  left +
  distanceFromMiddle -
  Number(
    Math.sin((angle - BASE_ROTATE_SVG) * (Math.PI / RADIANS_VALUE)).toFixed(2),
  ) *
    50;

const cosCalculationForLeftArrow = (
  left: number,
  angle: number,
  distanceFromMiddle: number,
) =>
  left +
  distanceFromMiddle +
  Number(Math.cos(angle * (Math.PI / RADIANS_VALUE)).toFixed(2)) * 50;

const sinCalculationForTopArrow = (
  top: number,
  angle: number,
  distanceFromMiddle: number,
) =>
  top -
  Number(Math.sin(angle * (Math.PI / RADIANS_VALUE)).toFixed(2)) *
    distanceFromMiddle;

const additionalSinCalculationForTopArrow = (
  top: number,
  angle: number,
  distanceFromMiddle: number,
) =>
  top -
  distanceFromMiddle -
  Number(Math.sin(angle * (Math.PI / RADIANS_VALUE)).toFixed(2)) * 80;

export const setArrowOnMap = (
  left: number,
  top: number,
  name: string,
  noiseDirection: number,
  editor?: fabric.Canvas,
) =>
  fabric.Image.fromURL(arrowIcon, img => {
    img.set({
      left: calculateLeftForArrow(left, noiseDirection),
      top: calculateTopForArrow(top, noiseDirection),
      scaleX: SCALE_X,
      scaleY: SCALE_Y,
      angle: -noiseDirection - BASE_ROTATE_SVG,
      name,
    });
    editor?.add(img);
    return false;
  });

export const calculateLeftForArrow = (left: number, angle: number) => {
  if (angle <= -170) return left - angle / 18 - 5;
  if (angle < -150) return left - angle / 18 - 10;
  if (angle < -130) return left - angle / 18 - 15;
  if (angle <= -90) return left - angle / 18 - 20;

  if (angle < 0 && angle >= -90) {
    if (angle <= -80) return left - angle / 18 - 20;
    if (angle < -60) return left - angle / 18 - 15;
    if (angle <= -40) return left - angle / 18 - 10;
    if (angle === -30) return left - angle / 18;
    if (angle < -30) return left - angle / 18 - 5;
    if (angle < -10) return left - angle / 18 + 5;
    return left - angle / 18 + 10;
  }

  if (angle === 180) {
    return left + 5;
  }

  if (angle > 135) {
    return sinCalculationForLeftArrow(left, angle, 20);
  }

  if (angle > 115) {
    return sinCalculationForLeftArrow(left, angle, 15);
  }

  if (angle > 90) {
    return sinCalculationForLeftArrow(left, angle, 25);
  }

  if (angle >= 60 && angle <= 90)
    return cosCalculationForLeftArrow(left, angle, 30);

  if (angle > 1) {
    return cosCalculationForLeftArrow(left, angle, angle / 1.25);
  }

  return left + angle + 15;
};

export const calculateTopForArrow = (top: number, angle: number) => {
  if (angle <= -180) return top + angle / 6 + 15;

  if (angle < -115) return top + angle / 6 + 20;
  if (angle < -90) return top + angle / 6 + 25;

  if (angle < -80) return top - angle / 6;
  if (angle < -70) return top - angle / 6 + 5;
  if (angle < -65) return top - angle / 6 + 10;
  if (angle <= -60) return top - angle / 6 + 15;
  if (angle <= -50) return top - angle / 6 + 20;
  if (angle < -30) return top - angle / 6 + 25;
  if (angle <= -20) return top - angle / 6 + 30;

  if (angle < 0) {
    return top - angle / 6 + 35;
  }

  if (angle === 180) {
    return additionalSinCalculationForTopArrow(top, angle, 15);
  }

  if (angle >= 170) {
    return additionalSinCalculationForTopArrow(top, angle, 10);
  }

  if (angle >= 135) {
    return sinCalculationForTopArrow(top, angle, 80);
  }

  if (angle >= 130) {
    return sinCalculationForTopArrow(top, angle, 75);
  }

  if (angle >= 125) {
    return sinCalculationForTopArrow(top, angle, 70);
  }

  if (angle >= 120) {
    return sinCalculationForTopArrow(top, angle, 65);
  }

  if (angle >= 115) {
    return sinCalculationForTopArrow(top, angle, 60);
  }

  if (angle >= 110) {
    return sinCalculationForTopArrow(top, angle, 55);
  }

  if (angle > 100) {
    return sinCalculationForTopArrow(top, angle, 50);
  }

  if (angle > 90) {
    return sinCalculationForTopArrow(top, angle, 45);
  }

  if (angle > 60) {
    return sinCalculationForTopArrow(top, angle, 40);
  }

  return top - angle + 33;
};
