import { useNoiseViolationLimits } from 'hooks';
import { STATUS } from 'utils/const';
import { StyledMdError, StyledMdWarning } from 'utils/icons';

const { OVER_LIMIT, ACCEPTABLE } = STATUS;

export const StatusIcon = ({ noiseLevel }: { noiseLevel?: number }) => {
  const { status } = useNoiseViolationLimits();
  if (!noiseLevel) return null;

  if (status(noiseLevel) === ACCEPTABLE) {
    return <StyledMdWarning />;
  }

  if (status(noiseLevel) === OVER_LIMIT) {
    return <StyledMdError />;
  }

  return null;
};
