import { FormControlLabel, Radio } from '@mui/material';
import { VARIOUS_TIMES } from 'utils/const';
import { ICalendarRadio } from 'utils/interface';

import { StyledCalendarRadioGroup } from '../calendar.styled';

const { FIVE_MINUTES, ONE_HOUR, ONE_DAY, ONE_SHIFT } = VARIOUS_TIMES;

export const CalendarRadio = ({ handleTimeRadio, checked }: ICalendarRadio) => (
  <StyledCalendarRadioGroup
    aria-labelledby="radio-buttons-group-calendar-label"
    name="radio-buttons-group-calendar"
    onChange={(e, v) => handleTimeRadio(Number(v))}
  >
    <FormControlLabel
      value={FIVE_MINUTES}
      control={<Radio />}
      label="Last 5 min"
      checked={checked === FIVE_MINUTES}
    />
    <FormControlLabel
      value={ONE_HOUR * ONE_SHIFT}
      control={<Radio />}
      label="Last 8 hours"
      checked={checked === ONE_HOUR * ONE_SHIFT}
    />
    <FormControlLabel
      value={ONE_HOUR * ONE_DAY}
      control={<Radio />}
      label="Last day"
      checked={checked === ONE_HOUR * ONE_DAY}
    />
  </StyledCalendarRadioGroup>
);
