import { memo } from 'react';
import { useZoom } from 'hooks/chart';
import {
  CartesianGrid,
  Label,
  Line,
  LineChart as RechartsLineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { colors } from 'styles/colors';
import { fontSize } from 'styles/font';
import { CHARTS } from 'utils/const';
import { getDividedNumbers } from 'utils/helpers/get-divided-numbers';
import { ILineChartProps } from 'utils/interface';

import { LineChartTick } from './line-chart-tick/line-chart-tick';
import { StyledLineChartWrapper } from './line-chart.styled';
import { LineChartTooltip } from './line-chart-tooltip';
// import { ActionBarChart } from './line-chart-action-bar';

const {
  X_AXIS_TICK_COUNT,
  Y_AXIS_MIN,
  Y_AXIS_MAX,
  Y_AXIS_TICKS_WITH_BOUNDARIES,
} = CHARTS;

export const LineChart = memo(
  ({
    data,
    cartesianGridRef,
    height,
    noiseLimit,
    pdf,
    yMax,
    yMin,
    liveMode,
  }: ILineChartProps) => {
    const { containerRef, onMouseMove, onMouseDown, onMouseUp, xPadding } =
      useZoom();

    const start = data[0]?.ts;
    const end = data[data.length - 1]?.ts;
    const maxLimit =
      noiseLimit === 0 || data.every(elem => elem.value === null)
        ? null
        : noiseLimit;
    const yAxisMax = () => {
      if (!yMax) return Math.max(...data.map(item => item.value)) + Y_AXIS_MAX;
      if (!pdf && liveMode) {
        return Math.max(...data.map(item => item.value)) + Y_AXIS_MAX;
      }
      return yMax;
    };
    const yAxisMin = liveMode && !pdf ? Y_AXIS_MIN : yMin ? yMin : Y_AXIS_MIN;
    const { result: ticks } = getDividedNumbers(start, end, X_AXIS_TICK_COUNT);

    return (
      <StyledLineChartWrapper zoomed={xPadding} pdf={pdf}>
        {/* <ActionBarChart onZoom={zoom} /> */}

        <ResponsiveContainer width="100%" height={height} ref={containerRef}>
          <RechartsLineChart
            data={data}
            onMouseMove={onMouseMove}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            margin={{ top: 60, right: 0, left: 0, bottom: 20 }}
          >
            <CartesianGrid
              ref={cartesianGridRef}
              fill={colors['line-chart']['cartesian-grid-fill']}
              stroke={colors['line-chart']['cartesian-grid-stroke']}
              strokeDasharray="4 12"
            />

            <XAxis
              dataKey="ts"
              type={'number'}
              domain={[start, end]}
              interval={0}
              ticks={ticks}
              tick={<LineChartTick axis="x" />}
              tickSize={0}
              allowDataOverflow={true}
              stroke={colors['line-chart']['axis']}
              padding={{ left: xPadding[0], right: xPadding[1] }}
            />

            <YAxis
              dataKey={yAxisMax()}
              type={'number'}
              domain={[yAxisMin, yAxisMax()]}
              interval={0}
              tick={<LineChartTick axis="y" />}
              tickCount={yAxisMax() / Y_AXIS_TICKS_WITH_BOUNDARIES}
              tickSize={0}
              stroke={colors['line-chart']['axis']}
            >
              <Label
                value="[dBA]"
                offset={20}
                position="top"
                fill={colors['line-chart']['label']}
                fontSize={fontSize.xSmall}
                fontWeight="500"
                style={{ transform: 'translateX(4px)' }}
              />
            </YAxis>

            <Tooltip content={<LineChartTooltip />} />

            <Line
              dataKey="value"
              type="step"
              dot={false}
              isAnimationActive={false}
              stroke={colors['line-chart']['line']}
              strokeWidth={2}
            />
            {maxLimit ? (
              <ReferenceLine
                y={maxLimit}
                stroke={colors['line-chart']['reference-line']}
                strokeDasharray="3 0"
                strokeWidth={2}
              />
            ) : null}
          </RechartsLineChart>
        </ResponsiveContainer>
      </StyledLineChartWrapper>
    );
  },
);
