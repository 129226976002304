import { TableBody, TableCell, TableRow } from '@mui/material';
import { IReportsTableBody } from 'utils/interface';

import { ReportTableActions } from '../reports-table-actions/reports-table-actions';

export const ReportsTableBody = ({ data }: IReportsTableBody) => {
  return (
    <TableBody>
      {data.map(item => {
        const {
          id,
          report_date,
          start_date,
          end_date,
          alerts,
          type,
          sensors,
          classes,
        } = item;
        return (
          <TableRow key={id}>
            <TableCell>{id}</TableCell>
            <TableCell>{report_date}</TableCell>
            <TableCell>{start_date}</TableCell>
            <TableCell>{end_date}</TableCell>
            <TableCell align="right">{alerts}</TableCell>
            <TableCell>{type}</TableCell>
            <TableCell align="right">{sensors.length}</TableCell>
            <TableCell align="right">{classes.length}</TableCell>
            <TableCell>
              <ReportTableActions id={id} data={item} download view />
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};
