import { STATUS } from 'utils/const';
import { ISensorsTableStatus } from 'utils/interface';

import { ErrorLabel, SuccessLabel } from '../status-label.styled';

const { DISABLED, ENABLED } = STATUS;

export const StatusSensors = ({ status }: ISensorsTableStatus) =>
  status ? (
    <SuccessLabel>{ENABLED}</SuccessLabel>
  ) : (
    <ErrorLabel>{DISABLED}</ErrorLabel>
  );
