import { fabric } from 'fabric';
import { colors } from 'styles/colors';
import { MAP } from 'utils/const';

const {
  CIRCLE: { RADIUS, STROKE_WIDTH },
} = MAP;

export const setCircleOnMap = (left: number, top: number, id: string) =>
  new fabric.Circle({
    radius: RADIUS,
    left,
    top,
    strokeWidth: STROKE_WIDTH,
    fill: colors.map['circle-fill'],
    stroke: colors.map['circle-border'],
    name: id,
  });
