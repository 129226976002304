import { useTranslation } from 'react-i18next';
import { useHookFormContext } from 'hooks';
import { convertTime } from 'utils/helpers';

import { LabelData } from 'components/label-data';

import {
  StyledLimitbarContainer,
  StyledLimitbarDataContainer,
} from './limitbar.styled';
import { LimitbarButtons } from './limitbar-buttons';

export const Limitbar = () => {
  const {
    data: { interval, dateFrom, dateTo, noiseLimit, liveMode },
  } = useHookFormContext();
  const { t } = useTranslation('limitbar');

  return (
    <StyledLimitbarContainer>
      <StyledLimitbarDataContainer>
        <LabelData
          label={t('timeframe')}
          data={liveMode ? 'Live - last 5 minutes' : `${dateFrom} - ${dateTo}`}
        />
        <LabelData label={t('interval')} data={`${convertTime(interval)}`} />
        <LabelData
          label={t('noise_limit')}
          data={`${noiseLimit === 0 ? 'Not set' : noiseLimit + ' dBA'}`}
        />
      </StyledLimitbarDataContainer>
      <LimitbarButtons />
    </StyledLimitbarContainer>
  );
};
