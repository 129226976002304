import { WEEK_DAYS } from 'utils/const';

export const getReportRecurrencyMapper = (days: number[], weeks: number) => {
  let recurrency;

  if (days.length === 7) {
    recurrency = 'Daily';
  } else if (weeks === 1) {
    recurrency = `Weekly (${days.map(day => WEEK_DAYS[day]).join('; ')})`;
  } else {
    recurrency = `Every ${weeks} weeks (${days
      .map(day => WEEK_DAYS[day])
      .join('; ')})`;
  }

  return recurrency;
};
