import ReactDOM from 'react-dom';
import { useHookFormContext } from 'hooks';
import { getPDFMapMapper } from 'utils/mapper/get-pdf-chart.mapper';

import { Map } from 'components/map';

import { StyledPDFMapGeneratorWrapper } from './pdf-map-generator.styled';

export const PDFMapGenerator = ({ data }: { data: any }) => {
  const { isDataUpdated, handleSetIsDataUpdated } = useHookFormContext();
  const excludeData = getPDFMapMapper(data);
  const mapData = { ...excludeData, isDataUpdated, handleSetIsDataUpdated };
  return (
    <>
      {ReactDOM.createPortal(
        <StyledPDFMapGeneratorWrapper id="pdf-map-generator">
          <Map data={mapData} pdf />
        </StyledPDFMapGeneratorWrapper>,
        document.querySelector('#root') as HTMLElement,
      )}
    </>
  );
};
