export const LinkOrange = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 11.5002C0 10.9935 0.215632 10.5814 0.48 10.5814C0.744992 10.5814 0.96 10.9923 0.96 11.5002V13.3426C0.96 13.8493 0.744368 14.2614 0.48 14.2614C0.215008 14.2614 0 13.8506 0 13.3426V11.5002ZM3.84 9.54432C3.84 9.10096 4.05563 8.74143 4.32 8.74143C4.58499 8.74143 4.8 9.10093 4.8 9.54432V14.3785C4.8 14.8219 4.58437 15.1814 4.32 15.1814C4.05501 15.1814 3.84 14.8219 3.84 14.3785V9.54432ZM7.68 6.7299C7.68 6.31623 7.89563 5.98143 8.16 5.98143C8.42499 5.98143 8.64 6.31332 8.64 6.7299V17.193C8.64 17.6066 8.42437 17.9414 8.16 17.9414C7.89501 17.9414 7.68 17.6095 7.68 17.193V6.7299ZM11.52 1.22709C11.52 0.803844 11.7356 0.461426 12 0.461426C12.265 0.461426 12.48 0.804841 12.48 1.22709V22.6958C12.48 23.119 12.2644 23.4614 12 23.4614C11.735 23.4614 11.52 23.118 11.52 22.6958V1.22709ZM15.36 3.98909C15.36 3.56482 15.5756 3.22143 15.84 3.22143C16.105 3.22143 16.32 3.56484 16.32 3.98909V20.8538C16.32 21.278 16.1044 21.6214 15.84 21.6214C15.575 21.6214 15.36 21.278 15.36 20.8538V3.98909ZM19.2 8.59009C19.2 8.16582 19.4156 7.82143 19.68 7.82143C19.945 7.82143 20.16 8.16485 20.16 8.59009V16.2528C20.16 16.677 19.9444 17.0214 19.68 17.0214C19.415 17.0214 19.2 16.678 19.2 16.2528V8.59009ZM23.04 11.5002C23.04 10.9935 23.2556 10.5814 23.52 10.5814C23.785 10.5814 24 10.9923 24 11.5002V13.3426C24 13.8493 23.7844 14.2614 23.52 14.2614C23.255 14.2614 23.04 13.8506 23.04 13.3426V11.5002Z"
      fill="#FF5B22"
    />
  </svg>
);
