import { PERCENTAGES } from 'utils/const';
import { getDividedNumbers } from 'utils/helpers/get-divided-numbers';
import { IGanttChartRulersProps } from 'utils/interface';

import {
  StyledGanttChartRuler,
  StyledGanttChartRulers,
} from './gantt-chart-rulers.styled';

const { THOUSAND } = PERCENTAGES;

export const GanttChartRulers = ({
  startDate,
  endDate,
  dateRange,
  ticks,
  pdf,
}: IGanttChartRulersProps) => {
  const { result: rulers } = getDividedNumbers(startDate, endDate, ticks);

  const calculateOffset = (ruler: number) => {
    return ((ruler - startDate) / (dateRange * THOUSAND)) * 100;
  };

  return (
    <StyledGanttChartRulers>
      {rulers.map((ruler: number, index) => (
        <StyledGanttChartRuler
          key={index}
          offset={calculateOffset(ruler)}
          pdf={pdf}
        />
      ))}
    </StyledGanttChartRulers>
  );
};
