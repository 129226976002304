import map from 'assets/img/map2.png';
import { fabric } from 'fabric';

export const setMap = (
  handleSetMapIsReady: () => void,
  editorMemo: fabric.Canvas,
  size?: number[],
  pdf?: boolean,
) => {
  size &&
    fabric.Image.fromURL(map, function (oImg) {
      oImg.scaleToWidth(size[0]);
      oImg.scaleToHeight(size[1]);
      oImg.set({
        left: 0,
        top: 0,
        selectable: false,
        name: map,
      });

      if (pdf) {
        const center = editorMemo.getCenter();
        oImg.set({
          left: center.left,
          top: center.top,
          selectable: false,
          name: map,
          originX: 'center',
          originY: 'center',
        });
      }

      editorMemo.setBackgroundImage(oImg, () => {
        editorMemo.renderAll();
        handleSetMapIsReady();
      });
    });
};
