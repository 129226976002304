import { ITile } from 'utils/interface';

import { StyledTileCard } from '../tiles.style';

export const Tile = ({ value, text }: ITile) => (
  <StyledTileCard>
    <span>{value}</span>
    <p>{text}</p>
  </StyledTileCard>
);
