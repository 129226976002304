import { Button } from '@mui/material';

import { StyledCalendarButtonsContainer } from 'components/calendar/calendar.styled';

export const ReportPeriodsTimeFrameButtons = ({
  handleCloseReportPeriodsTimeFrameModal,
  handleConfirmReportPeriodsTimeFrameModal,
}: any) => {
  return (
    <StyledCalendarButtonsContainer style={{ marginTop: '15px' }}>
      <Button
        variant="contained"
        fullWidth
        onClick={handleConfirmReportPeriodsTimeFrameModal}
      >
        Apply
      </Button>
      <Button fullWidth onClick={handleCloseReportPeriodsTimeFrameModal}>
        Cancel
      </Button>
    </StyledCalendarButtonsContainer>
  );
};
