import { MdDelete, MdDownload, MdEdit, MdSearch } from 'react-icons/md';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useModal, useReportActions } from 'hooks';
import { IReportTableActionsProps } from 'utils/interface';

import { DropdownActions } from 'components/dropdown';
import { ModalConfirm } from 'components/modal';
import { PDFGanttChartGenerator, PDFLineChartGenerator } from 'components/pdf';
import { PDFMapGenerator } from 'components/pdf/pdf-map-generator';

export const ReportTableActions = ({
  download,
  edit,
  id,
  remove,
  view,
  data,
}: IReportTableActionsProps) => {
  const {
    onDownload,
    onEdit,
    onRemove,
    onView,
    lineChartPDFData,
    ganttChartPDFData,
    mapPDFData,
    getDataForPrepareRaport,
  } = useReportActions(data);
  const { openModal, handleOpenModal, handleCloseModal } = useModal();

  return (
    <>
      <DropdownActions id={id} getData={getDataForPrepareRaport}>
        {edit ? (
          <MenuItem onClick={() => onEdit(edit)}>
            <ListItemIcon>
              <MdEdit />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
        ) : null}

        {remove ? (
          <MenuItem onClick={handleOpenModal}>
            <ListItemIcon>
              <MdDelete />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        ) : null}

        {view && data ? (
          <MenuItem onClick={() => onView(data)}>
            <ListItemIcon>
              <MdSearch />
            </ListItemIcon>
            <ListItemText>Show in sensors view</ListItemText>
          </MenuItem>
        ) : null}

        {download ? (
          <MenuItem onClick={() => onDownload(id)}>
            <ListItemIcon>
              <MdDownload />
            </ListItemIcon>
            <ListItemText>Download PDF</ListItemText>

            {lineChartPDFData && (
              <PDFLineChartGenerator data={lineChartPDFData} />
            )}

            {ganttChartPDFData && (
              <PDFGanttChartGenerator data={ganttChartPDFData} />
            )}

            {mapPDFData && <PDFMapGenerator data={mapPDFData} />}
          </MenuItem>
        ) : null}
      </DropdownActions>

      {remove && (
        <ModalConfirm
          title="Confirmation"
          open={openModal}
          onConfirm={() => onRemove(id)}
          onClose={() => handleCloseModal()}
        >
          Are you sure you want to delete this reporting period?
        </ModalConfirm>
      )}
    </>
  );
};
