import { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { useDefaultValues, useSocket } from 'hooks';
import { useToastContext } from 'hooks';
import { FORMAT, MODAL_TYPE, REGEX } from 'utils/const';
import { postGenerateReport } from 'utils/data-access';
import { IUseFilterProps, ModalType } from 'utils/interface';
import { getApiData, getApiReportDataMapper } from 'utils/mapper';
import { filterSchema } from 'utils/schemas';

const { CHECK_FOR_TIMEFRAME } = REGEX;
const { DATE, TIME } = FORMAT;

export const useFilter = () => {
  const defaultValue = useDefaultValues();
  const { isDataUpdated, sendParams, handleSetIsDataUpdated, isConnected } =
    useSocket();
  const { successToast, errorToast } = useToastContext();
  const [updatedDefaultValues, setUpdatedDefaultValues] = useState(false);
  const [readyToReconnect, setReadyToReconnect] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState<IUseFilterProps>(defaultValue);
  const [openCalendarModal, setOpenCalendarModal] = useState(false);
  const [modalType, setModalType] = useState<ModalType | null>(null);
  const isFilter = modalType === MODAL_TYPE.filter;

  const methods = useForm<IUseFilterProps>({
    defaultValues: data,
    resolver: yupResolver(filterSchema),
  });

  const submitRecipe: SubmitHandler<IUseFilterProps> = useCallback(
    (submitData: IUseFilterProps) => {
      if (isFilter) {
        methods.setValue('calendarDate', null);
        const newDate = submitData.timeFrame.match(CHECK_FOR_TIMEFRAME)!;
        const updatedData = {
          ...submitData,
          dateFrom: submitData.liveMode
            ? dayjs().subtract(5, 'm').format(`${DATE} ${TIME}`)
            : newDate[0],
          dateTo: submitData.liveMode
            ? dayjs().format(`${DATE} ${TIME}`)
            : newDate[1],
          noiseLimit: Number(submitData.noiseLimit),
        };

        methods.reset(updatedData);
        setData(updatedData);
        sendParams(getApiData(updatedData));
      } else {
        successToast('Your report has been generated');
      }

      setOpenModal(false);
    },
    [isFilter, methods, sendParams, successToast],
  );

  const handleOpenModal = useCallback((type: ModalType) => {
    setModalType(type);
    setOpenModal(true);
  }, []);

  const handleOpenCalendarModal = useCallback(() => {
    setOpenCalendarModal(true);
  }, []);

  const handleCloseCalendarModal = useCallback(() => {
    setOpenCalendarModal(false);
  }, []);

  const updateData = useCallback(() => {
    const apiData = getApiData(methods.getValues());

    setData(methods.getValues());
    sendParams(apiData);
  }, [methods, sendParams]);

  const sendReport = () => {
    return postGenerateReport(getApiData(getApiReportDataMapper(data)))
      .then(() => successToast('Report saved'))
      .catch(() => errorToast('Fail to generate report'));
  };

  const resetToDefault = useCallback(() => {
    methods.reset(defaultValue);
    setData(defaultValue);
    sendParams(getApiData(defaultValue));
    successToast('You managed to correctly restore the default values');
  }, [defaultValue, methods, sendParams, successToast]);

  const handleCloseModal = useCallback(() => {
    methods.reset(data);
    setOpenModal(false);
  }, [data, methods]);

  useEffect(() => {
    if (isConnected && readyToReconnect) {
      updateData();
    }
  }, [isConnected]);

  useEffect(() => {
    if (!updatedDefaultValues) {
      sendParams(getApiData(defaultValue));

      if (Object.keys(defaultValue.selectedSensorList).length > 0) {
        setUpdatedDefaultValues(true);
        setReadyToReconnect(true);
      }
    }
  }, [defaultValue, sendParams]);

  useEffect(() => {
    setData(defaultValue);
  }, [defaultValue]);

  return {
    data,
    methods,
    modalType,
    isFilter,
    openModal,
    openCalendarModal,
    isDataUpdated,
    handleSetIsDataUpdated,
    submitRecipe,
    handleOpenModal,
    handleCloseModal,
    updateData,
    resetToDefault,
    handleOpenCalendarModal,
    handleCloseCalendarModal,
    sendReport,
  };
};
