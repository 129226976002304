import { useReportPeriods } from 'hooks';

import { RaportPeriodsFirstPage } from './raport-periods-first-page';
import { RaportPeriodsSecondPage } from './raport-periods-second-page';

export const ReportPeriods = ({
  data,
  close,
}: {
  data?: any;
  close: () => void;
}) => {
  const {
    methodsReportPeriods,
    selectedSensors,
    selectedClasses,
    handleForwardPage,
    handleBackPage,
    submitRecipe,
  } = useReportPeriods(data, close);

  return methodsReportPeriods.watch('step') === 1 ? (
    <RaportPeriodsFirstPage
      selectedSensors={selectedSensors}
      selectedClasses={selectedClasses}
      methods={methodsReportPeriods}
      handleForwardPage={handleForwardPage}
    />
  ) : (
    <RaportPeriodsSecondPage
      data={data}
      selectedSensors={selectedSensors}
      selectedClasses={selectedClasses}
      handleBackPage={handleBackPage}
      submitRecipe={submitRecipe}
      methods={methodsReportPeriods}
    />
  );
};
