import { Controller } from 'react-hook-form';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StaticDateRangePicker from '@mui/lab/StaticDateRangePicker';
import { TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import { StyledCalendarTimePicker } from 'components/calendar/calendar.styled';

export const ReportPeriodsTimeFrameDateRange = ({ methods, handler }: any) => (
  <StyledCalendarTimePicker>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        control={methods.control}
        name="dateRange"
        render={({ field: { value } }) => (
          <StaticDateRangePicker
            displayStaticWrapperAs="desktop"
            defaultCalendarMonth={dayjs().subtract(1, 'month')}
            value={value}
            onChange={handler}
            renderInput={(startProps, endProps) => (
              <>
                <TextField {...endProps} />
                <TextField {...startProps} />
              </>
            )}
          />
        )}
      />
    </LocalizationProvider>
  </StyledCalendarTimePicker>
);
