import dayjs from 'dayjs';
import { FORMAT } from 'utils/const';
import { IUseFilterProps } from 'utils/interface';

const { DATE, TIME } = FORMAT;

export const getApiReportDataMapper = (data: IUseFilterProps) => {
  let newData = JSON.parse(JSON.stringify(data));

  if (newData.liveMode) {
    const dateFrom = dayjs().subtract(5, 'm').format(`${DATE} ${TIME}`);
    const dateTo = dayjs().format(`${DATE} ${TIME}`);

    delete newData.liveMode;

    return {
      ...newData,
      dateFrom: dateFrom,
      dateTo: dateTo,
      timeFrame: `${dateFrom} - ${dateTo}`,
    };
  }

  return data;
};
