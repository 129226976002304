import { Card } from '@mui/material';
import { useHookFormContext, useWebsocketContext } from 'hooks';
import { IVisibleContent } from 'utils/interface';

import { Map } from 'components/map';
import { Title } from 'components/title';

export const MapView = ({ visibility }: IVisibleContent) => {
  const {
    data: { noiseLimit },
    isDataUpdated,
    handleSetIsDataUpdated,
  } = useHookFormContext();
  const { sensorsData } = useWebsocketContext();

  const data = {
    noiseLimit,
    sensorsData,
    isDataUpdated,
    handleSetIsDataUpdated,
  };

  return visibility ? (
    <Card>
      <Title
        title="Sensors and classes placed on map"
        subTitle="Click on sensor to see more details"
      />
      <Map data={data} />
    </Card>
  ) : null;
};
