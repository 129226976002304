import { ITileAverageNoise } from 'utils/interface';

import { StatusIcon } from 'components/status';

import { StyledTileCard } from '../tiles.style';

export const TileAverageNoise = ({
  value,
  text,
  noiseLevel,
}: ITileAverageNoise) => (
  <StyledTileCard>
    <span>
      <StatusIcon noiseLevel={noiseLevel} />
      {value}
    </span>
    <p>{text}</p>
  </StyledTileCard>
);
