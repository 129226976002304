import { memo } from 'react';
import { IPaginationInput } from 'utils/interface';

import {
  StyledPaginationContainerOptions,
  StyledPaginationTextField,
} from '../pagination.styled';

export const PaginationInput = memo(
  ({
    page,
    handleChangePageSelect,
    handleOnKeyDown,
    results,
    rowsPerPage,
  }: IPaginationInput) => (
    <StyledPaginationContainerOptions>
      <p>Go to Page</p>
      <StyledPaginationTextField
        value={page}
        placeholder={`${Math.round(results / rowsPerPage)}`}
        onChange={handleChangePageSelect}
        onKeyDown={handleOnKeyDown}
        onFocus={e => e.target.select()}
      />
    </StyledPaginationContainerOptions>
  ),
);
