import { memo } from 'react';
import { useSocketSensors } from 'hooks';
import { IMapPopup } from 'utils/interface';
import { getSensorMapPopupData } from 'utils/mapper';

import { StatusIcon } from 'components/status';

import { StyledMapPopupContainer } from '../map.styled';

export const MapPopup = memo(({ sensor, turnOffSensor }: IMapPopup) => {
  const { sensorsData: liveData } = useSocketSensors();
  const { id, name, x, y, current_noise_level, measurements } =
    getSensorMapPopupData(sensor, liveData);
  return name &&
    x &&
    y &&
    current_noise_level &&
    measurements?.average_noise ? (
    <StyledMapPopupContainer onClick={turnOffSensor} x={x} y={y}>
      <StatusIcon noiseLevel={Math.round(measurements?.average_noise)} />
      <span>{name}</span>
      <span>Sensor ID: {id}</span>
      <span>Reading live: {Math.round(current_noise_level)} dBA</span>
    </StyledMapPopupContainer>
  ) : null;
});
