import { styled } from '@mui/material';
import { IMainProps } from 'utils/interface';

export const StyledMain = styled('main')<IMainProps>`
  position: relative;
  display: flex;
  gap: ${({ theme }) => theme.spacing(3)};
  width: 100%;
  max-width: ${({ theme, view }) =>
    view === 'basic' ? '100%' : theme.spacing(165)};
  min-height: calc(100vh - ${({ theme }) => theme.spacing(27.5)});
  margin: ${({ theme, view }) => (view === 'basic' ? 0 : theme.spacing(3))} auto;
`;
