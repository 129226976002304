import dayjs from 'dayjs';
import { getTruthyItems } from 'utils/helpers';
import { IUseFilterProps } from 'utils/interface';

export const getApiData = (params: IUseFilterProps) => {
  let newParams = JSON.parse(JSON.stringify(params));

  if (!params.liveMode) {
    newParams.window_start = dayjs(params.dateFrom);
    newParams.window_end = dayjs(params.dateTo);
  } else {
    newParams.live_mode = true;
  }

  newParams.sensors = getTruthyItems(params.selectedSensorList).result.map(
    item => +item,
  );

  newParams.classes = getTruthyItems(params.selectedClassList).result.map(
    item => +item,
  );

  delete newParams.liveMode;
  delete newParams.dateFrom;
  delete newParams.dateTo;
  delete newParams.selectedSensorList;
  delete newParams.selectedClassList;

  return newParams;
};
