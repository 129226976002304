/* eslint-disable unicorn/numeric-separators-style */
import { AlertColor } from '@mui/material';
import dayjs from 'dayjs';
import { colors } from 'styles/colors';
import { fontSize } from 'styles/font';

import {
  IClass,
  ISensor,
  IUseFilterProps,
  ModalType,
  ReportsDataType,
  ZoomType,
} from './interface';

export const MAP = {
  SCALE: 0.85,
  CIRCLE: {
    RADIUS: 7,
    STROKE_WIDTH: 4,
  },
  ARROW: {
    SCALE_X: 3,
    SCALE_Y: 3,
    BASE_ROTATE_SVG: 90,
    RADIANS_VALUE: 180,
  },
  INACTIVE_SENSOR: {
    GROUP: {
      LEFT_CENTER_POSITION: 23,
      TOP_CENTER_POSITION: 27,
    },
    BOX: {
      WIDTH: 64,
      HEIGHT: 28,
      RX: 5,
      RY: 5,
    },
    TEXT: {
      WIDTH: 150,
      FONT_SIZE: 14,
      FONT_WEIGHT: 400,
      LEFT: 5,
      TOP: 5,
    },
  },
  ACTIVE_SENSOR: {
    GROUP: {
      LEFT_CENTER_POSITION: 39,
      TOP_CENTER_POSITION: 45,
    },
    TEXT: {
      WIDTH: 150,
      FONT_SIZE: 16,
      FONT_WEIGHT: 500,
      LEFT: 35,
      TOP: 2,
    },
    SUBTEXT: {
      WIDTH: 150,
      FONT_SIZE: 14,
      FONT_WEIGHT: 400,
      LEFT: 34,
      TOP: 24,
    },
    BOX: {
      WIDTH: 95,
      HEIGHT: 46,
      RX: 5,
      RY: 5,
    },
  },
  FONT_FAMILY: 'Roboto',
  X_SET_ON_MAP: 100,
  Y_SET_ON_MAP: 100,
  Y_ROTATE: 100,
  SENSOR_LABEL_LEFT: 10,
  SENSOR_LABEL_TOP: 10,
};

export const ROUTES = {
  LOGIN: '/',
  SENSORS: '/sensors',
  MAP: '/map',
  REPORTS: '/reports',
};

export const DEFAULT_LOGIN_VALUE = {
  name: '',
  pass: '',
};

export const HTTP = {
  ENDPOINTS: {
    BASE_URL: process.env.REACT_APP_URL_BASE,
    INTERVAL: process.env.REACT_APP_URL_INTERVAL,
    GENERATE_REPORT: process.env.REACT_APP_URL_GENERATE_REPORT,
    REPORT: process.env.REACT_APP_URL_REPORT,
    REPORTS: process.env.REACT_APP_URL_REPORTS,
    REPORTING_PERIODS: process.env.REACT_APP_URL_REPORTING_PERIODS,
    SAVE_REPORTING_PERIOD: process.env.REACT_APP_URL_ADD_REPORTING_PERIOD,
    UPDATE_REPORTING_PERIOD: process.env.REACT_APP_URL_EDIT_REPORTING_PERIOD,
    DELETE_REPORTING_PERIOD: process.env.REACT_APP_URL_DELETE_REPORTING_PERIOD,
  },
  TIMEOUT: 3000,
};

export const WS_ENDPOINTS = {
  WS_PATH: process.env.REACT_APP_WEBSOCKET_PATH,
  WS_PROD: process.env.REACT_APP_WEBSOCKET_URL,
};

export const FORMAT = {
  TIME: 'HH:mm',
  TIME_WITH_SECONDS: 'HH:mm:ss',
  DATE: 'YYYY.MM.DD',
  DATE_TIME: 'YYYY.MM.DD HH:mm:ss',
  PDF_DATE_TIME: 'YYYY_MM_DD_HH_mm_ss',
};

export const WEEK_DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const TIME_PLACEHOLDER = '00:00';

export const VARIOUS_TIMES = {
  ONE_SEC_IN_MS: 1000,
  ONE_MIN_IN_SEC: 60,
  ONE_DAY_IN_SEC: 86400,
  FIVE_MINUTES: 5,
  ONE_HOUR: 60,
  ONE_SHIFT: 8,
  ONE_DAY: 24,
  CORRECT_TIME_LENGTH: 5,
  DEFAULT_TIME: dayjs().set('hour', 0).set('minute', 0),
  TO_CLOSE_TOAST: 5000,
};

export const PERCENTAGES = {
  HUNDRED: 100,
  THOUSAND: 1000,
};

export const STATUS = {
  ENABLED: 'enabled',
  DISABLED: 'disabled',
  NORMAL: 'normal',
  OVER_LIMIT: 'over limit',
  ACCEPTABLE: 'acceptable',
};

export const KEYBOARD = {
  ENTER: 'Enter',
};

export const PAGINATION_SELECT = {
  TEN: 10,
  TWENTY: 20,
  FIFTY: 50,
  ONE_HUNDRED: 100,
};

export const TABLE = {
  SENSORS: [
    'Sensor Name',
    'Average Noise',
    'Sensor ID',
    'Map Location',
    'Reading Live (dBA)',
    'Sensor Status',
  ],
  CLASSES: [
    'Class Name',
    'Average Noise',
    'Occurrence Time',
    'Part (dBA)',
    'Part (%)',
    'Noise Status',
  ],
  REPORTS: [
    'ID',
    'Report Date',
    'Date From',
    'Date To',
    'Alerts reported',
    'Type of Report',
    'Sensors',
    'Classes',
    'Actions',
  ],
  REPORTING_PERIODS: [
    'Report Start Date',
    'Report End Date',
    'Recurrency',
    'Sensors',
    'Classes',
    'Actions',
  ],
};

export const TABS = {
  REPORTING: ['Reports', 'Reporting periods'],
};

export const CHARTS = {
  TICK_STYLES: {
    fill: colors['grey-main'],
    fontSize: fontSize.xSmall,
  },
  X_AXIS_TICK_COUNT: 8,
  Y_AXIS_MIN: 20,
  Y_AXIS_MAX: 20,
  Y_AXIS_TICKS_WITH_BOUNDARIES: 9,
  ZOOM: 1,
};

export const REGEX = {
  CHECK_FOR_HOUR: /((\w{2}:\w{2})|(\w{4}\s-\s))/g,
  CHECK_FOR_TIMEFRAME: /[^-]+/g,
  CHECK_FOR_TIMEFRAME_REPORT_PERIODS: /-/g,
};

export const DEFAULT_REPORT_PERIODS_VALUE = (
  selectedClassList: IClass[] = [],
  selectedSensorList: ISensor[] = [],
) => {
  return {
    selectedClassList: selectedClassList.reduce(
      (obj, arr) => ({ ...obj, [arr.id]: true }),
      {},
    ),
    selectedSensorList: selectedSensorList.reduce(
      (obj, arr) => ({ ...obj, [arr.id]: arr.available }),
      {},
    ),
    step: 1,
    hoursFrom: 0,
    hoursTo: 0,
    noiseLimit: +process.env.REACT_APP_NOISE_LIMIT_VIOLATIONS_ERROR,
    dateTo: dayjs().format(`${FORMAT.DATE} ${FORMAT.TIME}`),
    dateFrom: dayjs().subtract(5, 'm').format(`${FORMAT.DATE} ${FORMAT.TIME}`),
    timeFrame: 'Please insert date',
    repeatForDays: [],
    repeatForWeeks: 1,
    dateRange: [null, null],
  };
};

export const DEFAULT_FILTERS_VALUE = (
  selectedClassList: IClass[] = [],
  selectedSensorList: ISensor[] = [],
): IUseFilterProps => {
  return {
    liveMode: true,
    dateTo: dayjs().format(`${FORMAT.DATE} ${FORMAT.TIME}`),
    dateFrom: dayjs().subtract(5, 'm').format(`${FORMAT.DATE} ${FORMAT.TIME}`),
    interval: +process.env.REACT_APP_INTERVAL,
    noiseLimit: +process.env.REACT_APP_NOISE_LIMIT_VIOLATIONS_ERROR,
    selectedClassList: selectedClassList.reduce(
      (obj, arr) => ({ ...obj, [arr.id]: true }),
      {},
    ),
    selectedSensorList: selectedSensorList.reduce(
      (obj, arr) => ({ ...obj, [arr.id]: arr.available }),
      {},
    ),
    alertsReported: false,
    typeOfReport: '',
    timeFrame: 'Live - last 5 minutes',
    calendarDate: null,
  };
};

export const TOAST: { SUCCESS: AlertColor; ERROR: AlertColor } = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export const MODAL_TYPE: { report: ModalType; filter: ModalType } = {
  report: 'report',
  filter: 'filter',
};

export const ZOOM_TYPE: { zoomIn: ZoomType; zoomOut: ZoomType } = {
  zoomIn: 'zoomIn',
  zoomOut: 'zoomOut',
};

export const REPORTS_DATA_TYPE: {
  reports: ReportsDataType;
  reportingPeriods: ReportsDataType;
} = {
  reports: 'reports',
  reportingPeriods: 'reportingPeriods',
};
