import { io } from 'socket.io-client';

function createWebSocketUrl() {
  let configured = process.env.REACT_APP_WEBSOCKET_URL;
  if (configured === undefined) {
    configured = '/';
  }

  const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
  const url = new URL(configured, window.location.href);
  url.protocol = protocol;

  return url.href;
}

export const socketClient = io(createWebSocketUrl(), {
  transports: ['websocket'],
  path: process.env.REACT_APP_WEBSOCKET_PATH,
  auth: { token: process.env.REACT_APP_TOKEN },
});
