import { WEEK_DAYS } from 'utils/const';

export const setDataFromAPIToModal = (data: any) => {
  const time = data.hours.match(/\d\d?:\d\d/g);
  const startData = data.start_date.match(/\d\d\d\d.\d\d.\d\d/g);
  const dateEnd = data.end_date.match(/\d\d\d\d.\d\d.\d\d/g);
  const repeatForDays = data.week_days.map((elem: any) => WEEK_DAYS[elem]);

  return {
    id: data.id,
    selectedClassList: data.classes.reduce(
      (a: any, v: any) => ({ ...a, [v]: true }),
      {},
    ),
    selectedSensorList: data.sensors.reduce(
      (a: any, v: any) => ({ ...a, [v]: true }),
      {},
    ),
    step: 1,
    hoursFrom: time[0],
    hoursTo: time[1],
    noiseLimit: data.noise_limit,
    dateFrom: startData[0],
    dateTo: dateEnd[0],
    timeFrame: `${startData[0]} - ${dateEnd[0]}`,
    repeatForWeeks: data.report_interval,
    dateRange: [null, null],
    repeatForDays,
  };
};
