import { HTTP } from 'utils/const';

import { AxiosInstance } from '../axios-instance';

const {
  ENDPOINTS: { UPDATE_REPORTING_PERIOD },
} = HTTP;

// TODO: change params/response type
export const updateReportingPeriod = async (params: any): Promise<any> =>
  await AxiosInstance.put(UPDATE_REPORTING_PERIOD, params)
    .then(res => res.data)
    .then(data => data);
