import { HTTP } from 'utils/const';

import { AxiosInstance } from '../axios-instance';

const {
  ENDPOINTS: { SAVE_REPORTING_PERIOD },
} = HTTP;

// TODO: change params type
export const saveReportingPeriod = async (params: any): Promise<number> =>
  await AxiosInstance.post(SAVE_REPORTING_PERIOD, params)
    .then(res => res.data)
    .then(data => data);
