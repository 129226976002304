import { Table } from '@mui/material';
import { useLoaderContext, usePagination, useReports } from 'hooks';
import { REPORTS_DATA_TYPE, TABLE } from 'utils/const';

import { Loader } from 'components/loader';
import { Pagination } from 'components/pagination';
import { Placeholder } from 'components/placeholder';
import { TableHead } from 'components/table';

import { ReportsTableBody } from './reports-table-body/reports-table-body';
import { StyledReportsTableContainer } from './reports-table.styled';

const { REPORTS } = TABLE;

export const ReportsTable = () => {
  const { loading } = useLoaderContext();
  const { reports, handleGetReports } = useReports(REPORTS_DATA_TYPE.reports);

  const {
    rowsPerPage,
    page,
    paginationPage,
    paginationLimit,
    handleChangePage,
    handleChangePageSelect,
    handleChangeRowsPerPage,
    handleOnKeyDown,
  } = usePagination(reports?.length, handleGetReports);

  return reports ? (
    <StyledReportsTableContainer>
      <Table>
        <TableHead rowTitles={REPORTS} />
        <ReportsTableBody data={reports.data} />
      </Table>

      <Loader loading={loading} fixed>
        Generating PDF
      </Loader>

      {reports.length > paginationLimit ? (
        <Pagination
          results={reports.length}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          paginationPage={paginationPage}
          page={page}
          handleChangePageSelect={handleChangePageSelect}
          handleOnKeyDown={handleOnKeyDown}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : null}
    </StyledReportsTableContainer>
  ) : (
    <Placeholder>
      No reports available.
      <br />
      Use "Generate report" option or configure automatic reports using
      "Reporting periods" tab
    </Placeholder>
  );
};
