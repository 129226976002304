import { useEffect, useRef, useState } from 'react';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import map from 'assets/img/map2.png';
import { useFabricJSEditor } from 'fabricjs-react';
import { useMap, useZoomLevel } from 'hooks';
import { ISensor } from 'utils/interface';

import {
  StyledFabricJSCanvas,
  StyledMapCanvasBackground,
  StyledMapCanvasContainer,
  StyledMapIconButtonMinus,
  StyledMapIconButtonPlus,
} from './map.styled';
import { MapDescription } from './map-description';
import { MapPopup } from './map-popup';

interface IMap {
  data: IDataMap;
  pdf?: boolean;
}

interface IDataMap {
  noiseLimit: number;
  sensorsData: ISensor[] | null;
  isDataUpdated: boolean;
  handleSetIsDataUpdated: () => void;
}

export const Map = ({ data, pdf = false }: IMap) => {
  const [size, setSize] = useState<number[] | undefined>();
  const [rerender, setRerender] = useState(false);
  const [rerenderCanvas, setRerenderCanvas] = useState(false);
  const canvasBackground = useRef<HTMLImageElement | null>(null);
  const { editor, onReady } = useFabricJSEditor();
  const { sensor, turnOffSensor } = useMap(data, pdf, editor, size);
  const { handleAddZoomLevel, handleOddZoomLevel } = useZoomLevel(editor);

  useEffect(() => {
    if (canvasBackground.current) {
      const { clientWidth, clientHeight } = canvasBackground.current;

      if (clientWidth > 100 && clientHeight > 100) {
        setSize([clientWidth, clientHeight]);
        setRerenderCanvas(true);
      } else {
        setRerender(!rerender);
        setRerenderCanvas(false);
      }
    }
  }, [canvasBackground, rerender]);
  return (
    <>
      {!pdf && sensor?.id ? (
        <MapPopup sensor={sensor} turnOffSensor={turnOffSensor} />
      ) : null}

      <StyledMapCanvasContainer id="canvas-wrapper">
        <StyledMapCanvasBackground src={map} alt="map" ref={canvasBackground} />

        {!pdf && (
          <>
            <StyledMapIconButtonPlus
              aria-label="zoom map in"
              onClick={handleAddZoomLevel}
              edge="end"
            >
              <AiOutlinePlus />
            </StyledMapIconButtonPlus>
            <StyledMapIconButtonMinus
              aria-label="zoom map out"
              onClick={handleOddZoomLevel}
              edge="end"
            >
              <AiOutlineMinus />
            </StyledMapIconButtonMinus>
          </>
        )}
        {rerenderCanvas && <StyledFabricJSCanvas onReady={onReady} />}
      </StyledMapCanvasContainer>
      <MapDescription />
    </>
  );
};
