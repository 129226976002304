import { View } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import { theme } from 'context/theme/theme';

export const StyledPDFTemplateFooter = styled(View)`
  padding-left: ${() => theme.spacing(2.5)};
  padding-right: ${() => theme.spacing(2.5)};
  margin-top: ${() => theme.spacing(2)};
`;

export const StyledPDFTemplateFooterContent = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: ${() => theme.spacing(1)};
  padding-bottom: ${() => theme.spacing(2)};
  border-top: 1px solid ${() => theme.palette.border.borderGreyLight};
  font-size: ${() => theme.spacing(1.5)};
  color: ${() => theme.palette.text.secondary};
`;
