import { HTTP } from 'utils/const';

import { AxiosInstance } from './axios-instance';

const {
  ENDPOINTS: { GENERATE_REPORT },
} = HTTP;

export const postGenerateReport = async (data: any): Promise<number> =>
  await AxiosInstance.post(GENERATE_REPORT, {
    data,
  })
    .then(res => res.data)
    .then(data => data);
