import { ChangeEvent, KeyboardEvent, useCallback, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { KEYBOARD, PAGINATION_SELECT } from 'utils/const';

const { TEN: paginationLimit } = PAGINATION_SELECT;
const { ENTER } = KEYBOARD;

export const usePagination = (
  max?: number,
  getData?: (offset: number, limit: number) => void,
) => {
  const [arrIndex, setArrIndex] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(paginationLimit);
  const [page, setPage] = useState(1);
  const [paginationPage, setPaginationPage] = useState(1);
  const getDataIsFunction = typeof getData === 'function';

  const handleChangePage = (event: unknown, newPage: number) => {
    if (getDataIsFunction) {
      getData((newPage - 1) * rowsPerPage, rowsPerPage);
    }

    setPaginationPage(newPage);
    setArrIndex(newPage - 1);
  };

  const handleChangePageSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const value = +event.target.value;

    if (value && max) {
      if (value > max / rowsPerPage) {
        setPage(Math.ceil(max / rowsPerPage));
      } else {
        setPage(value);
      }
    }
  };

  const handleChangeRowsPerPage = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      if (getDataIsFunction) {
        getData(0, Number(event.target.value));
      }

      setRowsPerPage(Number(event.target.value));
      setArrIndex(0);
      setPaginationPage(1);
    },
    [],
  );

  const handleOnKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === ENTER) {
        if (getDataIsFunction) {
          getData((page - 1) * rowsPerPage, rowsPerPage);
        }

        setArrIndex(page - 1);
        setPaginationPage(+page);
        (event.target as HTMLElement).blur();
      }
    },
    [page],
  );

  return {
    arrIndex,
    rowsPerPage,
    page,
    paginationPage,
    paginationLimit,
    sliceStart: arrIndex * rowsPerPage,
    sliceEnd: arrIndex * rowsPerPage + rowsPerPage,
    handleChangePage,
    handleChangePageSelect,
    handleChangeRowsPerPage,
    handleOnKeyDown,
  };
};
