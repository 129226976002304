import dayjs from 'dayjs';
import { CHARTS, FORMAT } from 'utils/const';
import { ILineChartTickProps } from 'utils/interface';

const { DATE, TIME_WITH_SECONDS } = FORMAT;
const { TICK_STYLES } = CHARTS;

export const LineChartTick = ({
  axis,
  index,
  payload,
  y,
  x,
}: ILineChartTickProps) => {
  if (!x || !y || !payload) return null;

  if (axis === 'x') {
    const firstTick = index === 0;
    const lastTick = index === 8;
    const timeLabelXOffset = 32;
    const timeLabelYOffset = 35;
    const dateLabelYOffset = 20;
    const date = dayjs(payload.value).format(DATE);
    const time = dayjs(payload.value).format(TIME_WITH_SECONDS);
    const dateAlign = firstTick ? 'start' : lastTick ? 'end' : 'middle';
    const timeX = firstTick
      ? x + timeLabelXOffset
      : lastTick
      ? x - timeLabelXOffset
      : x;

    return (
      <>
        {date && (
          <text
            x={x}
            y={y + dateLabelYOffset}
            textAnchor={dateAlign}
            style={TICK_STYLES}
          >
            {date}
          </text>
        )}

        {time && (
          <text
            x={timeX}
            y={y + timeLabelYOffset}
            textAnchor={'middle'}
            style={TICK_STYLES}
          >
            {time}
          </text>
        )}
      </>
    );
  } else {
    const dbaLabelXOffset = 10;
    const dbaLabelYOffset = 5;

    return (
      <text
        x={x - dbaLabelXOffset}
        y={y + dbaLabelYOffset}
        textAnchor={'end'}
        style={TICK_STYLES}
      >
        {payload.value}
      </text>
    );
  }
};
