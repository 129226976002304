import { styled } from '@mui/material';

export const StyledCheckboxListLabel = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: ${({ theme }) => theme.spacing(5.5)};
  padding-top: ${({ theme }) => theme.spacing(1.5)};
  padding-bottom: ${({ theme }) => theme.spacing(1.5)};
  font-size: ${({ theme }) => theme.typography.customFontSize.small};
  line-height: ${({ theme }) => theme.spacing(2.25)};
`;

const StyledParagraph = styled('p')`
  margin-top: 0;
  margin-bottom: 0;
`;

export const StyledCheckboxListLabelPrimary = styled(StyledParagraph)`
  font-weight: 500;
`;

export const StyledCheckboxListLabelSecondary = styled(StyledParagraph)`
  color: ${({ theme }) => theme.palette.custom.greyMain};

  .Mui-disabled & {
    color: inherit;
  }
`;
